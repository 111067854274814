import type { UserGroupHydraItem } from "@verbleif/lib";

import { computed, ref } from "vue";

export function useSharedStoreUserGroups() {
  const userGroups = ref<Record<string, UserGroupHydraItem>>({});

  const userGroupsList = computed<UserGroupHydraItem[]>(() => {
    return Object.values(userGroups.value);
  });

  function setUserGroups(newUserGroups: UserGroupHydraItem[]) {
    userGroups.value = newUserGroups.reduce((acc, userGroup) => {
      acc[userGroup["@id"]] = userGroup;
      return acc;
    }, {} as Record<string, UserGroupHydraItem>);
  }

  function upsertUserGroup(userGroup: UserGroupHydraItem) {
    userGroups.value[userGroup["@id"]] = userGroup;
  }

  function deleteUserGroup(userGroup: UserGroupHydraItem) {
    delete userGroups.value[userGroup["@id"]];
  }

  function _reset() {
    userGroups.value = {};
  }

  return {
    userGroups,
    userGroupsList,
    setUserGroups,
    _reset,
    upsertUserGroup,
    deleteUserGroup,
  };
}
