<script setup lang="ts">
import { ref, toRefs, watch } from "vue";
import { useComponentId } from "../useComponentId";

defineOptions({
  name: "VRadio",
});

const props = withDefaults(defineProps<{
  name?: string | boolean
  labelLeft?: string | boolean
  labelRight?: string | boolean
  labelVariant?: "is-light" | "is-gray"
  size?: "is-medium" | "is-small" | "is-large"
  disabled?: boolean
  loading?: boolean
  hovered?: boolean
  modelValue: RadioModelValue
  value: RadioModelValue
}>(), {
  name: false,
  labelLeft: false,
  labelRight: false,
  labelVariant: "is-light",
  size: "is-medium",
  disabled: false,
  loading: false,
  hovered: false,
});

const emit = defineEmits<{
  "update:modelValue": [RadioModelValue]
}>();

export type RadioModelValue = string | boolean | number | null;

const id: any = useComponentId();
const { modelValue, value, disabled } = toRefs(props);
const valueRef = ref(modelValue.value);

// If an external change is made to the value, update the internal value
watch(modelValue, (newValue: RadioModelValue, oldValue: RadioModelValue) => {
  if (newValue === oldValue) {
    return;
  }
  valueRef.value = newValue;
});

// If we changed the internal value, emit the change
watch(valueRef, (value: RadioModelValue, oldValue: RadioModelValue) => {
  if (value === oldValue || disabled.value) {
    return;
  }
  emit("update:modelValue", value);
});

function handleClick() {
  if (!disabled.value) {
    valueRef.value = value.value;
  }
};
</script>

<template>
  <div
    class="radio-container"
    :class="[
      {
        'is-hovered': hovered,
        'is-disabled': disabled,
      },
      size,
      $attrs.class,
    ]"
    @click="handleClick"
  >
    <label
      v-if="labelLeft || $slots.labelLeft"
      :for="id"
      class="v-label is-left"
      :class="[labelVariant]"
    >
      <VSkeleton v-if="loading" :width="75" :height="10" />
      <template v-else>
        <slot name="labelLeft">{{ labelLeft }}</slot>
      </template>
    </label>
    <div class="radio-wrapper">
      <VSkeleton
        v-if="loading"
        :width="20"
        width-unit="px"
        :dynamic-width="false"
        :height="20"
      />
      <template v-else>
        <input
          :id="id"
          v-model="valueRef"
          type="radio"
          class="radio-input"
          :value="value"
          :name="typeof name === 'string' ? name : ''"
          :disabled="disabled"
        >
        <span :for="id" class="radio-custom" :class="{ 'is-disabled': disabled }" />
      </template>
    </div>
    <label
      v-if="labelRight || $slots.labelRight"
      :for="id"
      class="v-label is-right"
      :class="[labelVariant]"
    >
      <VSkeleton v-if="loading" :width="150" width-unit="px" :height="10" />
      <template v-else>
        <slot name="labelRight">{{ labelRight }}</slot>
      </template>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.radio-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover:not(.is-disabled),
  &.is-hovered:not(.is-disabled) {
    //Show check if radio is hovered
    .radio-wrapper .radio-input + .radio-custom:after {
      opacity: 0.25;
    }
  }

  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    .radio-wrapper .radio-custom,
    .radio-input,
    .v-label {
      cursor: not-allowed;
    }
  }

  .v-label {
    color: var(--text);
    cursor: pointer;
    user-select: none;

    &.is-right {
      margin-left: 10px;
    }
    &.is-left {
      margin-right: 10px;
    }
  }

  &.is-large {
    .v-label {
      line-height: 20px;
      font-size: 16px;
    }

    .radio-wrapper {
      .radio-custom {
        height: 20px;
        width: 20px;
        line-height: 20px;
      }

      .radio-input + .radio-custom:after {
        width: 10px;
        height: 10px;
      }
    }
  }

  &.is-medium {
    .v-label {
      line-height: 16px;
      font-size: 14px;
    }

    .radio-wrapper {
      .radio-custom {
        height: 16px;
        width: 16px;
        line-height: 16px;
      }

      .radio-input + .radio-custom:after {
        width: 8px;
        height: 8px;
      }
    }
  }

  &.is-small {
    .v-label {
      line-height: 16px;
      font-size: 14px;
    }

    .radio-wrapper {
      .radio-custom {
        height: 14px;
        width: 14px;
        line-height: 14px;
      }

      .radio-input + .radio-custom:after {
        width: 6px;
        height: 6px;
      }
    }
  }

  .radio-wrapper {
    position: relative;

    //DEFAULT STATE (the radio container)
    .radio-custom {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px var(--primary);
      -webkit-font-smoothing: antialiased;
      color: gray;
      background-color: var(--white);
      text-align: center;
      cursor: pointer;
      user-select: none;
      border-radius: 50%;

      &.is-disabled {
        border-color: var(--gray-300);
        background-color: var(--gray-100);
      }
    }

    //THE REAL HTML5 CHECKBOX (hide it)
    .radio-input[type="radio"] {
      display: none;
    }

    //DEFAULT STATE (the check)
    .radio-input + .radio-custom:after {
      content: "";
      opacity: 0;
      border-radius: 50%;
      transition: all ease 100ms;
      background-color: var(--primary);
    }

    //CHECKED STATE
    .radio-input:checked + .radio-custom:after {
      opacity: 1;
    }

    .radio-input:disabled + .radio-custom:after {
      background-color: var(--gray-300);
    }
  }
}
</style>
