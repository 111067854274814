import type { ClientHydraItem, LocationHydraItem, SpaceHydraItem } from "@verbleif/lib";
import type { ComputedRef } from "vue";
import { iriToId } from "@verbleif/lib";
import { createGlobalState, useLocalStorage, useSessionStorage } from "@vueuse/core";

import { computed, ref } from "vue";

export const useLocationStore = createGlobalState(
  () => {
    const mode = ref<"location" | "client" | "global">("location");

    function switchMode(newMode: "location" | "client" | "global") {
      mode.value = newMode;
    }

    const locations = ref<Record<string, LocationHydraItem>>({});
    const clients = ref<Record<string, ClientHydraItem>>({});
    const spaces = ref<SpaceHydraItem[]>([]);
    const defaultLocation = useLocalStorage<string | null>("defaultLocation", null);
    const selectedLocation = useSessionStorage<string | null>("selectedLocation", defaultLocation.value || null);

    const clientsList = computed<ClientHydraItem[]>(() => {
      return Object.values(clients.value);
    });

    const locationsList = computed<LocationHydraItem[]>(() => {
      if (!locations.value) {
        return [];
      }
      return Object.values(locations.value);
    });

    const selectedLocationId = computed<number | null>(() => {
      return selectedLocation.value ? iriToId(selectedLocation.value) : null;
    });

    const isAnyImportRunning: ComputedRef<boolean> = computed(() => {
      if (!locationsList.value) {
        return false;
      }
      return locationsList.value.filter(l => l.importRunning).length > 0;
    });

    const hasMultipleLocations = computed<boolean>(() => {
      if (!spaces.value) {
        return false;
      }
      return spaces.value.length > 1;
    });

    const modeSelectedLocation = computed<string | null>(() => {
      if (mode.value !== "location") {
        return null;
      }

      if (!selectedLocation.value) {
        return null;
      }
      return selectedLocation.value;
    });

    const selectedLocationObject = computed<LocationHydraItem | null>(() => {
      if (!selectedLocation.value) {
        return null;
      }
      return locations.value?.[selectedLocation.value] || null;
    });

    const modeSelectedClient = computed<string | null>(() => {
      if (mode.value !== "client") {
        return null;
      }

      if (!selectedLocationObject.value) {
        return null;
      }
      return selectedLocationObject.value.client || null;
    });

    const getSelectedLocationId = computed<number | null>(() => {
      return selectedLocationObject.value?.id || null;
    });

    function setLocations(newLocations: LocationHydraItem[]): void {
      locations.value = newLocations.reduce((a, v) => {
        return { ...a, [v["@id"]]: v };
      }, {} as Record<string, LocationHydraItem>);
    }

    function getSpaceByLocation(location: string): SpaceHydraItem | null {
      return spaces.value.find(s => s.location === location) || null;
    }

    function isSpaceValid(space: SpaceHydraItem): boolean {
      return !!space.location && !!space.accepted && !space.expired;
    }

    function updateSpaceState() {
      // If the selectedLocation does not exist anymore somehow
      if (selectedLocation.value && !locations.value?.[selectedLocation.value]) {
        setSelectedLocation(null);
      }

      if (defaultLocation.value && !locations.value?.[defaultLocation.value]) {
        setDefaultLocation(null);
      }

      if (defaultLocation.value && !selectedLocation.value) {
        const space = getSpaceByLocation(defaultLocation.value);
        if (space && isSpaceValid(space)) {
          setSelectedLocation(defaultLocation.value);
        }
      } else if (spaces.value.length === 1 && spaces.value[0].location && selectedLocation.value !== spaces.value[0].location) {
        const space = getSpaceByLocation(spaces.value[0].location);
        if (space && isSpaceValid(space)) {
          setSelectedLocation(spaces.value[0].location);
          setDefaultLocation(spaces.value[0].location);
        }
      }
    }

    function upsertLocation(newLocation: LocationHydraItem): void {
      locations.value = {
        ...locations.value,
        [newLocation["@id"]]: newLocation,
      };
      updateSpaceState();
    }

    function deleteLocation(iri: string): void {
      delete locations.value[iri];
      updateSpaceState();
    }

    function setSpaces(newSpaces: SpaceHydraItem[]): void {
      spaces.value = newSpaces.filter(s => !!s.location);
      updateSpaceState();
    }

    const pendingInvites = computed<number | null>(() => {
      const unAcceptedSpaces = spaces.value.filter(s => !s.accepted);
      return unAcceptedSpaces.length > 0 ? unAcceptedSpaces.length : null;
    });

    function setImportRunningForLocationIri(iri: string, isRunning: boolean): void {
      if (!locations.value) {
        console.error("Cant set setImportRunningForLocationId since there are no locations");
        return;
      }
      const index = locations.value[iri];
      if (!index) {
        return;
      }
      locations.value[iri].importRunning = isRunning;
    }

    function setDefaultLocation(val: string | null): void {
      defaultLocation.value = val;
    }

    function setSelectedLocation(val: string | null): void {
      selectedLocation.value = val;
    }

    function reset() {
      setSelectedLocation(null);
      locations.value = {};
      defaultLocation.value = null;
      clients.value = {};
      spaces.value = [];
    }

    const hasMoreThanOneLocation = computed<boolean>(() => {
      return spaces.value.length > 1;
    });

    const selectedClient = computed<ClientHydraItem | null>(() => {
      if (!selectedLocationObject.value) {
        return null;
      }
      const clientIri = selectedLocationObject.value.client;

      if (!clientIri) {
        return null;
      }

      return clients.value[clientIri] || null;
    });

    function setClients(newClients: ClientHydraItem[]) {
      clients.value = newClients.reduce((a, v) => {
        return { ...a, [v["@id"]]: v };
      }, {} as Record<string, ClientHydraItem>);
    }

    return {
      locationsList,
      locations,
      mode,
      modeSelectedClient,
      spaces,
      selectedClient,
      clientsList,
      switchMode,
      upsertLocation,
      clients,
      setClients,
      deleteLocation,
      modeSelectedLocation,
      setSpaces,
      selectedLocation,
      selectedLocationId,
      hasMoreThanOneLocation,
      selectedLocationObject,
      pendingInvites,
      hasMultipleLocations,
      isAnyImportRunning,
      getSelectedLocationId,
      reset,
      defaultLocation,
      setSelectedLocation,
      setDefaultLocation,
      setImportRunningForLocationIri,
      setLocations,
    };
  },
);
