<script setup lang="ts">
import { computed } from "vue";
import { useSortable } from "../../composables";

interface Props {
  item: {
    field: string
    translation: string
  }
  right?: boolean
  sortableHeading: boolean
}

const props = withDefaults(defineProps<Props>(), {
  item: () => ({ field: "", translation: "" }),
  sortableHeading: false,
  right: false,
});

const { sort, isSortable, sortDirection } = useSortable();

const isClickable = computed(() => props.sortableHeading && isSortable(props.item.field));
const isSorted = computed(() => props.item.field === sort.value);

function setCurrentSort() {
  if (!props.sortableHeading || !isSortable(props.item.field)) {
    return;
  }

  if (sort.value !== props.item.field) {
    sort.value = props.item.field;
    return;
  }

  if (sortDirection.value === "ASC") {
    sortDirection.value = "DESC";
    return;
  }

  if (sortDirection.value === "DESC") {
    sort.value = null;
    sortDirection.value = "ASC";
  }
}
</script>

<template>
  <div class="table-head-sortable" @click="setCurrentSort">
    <div
      :class="{
        'is-sorted': isSorted,
        'is-clickable': isClickable,
        'w-full': right,
      }"
      class="table-heading"
    >
      {{ item.translation }}
      <div
        v-if="isClickable"
        class="sort-controls"
      >
        <FontAwesomeIcon
          class="icon caret-up"
          :class="{ active: isSorted && sortDirection === 'ASC' }"
          icon="caret-up"
        />
        <FontAwesomeIcon
          class="icon caret-down"
          :class="{ active: isSorted && sortDirection === 'DESC' }"
          icon="caret-down"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-head-sortable {
  width: 100%;
  height: 100%;
  display: flex;
}

.table-heading {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  color: var(--text);
  font-weight: bold;
  font-size: 14px;
  transition: none;
  &.is-clickable {
    cursor: pointer;
  }

  &.is-sorted {
    opacity: 1;
    color: var(--primary);
  }

  .sort-controls {
    transition: inherit;
    display: flex;
    gap: 1px;
    flex-direction: column;
    flex-shrink: 0;

    .icon {
      font-size: 12px;
      color: var(--input-border);

      &.active {
        opacity: 1;
        color: var(--primary);
      }
    }

    .caret-up {
      margin-bottom: -3px;
    }

    .caret-down {
      margin-top: -3px;
    }
  }
}
</style>
