import type { ReportHydraItem } from "@verbleif/lib";
import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum, PermissionScope } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

const createContextAttributes = [
  PermissionAttributesEnum.ContextOperationReportCreate,
];

const deleteContextAttributes = [
  PermissionAttributesEnum.ContextOperationReportDelete,
];

const updateContextAttributes = [
  PermissionAttributesEnum.ContextOperationReportUpdate,
];

export class ReportVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReportCreateany,
      contextAttribute: createContextAttributes,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReportReadany,
      contextAttribute: PermissionAttributesEnum.ContextOperationReportRead,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReportDeleteany,
      contextAttribute: deleteContextAttributes,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReportUpdateany,
      contextAttribute: updateContextAttributes,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationReportCreateany,
        PermissionAttributesEnum.GlobalOperationReportUpdateany,
        PermissionAttributesEnum.GlobalOperationReportDeleteany,
      ],
      contextAttribute: [
        ...createContextAttributes,
        ...updateContextAttributes,
        ...deleteContextAttributes,
      ],
    });
  };

  public hasAccessToAttributeViaReportRelations(item: ReportHydraItem, attribute: PermissionAttributesEnum) {
    const location = item.location;
    const property = item.property;
    const client = location ? this.permissionService.getClientViaLocation(location) : null;

    if (client) {
      const userPermission = this.permissionService.getScopePermissionForUser({
        iri: client,
        attribute,
        scope: PermissionScope.CLIENT,
      });
      if (userPermission !== null) {
        return true;
      }
    }

    if (location) {
      const userLocationPermission = this.permissionService.getScopePermissionForUser({
        iri: location,
        attribute,
        scope: PermissionScope.LOCATION,
      });
      if (userLocationPermission !== null) {
        return true;
      }
    }

    if (property) {
      const userPropertyPermission = this.permissionService.getScopePermissionForUser({
        iri: property,
        attribute,
        scope: PermissionScope.PROPERTY,
      });
      if (userPropertyPermission !== null) {
        return true;
      }
    }
    return false;
  }
}
