<script setup lang="ts">
import type { Dropdown as VDropdown } from "floating-vue";
import { computed, ref, watch } from "vue";
import ACTooltip from "../ACTooltip/ACTooltip.vue";

interface Props {
  modelValue?: string
  is24h?: boolean
  placeholder?: string
  size?: "small" | "medium" | "large"
  labelLeft?: string
  required?: boolean
  disabled?: boolean
  errorMessage?: string | null
  variant?: string
  hasPerms?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  is24h: true,
  placeholder: "Select time",
  size: "medium",
  labelLeft: "",
  required: false,
  disabled: false,
  errorMessage: null,
  variant: "is-gray",
  hasPerms: true,
});

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void
  (e: "blur", event: FocusEvent): void
}>();
const isDisabled = computed(() => {
  return props.disabled || !props.hasPerms;
});

const dropdown = ref<InstanceType<typeof VDropdown> | null>(null);
const inputValue = ref("");
const hours = ref("00");
const minutes = ref("00");
const ampm = ref("AM");
const initialValue = ref("");

function formatTime(h: string, m: string, ap: string): string {
  return props.is24h ? `${h}:${m}` : `${h}:${m} ${ap}`;
}

function parseISOString(isoString: string): { hours: string, minutes: string, ampm: string } {
  const date = new Date(isoString);
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Round minutes to nearest 5
  minutes = Math.round(minutes / 5) * 5;

  // Handle case where minutes round to 60
  if (minutes === 60) {
    minutes = 0;
    hours = (hours + 1) % 24;
  }

  const minutesStr = minutes.toString().padStart(2, "0");
  let ampm = "AM";

  if (!props.is24h) {
    if (hours >= 12) {
      ampm = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    }
    if (hours === 0) {
      hours = 12;
    }
  }

  return {
    hours: hours.toString().padStart(2, "0"),
    minutes: minutesStr,
    ampm,
  };
}

function createISOString(hours: string, minutes: string, ampm: string): string {
  const date = new Date();
  let h = Number.parseInt(hours, 10);
  if (!props.is24h) {
    if (ampm === "PM" && h !== 12) {
      h += 12;
    } else if (ampm === "AM" && h === 12) {
      h = 0;
    }
  }
  date.setHours(h, Number.parseInt(minutes, 10), 0, 0);
  return date.toISOString();
}

function updateModelValue() {
  const newValue = inputValue.value ? createISOString(hours.value, minutes.value, ampm.value) : "";
  emit("update:modelValue", newValue);
}

function updateTime(newHours?: number, newMinutes?: number, newAMPM?: string) {
  if (newHours !== undefined) {
    hours.value = newHours.toString().padStart(2, "0");
  }
  if (newMinutes !== undefined) {
    minutes.value = newMinutes.toString().padStart(2, "0");
  }
  if (newAMPM !== undefined) {
    ampm.value = newAMPM;
  }
  inputValue.value = formatTime(hours.value, minutes.value, ampm.value);
}

function incrementHours() {
  let h = Number.parseInt(hours.value);
  if (props.is24h) {
    h = (h + 1) % 24;
  } else {
    h = (h % 12) + 1;
  }
  hours.value = h.toString().padStart(2, "0");
  inputValue.value = formatTime(hours.value, minutes.value, ampm.value);
}

function decrementHours() {
  let h = Number.parseInt(hours.value);
  if (props.is24h) {
    h = (h - 1 + 24) % 24;
  } else {
    h = ((h - 2 + 12) % 12) + 1;
  }
  hours.value = h.toString().padStart(2, "0");
  inputValue.value = formatTime(hours.value, minutes.value, ampm.value);
}

function incrementMinutes() {
  let m = Number.parseInt(minutes.value);
  m = (m + 5) % 60;
  minutes.value = m.toString().padStart(2, "0");
  inputValue.value = formatTime(hours.value, minutes.value, ampm.value);
}

function decrementMinutes() {
  let m = Number.parseInt(minutes.value);
  m = (m - 5 + 60) % 60;
  minutes.value = m.toString().padStart(2, "0");
  inputValue.value = formatTime(hours.value, minutes.value, ampm.value);
}

function toggleAMPM() {
  ampm.value = ampm.value === "AM" ? "PM" : "AM";
  inputValue.value = formatTime(hours.value, minutes.value, ampm.value);
}

function clearValue() {
  hours.value = "00";
  minutes.value = "00";
  ampm.value = "AM";
  inputValue.value = "";
  emit("update:modelValue", "");
}

function setCurrentTime() {
  const { hours: h, minutes: m, ampm: ap } = parseISOString(new Date().toISOString());
  updateTime(Number.parseInt(h), Number.parseInt(m), ap);
}
function show() {
  dropdown.value?.show();
}

watch(() => props.modelValue, (newValue) => {
  if (newValue) {
    const { hours: h, minutes: m, ampm: ap } = parseISOString(newValue);
    updateTime(Number.parseInt(h), Number.parseInt(m), ap);
    initialValue.value = inputValue.value;
  } else {
    clearValue();
  }
});

const inputClass = computed(() => [
  "input",
  {
    "is-disabled": isDisabled.value,
    "has-validation-error": props.errorMessage !== null,
  },
  props.variant,
  props.errorMessage !== null && "is-danger",
]);

function onPopperShow() {
  if (!inputValue.value) {
    setCurrentTime();
  }
}

function hasValueChanged(): boolean {
  return inputValue.value !== initialValue.value;
}

function onPopperHide() {
  if (hasValueChanged()) {
    updateModelValue();
  }
}
</script>

<template>
  <div class="v-time-picker">
    <label v-if="labelLeft" class="label">
      {{ labelLeft }}
      <span v-if="required" class="required">*</span>
    </label>
    <ACTooltip
      type="edit_field"
      :allow-change="hasPerms"
    >
      <VDropdown
        ref="dropdown"
        class="dropdown-wrapper"
        placement="bottom-start"
        theme="verbleif-popper"
        no-auto-focus
        auto-size
        :triggers="[]"
        :disabled="isDisabled"
        @show="onPopperShow"
        @hide="onPopperHide"
      >
        <div
          class="input-wrapper"
          :class="[
            inputClass,
            size,
          ]"
          @click="show"
        >
          <input
            v-model="inputValue"
            :placeholder="errorMessage !== null ? errorMessage : placeholder"
            :disabled="disabled"
            type="text"
            readonly
            @blur="$emit('blur', $event)"
          >
          <div class="icon right">
            <FontAwesomeIcon
              v-if="inputValue"
              icon="times"
              class="has-action mr-2"
              @click.stop="clearValue"
            />
            <FontAwesomeIcon icon="caret-down" class="has-action" />
          </div>
        </div>
        <template #popper>
          <div class="min-w-[200px]">
            <div class="flex justify-center items-center space-x-4">
              <div class="flex flex-col items-center">
                <button class="p-2 chevron-button" @click="incrementHours">
                  <FontAwesomeIcon icon="chevron-up" />
                </button>
                <span class="text-lg my-2">{{ hours }}</span>
                <button class="p-2 chevron-button" @click="decrementHours">
                  <FontAwesomeIcon icon="chevron-down" />
                </button>
              </div>
              <span class="text-xl font-bold">:</span>
              <div class="flex flex-col items-center">
                <button class="p-2 chevron-button" @click="incrementMinutes">
                  <FontAwesomeIcon icon="chevron-up" />
                </button>
                <span class="text-lg my-2">{{ minutes }}</span>
                <button class="p-2 chevron-button" @click="decrementMinutes">
                  <FontAwesomeIcon icon="chevron-down" />
                </button>
              </div>
              <div v-if="!is24h" class="flex flex-col items-center">
                <button class="p-2 chevron-button" @click="toggleAMPM">
                  <FontAwesomeIcon icon="chevron-up" />
                </button>
                <span class="text-lg my-2">{{ ampm }}</span>
                <button class="p-2 chevron-button" @click="toggleAMPM">
                  <FontAwesomeIcon icon="chevron-down" />
                </button>
              </div>
            </div>
          </div>
        </template>
      </VDropdown>
    </ACTooltip>
    <!-- <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.v-time-picker {
  position: relative;
  display: inline-block;
  width: 100%;

  .label {
    display: inline-flex;
    font-size: 14px;
    color: var(--text);
    gap: 5px;
    margin-bottom: 5px;

    .required {
      color: var(--secondary);
      font-weight: bold;
    }
  }

  .dropdown-wrapper {
    width: 100%;
  }

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 25px;
    background: var(--input-background);
    justify-content: space-between;
    width: 100%;
    border: 1px solid var(--input-border);

    &.small {
      height: 32px;

      input {
        font-size: 14px;
        line-height: 32px;
      }
    }

    &.medium {
      height: 32px;

      input {
        font-size: 14px;
        line-height: 32px;
      }
    }

    &.large {
      height: 40px;

      input {
        font-size: 16px;
        line-height: 40px;
      }
    }

    &.is-danger.has-validation-error {
      border-color: var(--danger);

      input {
        color: var(--danger);
      }
    }

    &.is-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    input {
      flex: 1;
      border: none;
      font-family: "Roboto", sans-serif;
      padding-left: 20px;
      padding-right: 20px;
      background: transparent;
      color: var(--text);
      width: 100%;
      height: 100%;
      box-sizing: content-box;
      -moz-box-sizing: content-box;
      -webkit-box-sizing: content-box;

      &::placeholder {
        color: var(--input-placeholder);
      }

      &:focus {
        outline: none;
      }
    }

    .icon {
      position: absolute;
      right: 15px;
      font-size: 14px;
      color: var(--text);
      display: flex;
      align-items: center;

      .has-action {
        cursor: pointer;
      }
    }
  }

  .error-message {
    color: var(--danger);
    font-size: 12px;
    margin-top: 5px;
  }
}

.v-time-picker__popper {
  padding: 10px;
  background: var(--background);
  border-radius: 4px;
}

.v-time-picker__controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-time-picker__control {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
    color: var(--text);

    &:hover {
      background-color: var(--input-background);
    }
  }

  span {
    font-size: 18px;
    padding: 5px 0;
    color: var(--text);
  }
}

.chevron-button {
  transition: color 0.3s ease;

  &:hover {
    color: var(--primary);
  }

  &:active {
    color: var(--primary-dark);
  }
}

.input-wrapper.is-danger.has-validation-error {
  input::placeholder {
    color: var(--danger);
  }
}
</style>
