<script>
export default {
  name: "VDate",
  props: {
    labelLeft: {
      type: String,
      default: "",
    },
    modelValue: {
      type: null,
      default: null,
    },
    range: Boolean,
    autoApply: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "center",
    },
    datePickerPlaceholder: {
      type: String,
      default: "placeholder",
    },
    inputPlaceholder: {
      type: String,
      default: "placeholder",
    },
  },
  emits: ["update:modelValue"],
};
</script>

<template>
  <div class="input-wrapper">
    <VueDatePicker
      :position="position"
      :auto-apply="autoApply"
      :model-value="modelValue"
      :dark="dark"
      locale="nl"
      cancel-text="Terug"
      select-text="Kiezen"
      :placeholder="datePickerPlaceholder"
      :range="range"
      v-bind="$attrs"
      :enable-time-picker="false"
      @update:model-value="(v) => $emit('update:modelValue', v)"
    >
      <template #dp-input="{ value, onInput }">
        <VInput
          :label-left="labelLeft"
          :model-value="value"
          :placeholder="inputPlaceholder"
          :icon-right="!value ? false : 'trash'"
          :has-icon-right-action="true"
          icon-left="calendar"
          readonly
          @on-right-icon-click="value = null"
          @click="onInput"
        />
      </template>
    </VueDatePicker>
  </div>
</template>

<style scoped lang="scss">
.input-wrapper {
  flex: 1 1 66%;
}
</style>
