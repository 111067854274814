import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class ReportTopicVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReporttopicCreateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationReporttopicCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReporttopicReadany,
      contextAttribute: PermissionAttributesEnum.ContextOperationReporttopicRead,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReporttopicDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextOperationReporttopicDelete,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReporttopicUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationReporttopicUpdate,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationReporttopicCreateany,
        PermissionAttributesEnum.GlobalOperationReporttopicUpdateany,
        PermissionAttributesEnum.GlobalOperationReporttopicDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationReporttopicCreate,
        PermissionAttributesEnum.ContextOperationReporttopicUpdate,
        PermissionAttributesEnum.ContextOperationReporttopicDelete,
      ],
    });
  };
}
