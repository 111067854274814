<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineProps } from "vue";

const props = withDefaults(defineProps<Props>(), {
  label: "",
  disabled: false,
  layout: "is-column",
  min: 0,
  max: 0,
  step: 1,
  width: 1,
});

interface Props {
  invalid?: boolean
  required?: boolean
  label: string
  disabled?: boolean
  layout?: string
  min?: number
  max?: number
  step?: number | string
}

const modelValue = defineModel<number | undefined>();

if (modelValue.value === undefined) {
  modelValue.value = Number(props.min);
}

function decreaseValue() {
  if (modelValue.value === undefined) {
    return;
  }
  if (modelValue.value - Number(props.step) < props.min) {
    modelValue.value = Number(props.min);
    return;
  }
  modelValue.value -= Number(props.step);
}

function increaseValue() {
  if (modelValue.value === undefined) {
    modelValue.value = 1;
    return;
  }
  if (!props.max) {
    modelValue.value += Number(props.step);
    return;
  }
  if (modelValue.value + Number(props.step) > props.max) {
    modelValue.value = Number(props.max);
    return;
  }
  modelValue.value += Number(props.step);
}

function checkValue() {
  if (modelValue.value === undefined) {
    return;
  }
  if (props.max !== 0 && modelValue.value + Number(props.step) > props.max) {
    modelValue.value = Number(props.max);
  }
  if (modelValue.value - Number(props.step) < props.min) {
    modelValue.value = Number(props.min);
  }
}
</script>

<template>
  <div class="inline-block dark:text-white">
    <label
      v-if="label"
      class="inline-flex text-theme-light-gray-2 dark:text-gray-300 gap-1 leading-[16px] text-sm mb-[5px]"
      :class="{ 'w-1/3': layout === 'is-row' }"
    >
      {{ label }}
      <span v-if="required" class="text-red-500 font-bold">*</span>
    </label>
    <div class="flex items-center font-white overflow-hidden">
      <button
        class="w-[20px] h-[20px] flex justify-center items-center rounded"
        :class="disabled ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' : 'bg-danger-600 dark:bg-danger-700'"
        :disabled="disabled"
        @click="decreaseValue"
      >
        <FontAwesomeIcon :icon="['fas', 'minus']" class="text-white" />
      </button>
      <div
        class="h-[30px] rounded-full border border-solid flex w-[52px] justify-center items-center mx-[13px]"
        :class="
          disabled ? 'bg-gray-300 dark:bg-dark-700 border-gray-200 dark:border-dark-600 cursor-not-allowed' : invalid ? 'border-danger-600 dark:border-danger-700 bg-white dark:bg-dark-800' : 'border-gray-200 dark:border-dark-600 bg-white dark:bg-dark-800'"
      >
        <input
          v-model="modelValue"
          type="number"
          class="custom-input dark:text-white"
          :class="
            disabled ? 'cursor-not-allowed' : ''"
          :disabled="disabled"
          @blur="checkValue"
        >
      </div>
      <button
        class="w-[20px] h-[20px] flex justify-center items-center rounded"
        :class="disabled ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' : 'bg-primary-600 dark:bg-primary-700'"
        :disabled="disabled"
        @click="increaseValue"
      >
        <FontAwesomeIcon :icon="['fas', 'plus']" class="text-white" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-input {
  //  Remove default browser styling
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none; // Tyvus: Remove border
  outline: none; //  Remove outline
  background: none; // Tyvus: Remove background
  text-align: center; //  Center the text
  width: 100%; // Tyvus: Ensure it takes full width
  height: 100%; //  Ensure it takes full height
}

//  Hide the up and down buttons for number input
.custom-input::-webkit-inner-spin-button,
.custom-input::-webkit-outer-spin-button {
  -webkit-appearance: none; //  Remove default styling
  margin: 0; // Tyvus: Remove margin
}
</style>
