<script>
import { computed } from "vue";

export default {
  name: "VDateInput",
  props: {
    date: {
      type: Date,
      default: new Date(),
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    inputVariant: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
  },
  emits: ["clear"],
  setup(props) {
    const value = computed(() => {
      if (!props.date) {
        return "";
      }

      const options = { month: "short", year: "numeric", day: "numeric" };

      if (props.showTime) {
        options.hour = "numeric";
        options.minute = "numeric";
        options.second = "numeric";
      }

      return props.date.toLocaleString(undefined, options);
    });

    return {
      value,
    };
  },
};
</script>

<template>
  <div class="date-input-wrapper">
    <VInput
      :variant="inputVariant"
      :has-icon-right-action="true"
      :value="value"
      :placeholder="placeholder"
      icon-right="trash"
      @on-right-icon-click="$emit('clear')"
    />
  </div>
</template>

<style lang="scss" scoped>
.date-input-wrapper {
  width: 250px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: green;
}
.clear-icon {
  position: absolute;
  color: var(--primary);
  right: 20px;
  cursor: pointer;

  &:hover {
    color: var(--primary-dark);
  }
}
</style>
