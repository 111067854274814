<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, inject, ref } from "vue";
import ACTooltip from "../ACTooltip/ACTooltip.vue";
import { useComponentId } from "../useComponentId";

interface Props {
  name?: string | boolean
  errorMessage?: string
  loading?: boolean
  label?: string
  labelPosition?: "top" | "left"
  required?: boolean
  placeholder?: string
  emptyValue?: string | null
  defaultColor?: string
  variant?: string
  size?: "medium" | "large"
  disabled?: boolean
  hasPerms?: boolean
}

const {
  errorMessage = "",
  loading = false,
  label = "",
  labelPosition = "top",
  required = false,
  placeholder = "placeholder",
  emptyValue = null,
  defaultColor,
  variant = "",
  size = "large",
  hasPerms = true,
  disabled = false,
} = defineProps<Props>();

defineEmits<{
  (e: "change"): void
  (e: "blur"): void
}>();

const isDisabled = computed(() => {
  return disabled || !hasPerms;
});

const modelValue = defineModel<string | null>();
const componentId = useComponentId();
const id = inject("FieldId", componentId);
const debounceTimeout = ref<number | null>(null);

function clearColor(event: Event) {
  event.stopPropagation();
  modelValue.value = emptyValue || "";
}

function handleColorInput(e: Event) {
  const value = (e.target as HTMLInputElement).value;

  // Clear any existing timeout
  if (debounceTimeout.value) {
    window.clearTimeout(debounceTimeout.value);
  }

  // Set new timeout
  debounceTimeout.value = window.setTimeout(() => {
    modelValue.value = value;
  }, 100); // 100ms debounce
}

const displayValue = computed(() => {
  return modelValue.value || placeholder;
});

const isPlaceholder = computed(() => {
  return !modelValue.value;
});

const hasError = computed(() => errorMessage !== "");
</script>

<template>
  <div
    class="relative font-roboto text-base"
    :class="[
      loading ? 'cursor-progress' : '',
      labelPosition === 'left' ? 'flex items-center' : '',
    ]"
  >
    <label
      v-if="label"
      class="inline-flex text-sm text-gray-700 dark:text-gray-300 gap-1.5 mb-1.5"
      :class="{ 'mr-2.5': labelPosition === 'left' }"
      :for="id?.toString()"
    >
      {{ label }}
      <span v-if="required" class="text-secondary-600 font-bold">*</span>
    </label>
    <div :class="{ 'flex-1': labelPosition === 'left' }">
      <ACTooltip
        type="edit_field"
        :allow-change="hasPerms"
      >
        <VSkeleton
          v-if="loading"
          :height="40"
          :width="100"
          width-unit="%"
          :dynamic-width="false"
          class="rounded"
          :pill="false"
        />
        <div
          v-else
          class="relative flex items-center rounded-full border border-solid border-gray-300 dark:border-dark-600 px-2.5 w-full dark:bg-dark-800"
          :class="[
            hasError ? 'border-red-500' : '',
            variant,
            size === 'medium' ? 'h-8 text-sm' : 'h-10 text-base',
            isDisabled ? 'cursor-not-allowed opacity-50' : '',
          ]"
        >
          <span
            class="w-5 h-5 rounded-lg border border-solid border-gray-300 dark:border-dark-600 mr-2.5"
            :style="{ backgroundColor: modelValue || defaultColor || '#E0E0E0' }"
          />
          <input
            :id="id?.toString()"
            type="color"
            :disabled="isDisabled"
            class="absolute inset-0 opacity-0 cursor-pointer w-full"
            :value="modelValue || defaultColor || '#E0E0E0'"
            @input="handleColorInput"
            @blur="$emit('blur')"
            @change="$emit('change')"
          >
          <span
            class="flex-grow text-sm pointer-events-none"
            :class="[
              isPlaceholder ? 'text-gray-400 dark:text-gray-500' : 'text-gray-700 dark:text-gray-300',
              hasError ? 'text-red-500' : '',
            ]"
          >
            {{ hasError ? errorMessage : displayValue }}
          </span>
          <button
            v-if="modelValue !== emptyValue"
            type="button"
            class="p-1.5 text-gray-400 hover:text-red-500 transition-colors duration-300 relative z-10"
            @click.stop="clearColor"
          >
            <FontAwesomeIcon :icon="['fas', 'times']" />
          </button>
        </div>
      </ACTooltip>
    </div>
  </div>
</template>

<style>
.v-color-picker .vc-colorpicker {
  background-color: transparent !important;
  box-shadow: none !important;
}
.v-color-picker .vc-colorpicker .advance {
  display: none !important;
  pointer-events: none !important;
}
</style>
