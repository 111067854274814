import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class SortVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationSortCreateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationSortCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationSortReadany,
      contextAttribute: PermissionAttributesEnum.ContextOperationSortRead,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationSortDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextOperationSortDelete,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationSortUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationSortUpdate,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationSortCreateany,
        PermissionAttributesEnum.GlobalOperationSortUpdateany,
        PermissionAttributesEnum.GlobalOperationSortDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationSortCreate,
        PermissionAttributesEnum.ContextOperationSortUpdate,
        PermissionAttributesEnum.ContextOperationSortDelete,
      ],
    });
  };
}
