<script lang="ts" setup>
import type { StepperProps } from "./stepper.interface.ts";
import { computed, defineExpose, defineModel, defineProps } from "vue";
import { StepperStyle, StepperType } from "./stepper.interface.ts";

interface Props {
  steps: StepperProps[]
  variant?: string
  divider?: StepperStyle
  border?: StepperStyle
  theme?: string
  blurPrevious?: boolean
  themedText?: boolean
  progress?: boolean
  showSteps?: boolean
  hasCheckIcon?: boolean
  stepInteract?: boolean
  loading?: boolean
}

const {
  variant = "horizontal",
  divider = StepperStyle.DASHED,
  border = StepperStyle.SOLID,
  theme = "primary",
  blurPrevious = true,
  themedText = true,
  progress = false,
  showSteps = true,
  hasCheckIcon = false,
  stepInteract = false,
  loading = false,
  steps,
} = defineProps<Props>();

const currentStep = defineModel<string>({ default: "" });

function changeStep(direction: 1 | -1) {
  const currentStepIndex = steps.findIndex(step => step.key === currentStep.value);
  if (currentStepIndex > -1) {
    const newIndex = currentStepIndex + direction;
    // Ensure the new index is within bounds
    if (newIndex >= 0 && newIndex < steps.length) {
      // Add a slight delay before changing the step
      setTimeout(() => {
        currentStep.value = steps[newIndex].key;
      }, 50); // 50ms delay, adjust as needed
    }
  }
}

function nextStep() {
  changeStep(1);
}

function prevStep() {
  changeStep(-1);
}

const currentIndex = computed(() => steps.findIndex(step => step.key === currentStep.value));

function goToStep(key: string) {
  if (stepInteract) {
    const stepIndex = getIndexByKey(key);
    if (stepIndex < currentIndex.value) {
      currentStep.value = key;
    }
  }
}

defineExpose({ currentStep, nextStep, prevStep, goToStep });

const computedSteps = computed(() => steps.map(step => ({
  ...step,
  type: StepperType.STEP,
  style: border,
})));

function getIndexByKey(key: string) {
  return steps.findIndex(step => step.key === key);
}
</script>

<template>
  <div class="container">
    <slot name="info" :title="currentStep" />
    <div v-if="showSteps" class="stepper" :class="[variant, { 'has-check-icon': hasCheckIcon }]">
      <template v-for="(step, index) in computedSteps" :key="step.key">
        <template v-if="loading">
          <VSkeleton
            :width="48"
            :height="48"
            width-unit="px"
            :dynamic-width="false"
            :pill="true"
          />
        </template>
        <template v-else>
          <div class="step" :class="{ 'last-step': index === computedSteps.length - 1, 'themed-text': themedText }" @click="goToStep(step.key)">
            <div
              class="inner-step"
              :class="[
                {
                  'dashed': step.style === 'dashed',
                  'solid': step.style === 'solid',
                  'active': currentStep === step.key,
                  'previous': getIndexByKey(step.key as string) < currentIndex,
                  'blur-previous': blurPrevious,
                  'themed-text': themedText,
                  'has-check-icon': hasCheckIcon,
                  'interactive': stepInteract && getIndexByKey(step.key as string) < currentIndex,
                },
                variant,
                theme,
              ]"
            >
              <div v-if="!hasCheckIcon" class="icon" style="font-size: 1.5rem">
                <template v-if="loading">
                  <VSkeleton :width="24" :height="24" :pill="true" />
                </template>
                <template v-else-if="step.icon">
                  <font-awesome-icon :icon="step.icon" />
                </template>
              </div>

              <div v-if="!hasCheckIcon" class="content-wrapper">
                <VSkeleton v-if="loading" :width="80" width-unit="px" :height="20" />
                <template v-else>
                  <div class="label">
                    {{ step.name }}
                  </div>
                  <div class="description">
                    <slot
                      v-if="currentStep === step.key"
                      name="inner-content"
                      :step="step"
                      :current-step="currentStep"
                      :next-step="nextStep"
                      :prev-step="prevStep"
                    />
                  </div>
                </template>
              </div>
              <div v-if="hasCheckIcon" class="content-wrapper">
                <template v-if="loading">
                  <VSkeleton :width="19" :height="19" :pill="true" />
                </template>
                <template v-else>
                  <div v-if="getIndexByKey(step.key as string) < currentIndex" class="icon">
                    <font-awesome-icon :icon="['fas', 'check']" :style="{ color: 'var(--always-white)' }" />
                  </div>
                </template>
              </div>
            </div>
            <div v-if="hasCheckIcon" class="step-label">
              <VSkeleton v-if="loading" :width="60" width-unit="px" :height="16" />
              <template v-else>
                {{ step.name }}
              </template>
            </div>
          </div>
        </template>
        <div
          v-if="index < computedSteps.length - 1"
          class="divider"
          :class="[
            {
              'previous': index < currentIndex,
              'dashed': divider === 'dashed',
              'solid': divider === 'solid',
              'loading': loading,
              'in-progress': index === currentIndex,
              'blur-previous': blurPrevious,
              'has-progress': progress && !loading,
              'has-check-icon': hasCheckIcon,
            },
            variant,
            theme,
          ]"
        />
      </template>
    </div>
    <slot class="content" name="content" :current-step="currentStep" :next-step="nextStep" :prev-step="prevStep" />
  </div>
</template>

<style scoped lang="scss">
@mixin theme-colors($theme) {
  border-color: var(--#{$theme}) !important;

  &.themed-text .label {
    color: var(--#{$theme});
  }

  &.previous.horizontal.has-check-icon {
    background: var(--#{$theme}) !important;
  }

  &.horizontal .icon {
    color: var(--#{$theme});
  }

  &.vertical .icon {
    background: var(--#{$theme});
  }
}

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .stepper {
    flex: 1;
    min-height: 0;
  }
}

.loading {
  background: theme("colors.gray.200") !important;
}

.stepper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  &.has-check-icon.vertical {
    .step {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      &.themed-text {
          &.primary { color: var(--primary); }
          &.info { color: var(--info); }
          &.danger { color: var(--danger); }
          &.warning { color: var(--warning); }
          &.success { color: var(--success); }
          &.secondary { color: var(--secondary); }
        &.dark { color: var(--dark); }
      }
    }
  }

  &.has-check-icon.horizontal {
    .step {
      display: flex;
      width: 19px;
      align-items: center;

      &.themed-text {
          &.primary { color: var(--primary); }
          &.info { color: var(--info); }
          &.danger { color: var(--danger); }
          &.warning { color: var(--warning); }
          &.success { color: var(--success); }
          &.secondary { color: var(--secondary); }
          &.dark { color: var(--dark); }
        }

      .inner-step {
        padding: 0;
        height: 19px;
        width: 19px;
        border-radius: 50%;
        border: 2px solid var(--stepper-text) !important;

        &.interactive {
          cursor: pointer;
        }

        &.previous {
          background: var(--stepper-text) !important;
        }

        &.dashed {
          border-style: dashed !important;
        }

        &.solid {
          border-style: solid !important;
        }
      }

      .step-label {
        margin-top: 8px;
        text-align: center;
        font-size: 0.8rem;
        color: var(--text);
      }
    }
  }

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
    height: 100%;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 1 auto;
    position: relative;

    .inner-step {

      &.previous,
      &.active {
        &.primary {
          @include theme-colors(primary);
        }

        &.info {
          @include theme-colors(info);
        }

        &.danger {
          @include theme-colors(danger);
        }

        &.warning {
          @include theme-colors(warning);
        }

        &.success {
          @include theme-colors(success);
        }

        &.secondary {
          @include theme-colors(secondary);
        }

        &.dark {
          @include theme-colors(dark);
        }
      }

      &.previous.blur-previous {
        opacity: 0.7 !important;
      }

      &.horizontal {
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: center;
        padding: 7px 24px;
        border-radius: 8px;

        &.solid {
          border: 1px solid var(--stepper-text);
        }

        &.dashed {
          border: 1px dashed var(--stepper-text);
        }

        .icon {
          font-size: 12px;
          color: var(--stepper-text);
        }
      }

      &.vertical {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 25px;

        .icon {
          min-height: 48px;
          min-width: 48px;
          height: 48px;
          width: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: var(--always-white);
          background: var(--stepper-text);
        }
      }

      &.themed-text .content-wrapper .label {
        color: var(--stepper-text);
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;

        .label {
          color: var(--text);
        }

        .description {
          color: var(--input-placeholder);
        }
      }

      &.horizontal .content-wrapper {
        line-height: 1;
      }
    }
  }

  .divider {
    flex: 1 1 auto;
    position: relative;

    &.vertical {
      margin-left: 22px;
    }

    &:not(.has-check-icon) {
      &.horizontal {
        margin-top: 26px;
      }
    }

    &.has-check-icon {
      &.horizontal {
        margin-top: 9px; // Half of the inner-step height
        height: 2px;
      }

    }

    @mixin divider-theme-colors($theme) {
      &.dashed {
        border-color: var(--#{$theme}) !important;
      }

      &.solid {
        background: var(--#{$theme}) !important;
      }
    }

    &.previous {
      &.primary {
        @include divider-theme-colors(primary);
      }

      &.info {
        @include divider-theme-colors(info);
      }

      &.danger {
        @include divider-theme-colors(danger);
      }

      &.warning {
        @include divider-theme-colors(warning);
      }

      &.success {
        @include divider-theme-colors(success);
      }

      &.secondary {
        @include divider-theme-colors(secondary);
      }

      &.dark {
        @include divider-theme-colors(dark);
      }

      &.blur-previous {
        opacity: 0.7 !important;
      }
    }

    &.horizontal {
      height: 1px;
      align-self: flex-start;
    }

    &.vertical {
      width: 4px;
      align-self: stretch;
    }

    &.dashed {
      &.horizontal {
        border-top: 1px dashed var(--stepper-text);
      }

      &.vertical {
        border-left: 4px dashed var(--stepper-text);
      }
    }

    &.solid {
      background: var(--stepper-text);
    }

    &.has-progress.solid {
      &.in-progress {
        z-index: 10;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
        }

        &.horizontal::before {
          left: 0;
          height: 100%;
          width: 50%;
        }

        &.horizontal::after {
          right: 0;
          height: 100%;
          width: 50%;
          background: var(--stepper-text);
        }

        &.vertical::before {
          top: 0;
          height: 50%;
          width: 100%;
        }

        &.vertical::after {
          bottom: 0;
          height: 50%;
          background: var(--stepper-text);
        }
      }

      @each $theme in (primary, info, danger, warning, success, secondary, dark) {
        &.#{$theme}.in-progress::before {
          background: var(--#{$theme});
        }
      }
    }
  }
}

.step-skeleton {
  margin: 0 12px;
}

.skeleton-step {
  &.horizontal {
    padding: 7px 24px;
    border-radius: 8px;
    border: 1px solid var(--stepper-text);
  }

  &.vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
  }
}
</style>
