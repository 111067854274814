import { createGlobalState } from "@vueuse/core";
import { useSharedStoreUserGroups } from "./useSharedStoreUserGroups";
import { useSharedStoreUsers } from "./useSharedStoreUsers";

export const useSharedStore = createGlobalState(
  () => {
    const usersStore = useSharedStoreUsers();
    const userGroupsStore = useSharedStoreUserGroups();

    function reset() {
      usersStore._reset();
      userGroupsStore._reset();
    }

    return {
      ...usersStore,
      ...userGroupsStore,
      reset,
    };
  },
);
