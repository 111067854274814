export function useKeyDown(
  show: () => void,
  emit: {
    (evt: "keydown", event: KeyboardEvent): void
  },
) {
  function onKeyDown(e: KeyboardEvent) {
    if (e.key.length === 1) {
      show();
    }
    emit("keydown", e);
  }

  return {
    onKeyDown,
  };
}
