<script>
import { watch } from "vue";
import { usePaginate, useSearchable } from "../../composables";

export default {
  name: "VSearch",
  props: {
    placeholderText: {
      type: String,
      default: "",
    },
    clearSearchText: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { searchValue, clearSearch, debouncedValue } = useSearchable();
    const { resetAndLoad } = usePaginate();

    watch(debouncedValue, () => resetAndLoad());

    return {
      searchValue,
      clearSearch,
    };
  },
};
</script>

<template>
  <div class="search">
    <VInput
      v-model="searchValue"
      name="search"
      :placeholder="placeholderText"
      text-align="is-text-align-left"
      has-icon-right-action
      variant="is-white"
      :icon-left="['far', 'search']"
      icon-right="times"
      @on-right-icon-click="clearSearch"
    />
  </div>
</template>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  margin-right: 15px;

  .icon {
    color: var(--text);
    cursor: pointer;
    font-size: 20px;
    margin-left: 10px;
  }
}
</style>
