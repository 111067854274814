<script setup lang="ts">
import type { TabItemRef, TabsContext } from "./types";
import { computed, provide, ref, watch } from "vue";

interface Props {
  activeIndex?: number
  orientation?: "horizontal" | "vertical"
  scrollable?: boolean
  lazy?: boolean
  tile?: boolean
  inkbar?: boolean
  listPosition?: "left" | "right"
  centerList?: boolean
  dividerBetweenTabs?: boolean
}

const {
  activeIndex = 0,
  orientation = "horizontal",
  scrollable = false,
  lazy = false,
  tile = false,
  inkbar = true,
  listPosition = "left",
  centerList = false,
  dividerBetweenTabs = false,
} = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:activeIndex", value: number): void
}>();

const activeIndexRef = ref(activeIndex ?? 0);
const orientationRef = computed(() => orientation ?? "horizontal");
const listPositionRef = computed(() => listPosition ?? "left");

const tabItems = ref<TabItemRef[]>([]);

function registerTab(tab: TabItemRef) {
  tabItems.value.push(tab);
}

function unregisterTab(index: number) {
  tabItems.value = tabItems.value.filter(tab => tab.index !== index);
}

provide("tabs", {
  activeIndex: activeIndexRef,
  tabItems,
  registerTab,
  unregisterTab,
  onTabClick: (index: number) => {
    activeIndexRef.value = index;
    emit("update:activeIndex", index);
  },
} satisfies TabsContext);

provide("tabPanelLazy", lazy);
provide("scrollable", scrollable);
provide("tabOrientation", orientationRef);
provide("tile", tile);
provide("inkbar", inkbar);
provide("listPosition", listPositionRef);
provide("centerList", centerList);
provide("dividerBetweenTabs", dividerBetweenTabs);

watch(() => activeIndex, (newValue) => {
  if (newValue !== undefined) {
    activeIndexRef.value = newValue;
  }
});
</script>

<template>
  <div
    class="flex w-full h-full min-h-0"
    :class="[
      orientationRef === 'horizontal'
        ? 'flex-col'
        : `flex-row${listPositionRef === 'right' ? '-reverse' : ''}`,
    ]"
  >
    <slot />
  </div>
</template>
