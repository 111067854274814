import type { InternalAxiosRequestConfig } from "axios";
import type { InterceptorConfig } from "../../createApi.ts";
import { InterceptorType } from "../../createApi.ts";

export function BaseUrlRequestInterceptor(
  baseUrlGetter: (requestConfig: InternalAxiosRequestConfig) => string,
): InterceptorConfig<InterceptorType.REQUEST> {
  async function resolved(requestConfig: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
    requestConfig.baseURL = baseUrlGetter(requestConfig);

    return requestConfig;
  }

  return {
    name: "BaseUrlRequestInterceptor",
    type: InterceptorType.REQUEST,
    priority: 1,
    resolved,
  };
}
