import { computed, inject, provide, ref, watch } from "vue";

const CursorKey = Symbol("Cursor");

function createCursor({
  partial = false,
}) {
  const perPage = ref(10);
  const currentPage = ref(1);
  const totalItems = ref<number | null>(null);

  const totalPages = computed(() => {
    if (totalItems.value === null) {
      return 1;
    }
    if (totalItems.value === 0) {
      return 1;
    }
    return Math.ceil(totalItems.value / perPage.value);
  });

  const hasNextPage = computed(() => {
    if (totalItems.value === null || totalItems.value === 0) {
      return false;
    }

    // When no totalItems or next hint is given, calculate the amount of results on a page.
    // (25 * 3 = 75) === 75)
    if (partial && (perPage.value * currentPage.value) === totalItems.value) {
      return true;
    }

    return currentPage.value < totalPages.value;
  });

  function resetCursor() {
    currentPage.value = 1;
    totalItems.value = null;
  }

  watch(perPage, (oldSize, newSize) => {
    if (oldSize > newSize) {
      resetCursor();
    }
  });

  function setPaginateUrlParams(urlParams: URLSearchParams) {
    if (currentPage.value !== 1) {
      urlParams.append("partial", `${partial}`);
    }
    urlParams.append("perPage", `${perPage.value}`);
    urlParams.append("page", `${currentPage.value}`);
  }

  return {
    resetCursor,
    hasNextPage,
    perPage,
    currentPage,
    totalPages,
    totalItems,
    setPaginateUrlParams,
  };
}

type CreateCursorParameters = Parameters<typeof createCursor>[0];
export function provideCursor(params: CreateCursorParameters) {
  const instance = createCursor(params);
  provide(CursorKey, instance);
  return instance;
}

type UseCursor = ReturnType<typeof createCursor>;
export function useCursor() {
  const instance = inject<UseCursor>(CursorKey);

  if (!instance) {
    throw new Error("Run provideCursor before useCursor.");
  }

  return instance;
}
