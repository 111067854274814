import type { Dropdown } from "floating-vue";
import type { Ref } from "vue";
import { ref } from "vue";

type Item = number | string | Record<string, any>;

interface PopperElement extends HTMLElement {
  querySelector: (selector: string) => HTMLElement | null
}

interface PopperRefs {
  popperElement?: PopperElement
  popperContent?: {
    popperId: string
  }
}

export function useArrowControls(
  dropdown: Ref<(InstanceType<typeof Dropdown> & { $refs: PopperRefs }) | null>,
  items: Ref<Item[]>,
  setValueRef: (value: Item | null) => void,
) {
  const hovered = ref<Item | null>(null);

  function setNewIndex(newIndex: number): void {
    let list: HTMLElement | null = null;

    if (dropdown.value?.$refs.popperElement) {
      list = dropdown.value.$refs.popperElement.querySelector(".items");
    } else if (dropdown.value?.$refs.popperContent?.popperId) {
      const popperId = dropdown.value.$refs.popperContent.popperId;
      const element = document.getElementById(popperId);
      list = element?.querySelector(".items") ?? null;
    }

    if (!list) {
      return;
    }

    const element = list.querySelectorAll("div.item")[newIndex] as HTMLElement;

    if (!element) {
      return;
    }

    const visMin = list.scrollTop;
    const visMax = list.scrollTop + list.clientHeight - element.clientHeight;

    if (element.offsetTop < visMin) {
      list.scrollTop = element.offsetTop;
    } else if (element.offsetTop >= visMax) {
      list.scrollTop = element.offsetTop - list.clientHeight + element.clientHeight;
    }

    hovered.value = items.value[newIndex];
  }

  function onArrowUp(): void {
    if (!hovered.value) {
      hovered.value = items.value[0];
      return;
    }
    const currentIndex = items.value.findIndex((item) => {
      if (typeof hovered.value! === "object" && typeof item === "object") {
        return hovered.value!.id === item.id;
      }
      return hovered.value! === item;
    });
    const newIndex = currentIndex === 0 ? currentIndex : currentIndex - 1;

    setNewIndex(newIndex);
  }

  function onArrowDown(): void {
    if (!hovered.value) {
      hovered.value = items.value[0];
      return;
    }
    const currentIndex = items.value.findIndex((item) => {
      if (typeof hovered.value! === "object" && typeof item === "object") {
        return hovered.value!.id === item.id;
      }
      return hovered.value! === item;
    });
    const newIndex
      = items.value.length - 1 === currentIndex ? currentIndex : currentIndex + 1;

    setNewIndex(newIndex);
  }

  function onEnter(e: KeyboardEvent): void {
    e.stopImmediatePropagation();
    e.preventDefault();
    if (hovered.value === null) {
      return;
    }
    setValueRef(hovered.value);
    hovered.value = null;
  }

  return {
    onArrowUp,
    onArrowDown,
    hovered,
    onEnter,
  };
}
