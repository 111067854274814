<script setup lang="ts">
import type { Price } from "../../composables/Api/Type/PriceSelector";
import { defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { usePackageStore } from "./usePackageStore";
import PriceDisplay from "./VPriceDisplay.vue";

const props = defineProps<{
  price: Price
  purchasable: boolean
}>();
// this component shows either the price per year or month
// it also checkes is its purchasable and shows a icon if true

const { n, t } = useI18n();
const monthlyPrice = n(props.price.monthly, "currency");
const yearlyPrice = n(props.price.yearly, "currency");

const { isYearly } = usePackageStore();
</script>

<template>
  <div v-if="purchasable">
    <div v-if="isYearly">
      <PriceDisplay
        :currency="yearlyPrice[0]"
        :amount="price.yearly"
        :billing-frequency="t('base.perYear')"
      />
    </div>
    <div v-else>
      <PriceDisplay
        :currency="monthlyPrice[0]"
        :amount="price.monthly"
        :billing-frequency="t('base.perMonth')"
      />
    </div>
  </div>
  <FontAwesomeIcon
    v-else
    class="icon is-success text-[30px] text-theme-light-gray-2"
    :icon="['far', 'briefcase']"
  />
</template>

<style scoped></style>
