<script setup lang="ts">
import { computed } from "vue";

const {
  variant = "is-horizontal",
  noMargins = false,
} = defineProps<{
  variant?: string
  noMargins?: boolean
}>();

const slots = defineSlots<{
  default: () => any
}>();

const hasContent = computed(() => {
  return slots.default && slots.default().length > 0;
});
</script>

<template>
  <div class="v-divider" :class="[variant, { 'no-margins': noMargins, 'has-content': hasContent }]">
    <template v-if="hasContent">
      <div class="content">
        <slot />
      </div>
    </template>
    <div v-else class="line" />
  </div>
</template>

<style lang="scss" scoped>
.v-divider {
  position: relative;
  color: var(--text);
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  text-transform: uppercase;

  .line {
    position: absolute;
    background-color: var(--divider-color);
    width: 100%;
    height: 1px;
  }

  &.is-horizontal {
    .line {
      top: 50%;
      transform: translateY(-50%);
    }

    &.has-content {
      height: 2px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: var(--divider-color);
        height: 2px;
        width: calc(50% - 50px);
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }

  &.is-vertical {
    margin: 0 10px;

    .line {
      width: 2px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &.has-content {
      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: var(--divider-color);
        width: 2px;
        height: calc(50% - 25px);
        left: 50%;
        transform: translateX(-50%);
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }

  &.no-margins {
    margin: 0;
  }

  .content {
    position: relative;
    z-index: 1;
    padding: 0 10px;
    background-color: inherit;
  }
}
</style>
