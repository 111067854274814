import type { InternalAxiosRequestConfig } from "axios";
import type { InterceptorConfig } from "../../createApi.ts";
import { InterceptorType } from "../../createApi.ts";

export function contentTypeInterceptor({
  excludedRoutes,
  skipRoute,
}: {
  excludedRoutes?: string[]
  skipRoute?: (config: InternalAxiosRequestConfig<any>) => boolean
} = {}): InterceptorConfig<InterceptorType.REQUEST> {
  async function resolved(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig> {
    if (!config.url) {
      return config;
    }

    if (excludedRoutes && excludedRoutes.includes(config.url)) {
      return config;
    }

    if (skipRoute && skipRoute(config)) {
      return config;
    }

    config.headers = config.headers || {};

    if ((config.method?.toLowerCase())) {
      if (config.method?.toLowerCase() === "patch") {
        // Set the Content-Type header to 'application/merge-patch+json'
        config.headers["Content-Type"] = "application/merge-patch+json";
      }

      if (["post", "put"].includes(config.method?.toLowerCase())) {
        // Set the Content-Type header to 'application/merge-patch+json'
        config.headers["Content-Type"] = "application/ld+json";
      }
    }

    return config;
  }

  return {
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved,
  };
}
