import { ref } from "vue";

const DEFAULT_TIMER_STEPS = 10;

interface TimerArguments {
  onComplete: () => void
  delay: number
  autoStartWhenDocumentFocus?: boolean
  steps?: number
}

export function createTimer({
  onComplete,
  delay,
  autoStartWhenDocumentFocus = true,
  steps = DEFAULT_TIMER_STEPS,
}: TimerArguments) {
  let timerId: number | null;
  const remaining = ref(delay);

  function stop() {
    if (!timerId) {
      return;
    }
    window.clearInterval(timerId);
    timerId = null;
    window.removeEventListener("blur", onBlur);
    window.removeEventListener("focus", onFocus);
  }

  function start() {
    if (timerId) {
      return;
    }

    timerId = window.setInterval(() => {
      remaining.value -= steps;
      if (remaining.value <= 0) {
        onComplete();
        stop();
      }
    }, steps);
  }

  function onBlur() {
    stop();
  }

  function onFocus() {
    start();
  }

  if (autoStartWhenDocumentFocus) {
    window.addEventListener("blur", onBlur);
    window.addEventListener("focus", onFocus);
  }

  if (autoStartWhenDocumentFocus && document.hasFocus()) {
    start();
  }

  return {
    remaining,
    stop,
    start,
  };
}
