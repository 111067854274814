import type { AxiosInstance, AxiosResponse } from "axios";
import type { ClientError, NetworkError, ServerError } from "../../Error";

export interface AuthPluginConfigInterface<Output> {
  path: AuthPath
  axios: AxiosInstance
  onAuthenticateSuccess?: (response: Output) => Promise<void>
  onAuthenticateFailed?: (error: ClientError | ServerError | NetworkError) => Promise<void>
}

export enum AuthPath {
  OAUTH_REFRESH_V2 = "/oauth/v2/token",
}

export function createAuthenticatePlugin<Input, Output>(
  config: AuthPluginConfigInterface<Output>,
) {
  const currentPromise: Record<
    AuthPath,
        null | Promise<Output>
  > = {
    [AuthPath.OAUTH_REFRESH_V2]: null,
  };

  function clearCurrentPromise() {
    currentPromise[config.path] = null;
  }

  async function authenticate(
    input: Input,
  ): Promise<Output> {
    let promise = currentPromise[config.path];
    console.log("promise", promise);
    if (!promise) {
      promise = config.axios.post<Output>(config.path, input).then(async (r: AxiosResponse) => {
        if (config.onAuthenticateSuccess) {
          await config.onAuthenticateSuccess(r.data);
        }

        return r.data;
      }).catch(async (e: ClientError | ServerError | NetworkError) => {
        if (config.onAuthenticateFailed) {
          await config.onAuthenticateFailed(e);
        }

        throw e;
      }).finally(clearCurrentPromise);

      currentPromise[config.path] = promise;
    }

    await promise;

    return promise;
  }

  return { authenticate };
}
