<script setup lang="ts">
import type { ComputedRef, VNode } from "vue";
import type { TabsContext } from "./types";
import { computed, inject, nextTick, onMounted, ref, watch } from "vue";

defineSlots<{
  "default"?: () => VNode[]
  "prev-icon"?: () => VNode[]
  "next-icon"?: () => VNode[]
}>();

const tabsContainer = ref<HTMLElement | null>(null);
const inkbarRef = ref<HTMLElement | null>(null);
const showPrevButton = ref(false);
const showNextButton = ref(false);
const shouldShowButtons = ref(false);

// Get tabs context
const tabs = inject<TabsContext>("tabs", {
  activeIndex: ref(0),
  tabItems: ref([]),
  registerTab: () => {},
  unregisterTab: () => {},
  onTabClick: () => {},
});
const activeIndex = computed(() => tabs.activeIndex.value);
const activeTabError = computed(() => {
  const activeTab = tabs.tabItems.value.find(tab => tab.index === activeIndex.value);
  return activeTab?.error ?? false;
});

const orientation = inject<ComputedRef<"horizontal" | "vertical">>("tabOrientation")!;
const tile = inject("tile", false);
const scrollable = inject("scrollable", false);
const inkbar = inject("inkbar", true);
const listPosition = inject<ComputedRef<"left" | "right">>("listPosition")!;
const centerList = inject("centerList", false);
const dividerBetweenTabs = inject("dividerBetweenTabs", false);

function getScrollContainer() {
  return tabsContainer.value?.querySelector(".tab-items-container");
}

function checkOverflow() {
  const container = getScrollContainer();
  if (!container || !tabsContainer.value) {
    return;
  }

  const isVertical = orientation.value === "vertical";
  const size = isVertical ? "Height" : "Width";

  const containerElement = container as HTMLElement;
  const hasOverflow = containerElement[`scroll${size}`] > containerElement[`offset${size}`];

  shouldShowButtons.value = hasOverflow && !scrollable;

  if (shouldShowButtons.value) {
    const pos = isVertical ? "Top" : "Left";
    showPrevButton.value = containerElement[`scroll${pos}`] > 0;
    showNextButton.value = containerElement[`scroll${pos}`] < (containerElement[`scroll${size}`] - containerElement[`offset${size}`]);
  } else {
    showPrevButton.value = false;
    showNextButton.value = false;
  }
}

function updateInkBar() {
  if (!tabsContainer.value || !inkbarRef.value || !inkbar) {
    return;
  }

  const activeTab = tabsContainer.value.querySelector(".tab-item[aria-selected='true']");
  if (!activeTab) {
    return;
  }

  const scrollContainer = getScrollContainer();
  if (!scrollContainer) {
    return;
  }

  const activeTabRect = (activeTab as HTMLElement).getBoundingClientRect();
  const containerRect = scrollContainer.getBoundingClientRect();

  const isVertical = orientation.value === "vertical";
  const scrollOffset = isVertical ? scrollContainer.scrollTop : scrollContainer.scrollLeft;

  const pos = isVertical
    ? activeTabRect.top - containerRect.top + scrollOffset
    : activeTabRect.left - containerRect.left + scrollOffset;

  const size = isVertical ? activeTabRect.height : activeTabRect.width;

  const styles = isVertical
    ? {
        top: `${pos}px`,
        height: `${size}px`,
        width: "2px",
        ...(tile
          ? {
              [listPosition.value === "left" ? "right" : "left"]: "0",
              borderRadius: "0",
            }
          : {
              [listPosition.value === "left" ? "right" : "left"]: "0",
              borderRadius: undefined,
            }),
      }
    : {
        left: `${pos}px`,
        width: `${size}px`,
        height: "2px",
        bottom: "0",
      };

  inkbarRef.value.style.cssText = Object.entries(styles)
    .filter(([,value]) => value !== undefined)
    .map(([key, value]) => `${key}: ${value}`)
    .join(";");
}

function scroll(direction: 1 | -1) {
  const container = getScrollContainer();
  if (!container) {
    return;
  }

  const isVertical = orientation.value === "vertical";
  const amount = isVertical ? container.clientHeight : container.clientWidth;
  const prop = isVertical ? "top" : "left";

  container.scrollBy({
    [prop]: amount * direction,
    behavior: "smooth",
  });
}

function scrollPrev() {
  scroll(-1);
}

function scrollNext() {
  scroll(1);
}

watch(activeIndex, () => {
  nextTick(() => {
    updateInkBar();
  });
});

function onScroll() {
  checkOverflow();
  updateInkBar();
}

onMounted(() => {
  nextTick(() => {
    checkOverflow();
    updateInkBar();
  });

  const container = getScrollContainer();
  if (container) {
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
      updateInkBar();
    });
    resizeObserver.observe(container);
    if (container.parentElement) {
      resizeObserver.observe(container.parentElement);
    }
  }
});
</script>

<template>
  <div
    ref="tabsContainer"
    class="relative flex bg-transparent"
    :class="[
      orientation === 'horizontal'
        ? 'flex-row items-center w-full border-b-1 border-gray-100 dark:border-dark-700'
        : [
          'flex-col',
          listPosition === 'left'
            ? 'border-r-1 border-gray-100 dark:border-dark-700'
            : 'border-l-1 border-gray-100 dark:border-dark-700',
        ],
      orientation === 'vertical' && !tile ? 'min-w-[200px]' : '',
    ]"
    role="VTabList"
  >
    <button
      v-if="shouldShowButtons"
      :disabled="!showPrevButton"
      class="absolute flex items-center justify-center border-none bg-transparent cursor-pointer text-primary-600 dark:text-primary-400 z-10 p-2 hover:bg-gray-50 dark:hover:bg-dark-800 shadow-[4px_0_6px_-4px_rgba(0,0,0,0.1)] dark:shadow-[4px_0_6px_-4px_rgba(0,0,0,0.3)] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-white dark:disabled:hover:bg-dark-850"
      :class="[
        orientation === 'horizontal'
          ? 'top-0 h-full left-0'
          : [
            'w-full h-8 top-0',
            listPosition === 'left' ? 'left-0' : 'right-0',
          ],
      ]"
      @click.prevent="scrollPrev"
      @mousedown.stop
    >
      <slot name="prev-icon">
        <FontAwesomeIcon :icon="['fas', orientation === 'horizontal' ? 'chevron-left' : 'chevron-up']" />
      </slot>
    </button>

    <div
      class="flex flex-1 overflow-hidden relative"
      :class="[
        orientation === 'horizontal' ? 'flex-row' : 'flex-col w-full',
        shouldShowButtons ? (orientation === 'horizontal' ? 'mx-8' : 'my-8') : '',
      ]"
    >
      <div
        class="tab-items-container flex flex-1 relative"
        :class="[
          orientation === 'horizontal' ? 'flex-row' : 'flex-col',
          tile ? 'gap-1.5' : '',
          !tile && !dividerBetweenTabs ? 'gap-1' : '',
          centerList ? 'justify-center' : '',
          orientation === 'vertical' && listPosition === 'left' && tile ? 'pr-3' : '',
          orientation === 'vertical' && listPosition === 'right' && tile ? 'pl-3' : '',
          scrollable ? [
            'overflow-auto scrollbar-hide',
            orientation === 'vertical' ? 'overflow-x-hidden' : '',
          ] : 'overflow-hidden scrollbar-hide',
        ]"
        @scroll="onScroll"
      >
        <div
          class="flex relative items-center"
          :class="[
            orientation === 'horizontal' ? 'flex-row' : 'flex-col w-full',
            tile ? 'gap-2' : '',
          ]"
        >
          <template v-for="(item, index) in $slots.default?.() || []" :key="index">
            <component :is="item" />
            <div
              v-if="dividerBetweenTabs
                && index < ($slots.default?.()?.length || 0) - 1
                && (item.type as any).__name === 'VTabItem'
                && ($slots.default?.()[index + 1].type as any).__name === 'VTabItem'"
              class="flex-shrink-0"
              :class="[
                orientation === 'horizontal'
                  ? 'w-0.5 h-[calc(100%-1rem)] mx-2 bg-gray-100 dark:bg-dark-600 my-2'
                  : [
                    'w-[calc(100%-2rem)] h-0.5 bg-gray-100 dark:bg-dark-600 my-2',
                    listPosition === 'left' ? 'mr-2' : 'ml-2',
                  ],
              ]"
            />
          </template>
        </div>
        <div
          v-if="inkbar"
          class="absolute bg-gray-100 dark:bg-dark-700"
          :class="[
            orientation === 'vertical'
              ? [
                'top-0 bottom-0 w-[2px]',
                listPosition === 'left' ? 'right-0' : 'left-0',
              ]
              : 'bottom-0 left-0 right-0 h-[2px]',
          ]"
        />
        <div
          v-if="inkbar"
          ref="inkbarRef"
          class="absolute transition-all duration-200 z-10"
          :class="[
            activeTabError ? '!bg-red-600 !dark:bg-red-400' : 'bg-primary-600 dark:bg-primary-400',
            orientation === 'vertical' && tile ? 'rounded-lg' : '',
          ]"
        />
      </div>
    </div>

    <button
      v-if="shouldShowButtons"
      :disabled="!showNextButton"
      class="absolute flex items-center justify-center border-none bg-transparent cursor-pointer text-primary-600 dark:text-primary-400 z-10 p-2 hover:bg-gray-50 dark:hover:bg-dark-800 shadow-[-4px_0_6px_-4px_rgba(0,0,0,0.1)] dark:shadow-[-4px_0_6px_-4px_rgba(0,0,0,0.3)] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-white dark:disabled:hover:bg-dark-850"
      :class="[
        orientation === 'horizontal'
          ? 'top-0 h-full right-0'
          : [
            'w-full h-8 bottom-0',
            listPosition === 'left' ? 'left-0' : 'right-0',
          ],
      ]"
      @click.prevent="scrollNext"
      @mousedown.stop
    >
      <slot name="next-icon">
        <FontAwesomeIcon :icon="['fas', orientation === 'horizontal' ? 'chevron-right' : 'chevron-down']" />
      </slot>
    </button>
  </div>
</template>
