<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "VToastClose",
  components: { FontAwesomeIcon },
  emits: ["closeToast"],
};
</script>

<template>
  <div class="close-icon" @onClick="$emit('closeToast')">
    <FontAwesomeIcon class="icon" :icon="['far', 'times']" />
  </div>
</template>

<style lang="scss">
.close-icon {
  font-size: 20px;
}
</style>
