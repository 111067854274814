<script>
import { computed } from "vue";

export default {
  name: "VDayItem",
  props: {
    day: {
      type: Date,
      required: true,
    },
    viewingMonth: {
      type: Number,
      required: true,
    },
    selectedDate: {
      type: Date,
      default: null,
    },
  },
  emits: ["click"],
  setup(props) {
    function dateEquals(date1, date2) {
      return (
        date1.getDate() === date2.getDate()
        && date1.getMonth() === date2.getMonth()
        && date1.getFullYear() === date2.getFullYear()
      );
    }

    const isToday = computed(() => dateEquals(props.day, new Date()));
    const isSelected = computed(
      () => props.selectedDate && dateEquals(props.day, props.selectedDate),
    );

    return {
      isToday,
      isSelected,
    };
  },
};
</script>

<template>
  <div
    :class="{
      'is-selected-month': day.getMonth() === viewingMonth,
      'is-today': isToday,
      'is-active': isSelected,
    }"
    class="date-cell"
    @click="$emit('click', day)"
  >
    <span class="cell">
      {{ day.getDate() }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.date-cell {
  font-size: 12px;
  color: var(--text);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 50ms;
  border-radius: 50%;
  padding: 5px 0;

  &:hover,
  &.is-active {
    background-color: var(--primary);
    color: white !important;
  }

  &.is-today {
    color: var(--primary);
    font-weight: bold;
  }

  &:not(.is-selected-month) {
    color: var(--gray-1);
  }
}
</style>
