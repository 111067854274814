<script setup lang="ts">
import type { SliderContext } from "./types";
import { provide, ref } from "vue";

interface Props {
  modelValue?: number
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  orientation?: "horizontal" | "vertical"
  showTooltip?: boolean
  tooltipFormat?: (value: number) => string
  size?: "sm" | "md" | "lg"
  marks?: Array<{ value: number, label?: string }>
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: 0,
  min: 0,
  max: 100,
  step: 1,
  disabled: false,
  orientation: "horizontal",
  showTooltip: true,
  size: "md",
  marks: () => [],
  tooltipFormat: (value: number) => `${value}`,
});

const emit = defineEmits<{
  (e: "update:modelValue", value: number): void
  (e: "change", value: number): void
}>();

const value = ref(props.modelValue);

provide<SliderContext>("slider", {
  value,
  min: props.min,
  max: props.max,
  step: props.step,
  disabled: props.disabled,
  orientation: props.orientation,
  showTooltip: props.showTooltip,
  tooltipFormat: props.tooltipFormat,
  size: props.size,
  marks: props.marks,
  updateValue: (newValue: number) => {
    value.value = newValue;
    emit("update:modelValue", newValue);
    emit("change", newValue);
  },
});
</script>

<template>
  <div
    class="relative"
    :class="{
      'w-full': orientation === 'horizontal',
      'h-[200px]': orientation === 'vertical',
    }"
  >
    <slot />
  </div>
</template>
