<script setup lang="ts">
import { computed } from "vue";

defineOptions({
  name: "ListItem",
});

const props = defineProps<Props>();

export interface Props {
  item: number | string | Record<string, any>
  currentValue: null | number | string | Record<string, any>
  hovered: (number | string | Record<string, any>) | null
  keyFieldName: string | null
  uniqueKeyOfObject: string
}

function getValue(item1: number | string | Record<string, any>, key: string | null): any {
  if (typeof item1 !== "object") {
    return item1;
  }
  if (!key) {
    return item1;
  }

  return item1[key];
}

const loading = computed(() => typeof props.item === "number");

const isActive = computed(() => {
  if (!props.item) {
    return false;
  }
  if (props.currentValue === null) {
    return;
  }

  if (typeof props.currentValue === "object") {
    return props.currentValue[props.uniqueKeyOfObject] === getValue(props.item, props.uniqueKeyOfObject);
  }

  return props.currentValue === getValue(props.item, props.keyFieldName);
});

const isHovered = computed(() => {
  if (!props.hovered) {
    return false;
  }

  if (typeof props.item === "object") {
    return props.item[props.uniqueKeyOfObject] === getValue(props.hovered, props.uniqueKeyOfObject);
  }

  return props.item === getValue(props.hovered, props.keyFieldName);
});
</script>

<template>
  <div class="item items-center" :class="{ 'is-active': isActive, 'is-hovered': isHovered, 'is-loading': loading }">
    <VSkeleton v-if="loading" dynamic-width :height="10" />
    <slot v-else :is-active="isActive" :item="item" />
    <FontAwesomeIcon
      v-if="isActive"
      class="icon"
      :icon="['fass', 'circle-check']"
    />
  </div>
</template>

<style lang="scss" scoped>
.item {
  display: flex;
  gap: 5px;
  padding: 10px;
  cursor: pointer;
  transition: none;
  font-size: 14px;
  justify-content: space-between;

  .icon {
    color: var(--primary);
  }

  &:not(.is-loading) {
    &:hover,
    &.is-hovered,
    &:active,
    &.is-active {
      background-color: var(--input-background-active);
    }
  }
}
</style>
