import type { UserStatusHydraItem } from "@verbleif/lib";
import {
  ActivityCommentVoter,
  AdminVoter,
  AppSubscriptionVoter,
  AppVoter,
  LocationVoter,
  MediaObjectMetaDataVoter,
  PermissionService,
  PermissionTemplateGroupVoter,
  PropertyGroupVoter,
  PropertyVoter,
  ReportTopicVoter,
  ReportVoter,
  ShortcutVoter,
  SortVoter,
  TaskProductVoter,
  TaskTimeEntryVoter,
  TaskVoter,
  useAuthStore,
  useLocationStore,
  UserGroupVoter,
  UserVoter,
  useSharedStore,
} from "@verbleif/shared";
import { computed } from "vue";

export const permissionService = new PermissionService({
  setUserPermissions: () => useAuthStore().user.value?.permissions ?? [],
  setUserIri: () => useAuthStore().user.value?.["@id"] ?? null,
  setMode: () => useLocationStore().mode.value,
  setDefaultLocation: () => useLocationStore().modeSelectedLocation.value,
  setDefaultClient: () => useLocationStore().modeSelectedClient.value,
  setLocationClientMap: () => {
    const { locationsList } = useLocationStore();
    return locationsList.value.reduce((map, location) => {
      map.set(location["@id"], location.client);
      return map;
    }, new Map<string, string>());
  },
  setUsersData: () => {
    const { usersList } = useSharedStore();
    return usersList.value.reduce((map, user) => {
      map.set(user["@id"], { statuses: user.statuses });
      return map;
    }, new Map<string, { statuses: UserStatusHydraItem[] }>());
  },
});

export const appSubscriptionVoter = new AppSubscriptionVoter(permissionService);
export const appVoter = new AppVoter(permissionService);
export const locationVoter = new LocationVoter(permissionService);
export const permissionTemplateGroupVoter = new PermissionTemplateGroupVoter(permissionService);
export const propertyGroupVoter = new PropertyGroupVoter(permissionService);
export const propertyVoter = new PropertyVoter(permissionService);
export const shortcutVoter = new ShortcutVoter(permissionService);
export const adminVoter = new AdminVoter(permissionService);
export const sortVoter = new SortVoter(permissionService);
export const userGroupVoter = new UserGroupVoter(permissionService);
export const userVoter = new UserVoter(permissionService);
export const taskVoter = new TaskVoter(permissionService);
export const reportVoter = new ReportVoter(permissionService);
export const reportTopicVoter = new ReportTopicVoter(permissionService);
export const taskTimeEntryVoter = new TaskTimeEntryVoter(permissionService, taskVoter);
export const taskProductVoter = new TaskProductVoter(permissionService, taskVoter);
export const activityCommentVoter = new ActivityCommentVoter(permissionService, taskVoter, reportVoter);
export const mediaObjectMetaDataVoter = new MediaObjectMetaDataVoter(permissionService, taskVoter, reportVoter);
export const isAdmin = computed(() => adminVoter.isAdmin());

export function usePermsWatch() {
  watch([() => useAuthStore().user.value?.permissions, () => useLocationStore().selectedLocation.value], () => {
    if (!useAuthStore().isAuthenticated.value) {
      console.log("%c [usePermsWatch] not authenticated", "color: #FFA500; font-weight: bold;");
      return;
    }

    if (!useAuthStore().user.value?.permissions) {
      console.log("%c [usePermsWatch] no permissions", "color: #FFA500; font-weight: bold;");
      return;
    }

    if (!useLocationStore().selectedLocation.value) {
      console.log("%c [usePermsWatch] no location", "color: #FFA500; font-weight: bold;");
      return;
    }

    console.log("%c [usePermsWatch] init", "color: #4CAF50; font-weight: bold;");
    permissionService.init();
  }, {
    immediate: true,
    deep: true,
  });
}
