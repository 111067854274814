import type { I18nOptions } from "vue-i18n";
import { useNavigatorLanguage, useStorage } from "@vueuse/core";
import moment from "moment";
import { watch } from "vue";
import { createI18n } from "vue-i18n";
import * as messages from "./index";

const navigatorLanguage = useNavigatorLanguage();
const langSplit: string | undefined = navigatorLanguage.language.value?.split("-")?.[0];
export const locale = useStorage("language", langSplit || "nl", localStorage);

const options: I18nOptions = {
  locale: locale.value,
  fallbackLocale: "nl",
  messages: {
    ...messages,
  },
  numberFormats: {
    nl: {
      currency: {
        style: "currency",
        currency: "EUR",
      },
    },
  },
};

export const i18n = createI18n<false, typeof options>(options);

// Ensure locale changes are properly propagated
watch(
  locale,
  (newLocale) => {
    // @ts-expect-error legacy
    i18n.global.locale = newLocale;
    moment.locale(newLocale);
  },
  { immediate: true },
);

export default i18n;

export function useI18n() {
  return i18n.global;
}
