<script setup lang="ts">
import { computed, defineProps, ref, useSlots, watch } from "vue";
import { useWidthCalculator } from "../../composables";
import { useComponentId } from "../useComponentId";

const {
  name = "",
  disabled = false,
  size = "medium",
  label = "",
  labelPosition = "left",
  required = false,
  modelValue = false,
  trueValue = true,
  falseValue = false,
  iconLeft = false,
  errorMessage = "",
  hasPerms = true,
} = defineProps<{
  name?: string
  disabled?: boolean
  size?: "small" | "medium" | "large"
  label?: string
  labelPosition?: "left" | "right" | "top"
  required?: boolean
  modelValue?: any
  hasPerms?: boolean
  trueValue?: any
  falseValue?: any
  iconLeft?: boolean
  errorMessage?: string
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: any): void
}>();

defineSlots<{
  "label": void
  "icon-left": void
}>();

const isDisabled = computed(() => disabled || !hasPerms);

const slots = useSlots();
const id: any = useComponentId();
const { containerRef, width } = useWidthCalculator();
const valueRef = ref(modelValue);

// Watch for external changes
watch(() => modelValue, (newValue) => {
  valueRef.value = newValue;
});

// Emit changes
watch(valueRef, (newValue) => {
  emit("update:modelValue", newValue);
});

function toggle() {
  if (!isDisabled.value) {
    valueRef.value = valueRef.value === trueValue ? falseValue : trueValue;
  }
}

const showLabel = computed(() => !errorMessage && (label || slots.label));

const switchClasses = computed(() => ({
  "w-fit": true,
  "flex items-center": true,
  "opacity-50 cursor-not-allowed": isDisabled.value,
  "cursor-pointer": !isDisabled.value,
  "w-full": iconLeft || showLabel.value || errorMessage,
}));

const labelSizeClass = computed(() => {
  switch (size) {
    case "small":
      return "text-xs"; // 12px
    case "medium":
      return "text-sm"; // 14px
    case "large":
      return "text-base"; // 16px
    default:
      return "text-sm";
  }
});

const switchSizeClasses = computed(() => {
  const sizes = {
    small: {
      wrapper: "w-[33px] h-4",
      thumb: "w-2.5 h-2.5 left-1",
      thumbChecked: "left-[calc(100%-14px)]",
    },
    medium: {
      wrapper: "w-[52px] h-6",
      thumb: "w-4 h-4 left-[5px]",
      thumbChecked: "left-[calc(100%-21px)]",
    },
    large: {
      wrapper: "w-[72px] h-8",
      thumb: "w-5 h-5 left-[5px]",
      thumbChecked: "left-[calc(100%-26px)]",
    },
  };
  return sizes[size] || sizes.medium;
});
</script>

<template>
  <div class="flex flex-col">
    <!-- Top Label -->
    <label
      v-if="labelPosition === 'top' && showLabel"
      :for="id"
      class="flex items-center gap-1 mb-2 text-gray-700 dark:text-white"
      :class="[labelSizeClass]"
    >
      <slot name="label">{{ label }}</slot>
      <span v-if="required" class="text-red-500 font-bold">*</span>
    </label>

    <div :class="switchClasses">
      <div
        class="relative flex items-center justify-between w-full rounded-full"
        @click="toggle"
      >
        <!-- Icon -->
        <div
          v-if="iconLeft"
          ref="containerRef"
          class="absolute left-3 text-sm flex items-center"
        >
          <slot name="icon-left" />
        </div>

        <!-- Left Label/Error -->
        <template v-if="labelPosition === 'left'">
          <label
            v-if="errorMessage"
            class="text-red-500 mr-2"
            :class="[labelSizeClass]"
          >
            {{ errorMessage }}
          </label>
          <label
            v-else-if="showLabel"
            :for="id"
            :style="{ paddingLeft: iconLeft ? `${width + 15}px` : undefined }"
            class="text-gray-700 dark:text-white flex mr-2 items-center gap-1"
            :class="[labelSizeClass]"
          >
            <slot name="label">{{ label }}</slot>
            <span v-if="required" class="text-red-500 font-bold">*</span>
          </label>
        </template>

        <!-- Switch -->
        <ACTooltip
          type="edit_field"
          :allow-change="hasPerms"
        >
          <div class="relative">
            <input
              :id="id"
              v-model="valueRef"
              type="checkbox"
              :name="name"
              :disabled="isDisabled"
              :true-value="trueValue"
              :false-value="falseValue"
              class="sr-only"
            >
            <div
              class="rounded-full transition-colors duration-200 border-solid border-1"
              :class="[
                switchSizeClasses.wrapper,
                errorMessage
                  ? 'border border-red-500'
                  : 'border border-gray-300 dark:border-dark-600',
                valueRef === trueValue
                  ? errorMessage
                    ? 'border-red-500'
                    : 'border-primary-500 dark:border-primary-400'
                  : '',
                valueRef === trueValue ? 'bg-gray-50 dark:bg-primary-900/20' : 'bg-gray-50 dark:bg-dark-800',
              ]"
            >
              <div
                class="absolute top-1/2 -translate-y-1/2 rounded-full transition-all duration-200"
                :class="[
                  switchSizeClasses.thumb,
                  valueRef === trueValue ? switchSizeClasses.thumbChecked : '',
                  errorMessage
                    ? 'bg-red-500'
                    : valueRef === trueValue
                      ? 'bg-primary-500 dark:bg-primary-400'
                      : 'bg-gray-400 dark:bg-dark-500',
                ]"
              />
            </div>
          </div>
        </ACTooltip>

        <!-- Right Label/Error -->
        <template v-if="labelPosition === 'right'">
          <label
            v-if="errorMessage"
            class="text-red-500 ml-2"
            :class="[labelSizeClass]"
          >
            {{ errorMessage }}
          </label>
          <label
            v-else-if="showLabel"
            :for="id"
            class="text-gray-700 dark:text-white flex ml-2 items-center gap-1"
            :class="[labelSizeClass]"
          >
            <slot name="label">{{ label }}</slot>
            <span v-if="required" class="text-red-500 font-bold">*</span>
          </label>
        </template>
      </div>
    </div>
  </div>
</template>
