<script lang="ts" setup>
import NotificationItem from "@web/features/Notification/NotificationItem.vue";
import { useNotifications } from "@web/features/Notification/useNotifications";

const { notifications, hideNotification } = useNotifications();
</script>

<template>
  <div class="notification-container">
    <NotificationItem
      v-for="(notification, index) in notifications"
      :key="notification.iri"
      v-bind="notification"
      @close="() => hideNotification(index)"
    />
  </div>
</template>

<style lang="scss" scoped>
.notification-container {
  position: absolute;
  top: 18px;
  right: 25px;
  z-index: 1001;

  .notification-item:not(:last-child) {
    margin-bottom: 15px;
  }
}
</style>
