<script setup lang="ts">
import type { ModernSelectProps } from "../AutoComplete";
import { computed } from "vue";
import { VModernSelect } from "../AutoComplete";
import { countryNames, languageNames } from "./locale";

interface Props {
  availableLocales: string[]
  showFlag?: boolean
  updateLocale?: boolean
  countries?: boolean
  short?: boolean
}

const props = withDefaults(defineProps<Props & ModernSelectProps>(), {
  availableLocales: () => [],
  showFlag: true,
  updateLocale: false,
  countries: false,
  short: false,
  rootClass: "",
  modelValue: null,
  iconLeft: undefined,
  iconRight: "caret-down",
  loading: false,
  autoSize: false,
  disabled: false,
  errorMessage: null,
  hideClear: true,
  clearSearchOnSelect: false,
  clearValueOnSelect: false,
  singleItemAutoSelect: false,
  hasPerms: true,
  keyFieldName: null,
  uniqueKeyOfObject: "id",
  searchFieldName: "name" as Extract<keyof any, string>,
  noResultsText: "No results.",
  refreshText: "Refresh",
  labelLeft: "",
  required: false,
  debug: false,
  placeholder: null,
  items: () => [],
  excludedItems: () => [],
  onLoad: null,
  loadParams: () => { },
  initialItemProvider: async () => null,
  variant: "is-gray",
  showGroup: undefined,
  watchForChange: null,
  size: "medium",
  groupFieldName: null,
  placement: "bottom-start",
});

const emits = defineEmits(["changeLocale"]);

const locale = defineModel<string>();

const localeItems = computed(() => props.availableLocales.map((locale) => {
  return {
    id: locale,
    value: locale,
    name: props.countries
      ? countryNames[locale as keyof typeof countryNames] ?? locale
      : languageNames[locale as keyof typeof languageNames] ?? locale,
  };
}));

function onLocaleChange(value: string) {
  if (props.updateLocale) {
    emits("changeLocale", value);
  }
}
</script>

<template>
  <VModernSelect
    v-bind="$props"
    v-model="locale"
    :class="$attrs.class"
    :items="localeItems"
    key-field-name="value"
    :icon-left="['fas', 'globe']"
    search-field-name="name"
    @update:model-value="onLocaleChange"
  >
    <template #item="{ item }">
      <div class="flex items-center gap-2">
        <img
          v-if="showFlag"
          class="w-6 h-4 rounded border border-gray-300 dark:border-gray-600"
          :src="`/flags/${item.value.toUpperCase()}.svg`"
          :alt="`${item.value.toUpperCase()}.svg`"
        >
        <span>{{ short ? item.value : item.name }}</span>
      </div>
    </template>
  </VModernSelect>
</template>
