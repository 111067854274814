import { reactive } from "vue";

interface ModalInfo {
  zIndex: number
  style: {
    left: string
    top: string
    position: string
  }
  id: string
}

interface ModalState {
  modals: ModalInfo[]
  nextZIndex: number
}

const state = reactive<ModalState>({
  modals: [],
  nextZIndex: 1003, // Starting zIndex
});

function openModal(id: string, modal: HTMLElement, drag: HTMLElement, draggable?: boolean) {
  const existingModal = state.modals.find(m => m.id === id);

  if (!existingModal) {
    const modalInfo: ModalInfo = {
      id,
      style: {
        left: `${window.innerWidth / 2 - modal.offsetWidth / 2}px`,
        top: `${window.innerHeight / 2 - modal.offsetHeight / 2}px`,
        position: "fixed",
      },
      zIndex: state.nextZIndex++,
    };

    if (draggable && drag) {
      enableDraggable(modal, drag, id);
    }

    state.modals.push(modalInfo);
    bringModalToFront(id);
  } else {
    bringModalToFront(id);
  }
}

function closeModal(id: string) {
  state.modals = state.modals.filter(m => m.id !== id);
}

function isTopModal(id: string) {
  const topModal = state.modals[state.modals.length - 1];
  return topModal && topModal.id === id;
}

function bringModalToFront(id: string) {
  const modal = state.modals.find(m => m.id === id);
  if (modal) {
    modal.zIndex = state.nextZIndex++;
    // Move the modal to the end of the array to ensure it's the last in the DOM order
    state.modals = state.modals.filter(m => m.id !== id);
    state.modals.push(modal);
  }
}

// Custom logic for enabling drag
function enableDraggable(modal: HTMLElement, drag: HTMLElement, id: string) {
  let isDragging = false;
  let offsetX = 0;
  let offsetY = 0;

  drag.addEventListener("mousedown", (event) => {
    isDragging = true;
    offsetX = event.clientX - modal.getBoundingClientRect().left;
    offsetY = event.clientY - modal.getBoundingClientRect().top;
  });

  document.addEventListener("mousemove", (event) => {
    if (!isDragging) {
      return;
    }

    const x = event.clientX - offsetX;
    const y = event.clientY - offsetY;

    const modalInfo = state.modals.find(m => m.id === id);
    if (modalInfo) {
      modalInfo.style.left = `${x}px`;
      modalInfo.style.top = `${y}px`;
    }
  });

  document.addEventListener("mouseup", () => {
    isDragging = false;
  });
}

export function useModalStore() {
  return {
    state,
    openModal,
    closeModal,
    isTopModal,
    bringModalToFront,
  };
}
