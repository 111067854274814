<script setup lang="ts">
import { computed } from "vue";

interface Props {
  pill?: boolean
  width?: number
  widthUnit?: string
  dynamicWidth?: boolean
  height?: number | boolean
  heightUnit?: string
  noBackground?: boolean
}

const {
  pill = true,
  width = 50,
  widthUnit = "%",
  dynamicWidth = true,
  height = false,
  heightUnit = "px",
  noBackground = false,
} = defineProps<Props>();

const randomWidth = computed(() => {
  let innerWidth = width;
  if (dynamicWidth) {
    const max = innerWidth;
    let min = innerWidth - 25;
    if (min < 25) {
      min = 25;
    }

    innerWidth = Math.floor(Math.random() * (max - min + 1) + min);
  }
  return `${innerWidth}${widthUnit}`;
});

const heightStyle = computed(() => {
  if (!height) {
    return undefined;
  }
  return typeof height === "number" ? `${height}${heightUnit}` : undefined;
});
</script>

<template>
  <div
    class="skeleton"
    :class="[{ pill, height, 'no-background': noBackground }]"
    :style="{
      width: randomWidth,
      height: heightStyle,
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.skeleton {
  &.pill {
    border-radius: 9999px;
  }
  line-height: 1;
  display: inline-block;
  &:not(.height) {
    height: inherit;
  }
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  &:not(.no-background) {
    background-color: rgba(0, 0, 0, 0.08);
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.3),
      rgba(37, 22, 22, 0)
    );
    animation: shimmer 1.5s infinite;
    content: "";
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
