<script>
export default {
  name: "VFieldGroup",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    labelLeft: {
      type: [String, Boolean],
      default: false,
    },
  },
};
</script>

<template>
  <div class="field-group" style="">
    <div v-if="labelLeft" class="label">
      <span v-if="required" class="required">*</span>
      {{ labelLeft }}
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.field-group {
  display: flex;

  .label {
    .required {
      color: theme("colors.danger.600");
      font-weight: bold;
    }

    flex: 2 2 194px;
    max-width: 194px;
    font-size: 12px;
    color: var(--text);
  }

  .content {
    flex: 1 1 66%;
    display: flex;
    gap: 10px;
  }
}
</style>
