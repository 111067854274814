import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum, PermissionScope } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class UserGroupVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationUsergroupCreateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationUsergroupCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationUsergroupReadany,
      contextAttribute: PermissionAttributesEnum.ContextOperationUsergroupRead,
      scope: PermissionScope.USER_GROUP,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationUsergroupDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextOperationUsergroupDelete,
      scope: PermissionScope.USER_GROUP,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationUsergroupUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationUsergroupUpdate,
      scope: PermissionScope.USER_GROUP,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationUsergroupCreateany,
        PermissionAttributesEnum.GlobalOperationUsergroupUpdateany,
        PermissionAttributesEnum.GlobalOperationUsergroupDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationUsergroupCreate,
        PermissionAttributesEnum.ContextOperationUsergroupUpdate,
        PermissionAttributesEnum.ContextOperationUsergroupDelete,
      ],
      scope: PermissionScope.USER_GROUP,
    });
  };
}
