import type { HydraCollection } from "@verbleif/lib";
import type { AxiosRequestConfig } from "axios";
import { api } from "./api";

export enum BaseUrl {
  LOGIN = "https://login.verbleif.dev",
  API = "https://api.verbleif.dev",
}

export async function getAll<T = any>({
  url,
  initialParams,
  perPage = 100,
  signal,
  _retry = true,
}: {
  url: string
  initialParams?: URLSearchParams
  perPage?: number
  signal?: AbortSignal
  _retry?: boolean
}): Promise<T[]> {
  let items: T[] = [];

  async function fetch(page = 1) {
    if (signal?.aborted) {
      return;
    }
    const params = new URLSearchParams(initialParams);
    params.set("perPage", perPage.toString());
    params.set("page", page.toString());

    const data = await api
      .get<HydraCollection<T>>(url, { params, signal, _retry } as AxiosRequestConfig & { _retry?: boolean })
      .then(({ data }) => data.member);
    items = [...items, ...data];

    if (data.length === perPage) {
      await fetch(page + 1);
    }

    return items;
  }

  await fetch();

  return items;
}
