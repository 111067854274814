import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum, PermissionScope } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class PropertyVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPropertyCreateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPropertyCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPropertyReadany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPropertyRead,
      scope: PermissionScope.PROPERTY,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPropertyDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPropertyDelete,
      scope: PermissionScope.PROPERTY,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPropertyUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPropertyUpdate,
      scope: PermissionScope.PROPERTY,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationPropertyCreateany,
        PermissionAttributesEnum.GlobalOperationPropertyUpdateany,
        PermissionAttributesEnum.GlobalOperationPropertyDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationPropertyCreate,
        PermissionAttributesEnum.ContextOperationPropertyUpdate,
        PermissionAttributesEnum.ContextOperationPropertyDelete,
      ],
      scope: PermissionScope.PROPERTY,
    });
  };
}
