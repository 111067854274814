import { useEventListener } from "@vueuse/core";
import { onBeforeRouteLeave } from "vue-router";
import { useTestConfirmDialog } from "./useTestConfirmDialog";

export function usePreventDirtyClose(isDirty?: () => boolean) {
  const confirm = useTestConfirmDialog();

  onBeforeRouteLeave(async (_, __, next) => {
    if (isDirty && isDirty()) {
      confirm.require({
        title: "You have unsaved changes.",
        message: "Are you sure you want to leave?",
        confirmText: "Leave",
        cancelText: "Stay",
        accept: () => {
          next();
        },
        reject: () => {
          next(false);
        },
      });
    } else {
      next();
    }
  });

  useEventListener(window, "beforeunload", (evt: BeforeUnloadEvent) => {
    if (isDirty && isDirty()) {
      evt.preventDefault();
    }
  });
}
