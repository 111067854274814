export enum AppCategoryEnum {
  PMS = 0,
  Overig = 1,
  GastenApp = 2,
  Toegangscontrole = 3,
}

export enum AppAuthEnum {
  OAuth = 0,
  API = 1,
}

export enum AppStatusEnum {
  DRAFT = 0,
  REJECTED = 1,
  PRIVATE = 2,
  TEST = 3,
  PUBLISHED = 4,
  INTERNAL = 5,
}

export enum AppSubscriptionStatus {
  ACTIVE = 1,
  PENDING = 2,
  INACTIVE = 3,
  INSTALLING = 4,
}

export enum AppInternalIdentifierEnum {
  WEMA_LEISURE = 1,
  BOOKING_EXPERTS = 2,
  GREAT_STAY_APP = 3,
  CAMPING_CARE = 4,
  RECRANET = 5,
  TOMMY_BOOKING_SUPPORT = 6,
}

export enum AppTypeEnum {
  SYSTEM = 0,
  CUSTOM = 1,
}
