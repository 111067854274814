<script>
import { provide } from "vue";
import { useComponentId } from "../useComponentId";

export default {
  name: "VField",
  props: {
    layout: {
      type: String,
      default: "is-row",
      validator: (v) => {
        return ["is-row", "is-column"].includes(v);
      },
    },
    labelCenter: {
      type: Boolean,
      default: true,
    },
    labelLeft: {
      type: [String, Boolean],
      default: false,
    },
    errorMessage: {
      type: [String, Boolean],
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    helpLink: {
      type: [String, Boolean],
      default: false,
    },
    hasMargin: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const id = useComponentId();
    provide("FieldId", id);

    return { id };
  },
};
</script>

<template>
  <div
    :class="[
      layout,
      {
        'label-center': labelCenter,
        'is-danger': errorMessage,
        'has-label-left': labelLeft,
        'has-margin-bottom': hasMargin,
      },
    ]"
    class="field"
  >
    <label v-if="labelLeft" :for="id" class="v-label">
      <span v-if="required" class="required">*</span>
      {{ labelLeft }}
    </label>
    <slot />
    <a
      v-if="helpLink"
      :href="helpLink"
      class="help-icon icon"
      target="_blank"
      title="More information"
    >
      <FontAwesomeIcon class="icon" icon="info-circle" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
.field {
  width: 100%;
  display: flex;
  position: relative;
  min-height: calc(36px + 12px);

  &.has-margin-bottom {
    margin-bottom: 25px;
  }

  &.is-row {
    flex-direction: row;
    align-items: flex-start;
    &.label-center {
      align-items: center;
    }
    .v-label {
      flex: 2 2 194px;
    }
    &.has-label-left {
      padding-left: 15px;
    }
  }

  &.is-column {
    flex-direction: column;
    justify-content: start;

    &.has-label-left {
      min-height: calc(36px + 12px + 12px);
    }

    .v-label {
      margin-bottom: 3px;
    }
  }

  &::v-deep(.validator-message) {
    margin-top: 3px;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
  }

  //@each $name, $color in $vColors {
  //  &.#{$name} {
  //    &::v-deep(.input .icon, .select .icon) {
  //      color: $color;
  //    }
  //    &::v-deep(.validator-message) {
  //      color: $color;
  //    }
  //    &::v-deep(.input input) {
  //      border: 1px solid $color;
  //    }
  //    &::v-deep(.select select) {
  //      border: 1px solid $color;
  //    }
  //    &::v-deep(.input textarea) {
  //      border: 1px solid $color;
  //    }
  //
  //    &.is-row {
  //      .label {
  //        color: $color;
  //      }
  //    }
  //  }
  //}
}
.help-icon {
  position: absolute;
  right: -18px;
  color: var(--text);
  font-size: 13px;
}
.v-label {
  //max-width: 194px;
  // Disabled, public report page has a long label, why should this be maximized?
  font-size: 12px;
  color: var(--text);

  .required {
    color: var(--danger);
    font-weight: bold;
  }
}
</style>
