<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { translationKey, entityField = "name", onConfirm } = defineProps<{
  translationKey: string
  entityField?: string
  onConfirm: (entity: any) => Promise<void>
}>();

const loading = ref(false);

const visible = defineModel<boolean>("visible");
const entity = ref<any | null>(null);

function show(inputEntity: any) {
  entity.value = inputEntity;
  visible.value = true;
}

function close() {
  entity.value = undefined;
  visible.value = false;
}

defineExpose({
  show,
  close,
});

async function handleConfirm() {
  if (loading.value || !entity.value) {
    return;
  }
  try {
    loading.value = true;
    await onConfirm?.(entity.value);
  } finally {
    loading.value = false;
  }
}

const { t } = useI18n();
const removeDialogConfig = computed(() => ({
  id: "remove-dialog",
  title: t(`${translationKey}.remove_title`),
  icon: "trash",
  type: "danger",
  mask: true,
  confirmText: t("base.delete"),
  cancelText: t("base.cancel"),
}));
</script>

<template>
  <VTestConfirmDialog
    :visible="visible"
    v-bind="removeDialogConfig"
    :confirm-loading="loading"
    @cancel="close"
    @confirm="handleConfirm"
  >
    <template #content>
      <div class="text-center flex flex-col items-center">
        {{ t(`${translationKey}.remove_description`) }}
        <b v-if="entity?.[entityField]">"{{ entity?.[entityField] }}"</b>
      </div>
    </template>
  </VTestConfirmDialog>
</template>

<style scoped>

</style>
