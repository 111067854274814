<script setup lang="ts">
import type { ConfirmDialogOptions } from "./ConfirmDialog";
import { useConfirmDialog } from "./ConfirmDialog";

const {
  visible,
  componentInst,
  loading,
  isMatching,
  onConfirmInput,
  componentProps,
  close,
  options,
} = useConfirmDialog();

function onSubmit(options: ConfirmDialogOptions) {
  console.log("onSubmit");
  if (options.onContinue) {
    options.onContinue();
  }
}
</script>

<template>
  <transition name="fade">
    <div
      v-if="visible"
      class="modal-container confirm-dialog-container"
      @click.self.stop="close"
    >
      <component
        v-bind="componentProps"
        :is="componentInst"
        :close="close"
        :options="options"
        :is-matching="isMatching"
        :loading="loading"
        @update:model-value="onConfirmInput"
        @on-submit="onSubmit(options)"
      />
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@use "../Modal/Modal";
.dialog {
  min-width: 525px;
  width: 525px;
  min-height: 335px;
  overflow: visible; // For deadline component
}
.modal .body {
  padding: 0 25px;
}
</style>
