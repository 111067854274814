<script setup lang="ts">
import { defineProps, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { FilterCondition, useFilters } from "../../composables";

interface Props {
  tags: string[]
}

const {
  tags = ["Alles"],
} = defineProps<Props>();

const { t } = useI18n();
const {
  addFilter,
  state,
  removeFilter,
} = useFilters();

const activeIndex = ref<number | null>(null);

onMounted(() => {
  initTags();
});

function initTags() {
  if (tags.length > 0) {
    selectTag(0, true);
  }
}

function selectTag(index: number, active: boolean) {
  if (active) {
    if (activeIndex.value !== index) {
      addFilter();
      const newFilterIndex = state.filters.length - 1;
      state.filters[newFilterIndex].leftHand = "category";
      state.filters[newFilterIndex].condition = FilterCondition.EQUAL;
      state.filters[newFilterIndex].rightHand = `${index}`;
      activeIndex.value = index;
    }
  } else {
    removeFilter(index);
    activeIndex.value = null;
  }
}
</script>

<template>
  <div class="tags-container">
    <VTag
      v-for="(tag, index) in tags"
      :key="index"
      :class="[{ active: activeIndex === index }]"
      :text="t(`settings.appStore.tags.${tag}`)"
      variant="is-third"
      cursor
      icon-less
      @click="selectTag(index, activeIndex !== index)"
    />
  </div>
</template>

<style scoped>
.active {
  background: var(--tag-primary-background) !important;
  color: var(--tag-primary-text) !important;
}
.tags-container {
  display: flex;
  gap: 5px;
}
</style>
