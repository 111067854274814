import type { TaskHydraItem, UserStatusHydraItem } from "@verbleif/lib";
import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum, PermissionScope } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

const createContextAttributes = [
  PermissionAttributesEnum.ContextOperationTaskCreateassigneddirectlytoscope,
  PermissionAttributesEnum.ContextOperationTaskCreateassigneddirectlytoself,
  PermissionAttributesEnum.ContextTaskCreateforassignedusergrouplessuser,
  PermissionAttributesEnum.ContextTaskCreateassignedtoothersinscope,
  PermissionAttributesEnum.ContextTaskCreateunassigned,
];

const deleteContextAttributes = [
  PermissionAttributesEnum.ContextOperationTaskDeleteassigneddirectlytoscope,
  PermissionAttributesEnum.ContextOperationTaskDeleteassigneddirectlytoself,
  PermissionAttributesEnum.ContextTaskDeleteforassignedusergrouplessuser,
  PermissionAttributesEnum.ContextTaskDeleteassignedtoothersinscope,
  PermissionAttributesEnum.ContextTaskDeleteunassigned,
];

const updateContextAttributes = [
  PermissionAttributesEnum.ContextOperationTaskUpdateassigneddirectlytoscope,
  PermissionAttributesEnum.ContextOperationTaskUpdateassigneddirectlytoself,
  PermissionAttributesEnum.ContextTaskUpdateforassignedusergrouplessuser,
  PermissionAttributesEnum.ContextTaskUpdateassignedtoothersinscope,
  PermissionAttributesEnum.ContextTaskUpdateunassigned,
];

export class TaskVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationTaskCreateany,
      contextAttribute: createContextAttributes,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationTaskReadany,
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationTaskReadassigneddirectlytoscope,
        PermissionAttributesEnum.ContextTaskReadofassignedusergrouplessuser,
        PermissionAttributesEnum.ContextTaskReadassignedofothersinscope,
        PermissionAttributesEnum.ContextTaskReadunassigned,
      ],
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationTaskDeleteany,
      contextAttribute: deleteContextAttributes,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationTaskUpdateany,
      contextAttribute: updateContextAttributes,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationTaskCreateany,
        PermissionAttributesEnum.GlobalOperationTaskUpdateany,
        PermissionAttributesEnum.GlobalOperationTaskDeleteany,
      ],
      contextAttribute: [
        ...createContextAttributes,
        ...updateContextAttributes,
        ...deleteContextAttributes,
      ],
    });
  };

  public hasAccessToAttributeViaTaskRelations(item: TaskHydraItem, attribute: PermissionAttributesEnum) {
    const location = item.location;
    const property = item.property;
    const client = location ? this.permissionService.getClientViaLocation(location) : null;
    const users = item.assignees.filter(assignee => assignee.includes("/users/"));
    const userGroups = item.assignees.filter(assignee => assignee.includes("/user_groups/"));

    if (client) {
      const userPermission = this.permissionService.getScopePermissionForUser({
        iri: client,
        attribute,
        scope: PermissionScope.CLIENT,
      });
      if (userPermission !== null) {
        return true;
      }
    }

    if (location) {
      const userLocationPermission = this.permissionService.getScopePermissionForUser({
        iri: location,
        attribute,
        scope: PermissionScope.LOCATION,
      });
      if (userLocationPermission !== null) {
        return true;
      }
    }

    if (property) {
      const userPropertyPermission = this.permissionService.getScopePermissionForUser({
        iri: property,
        attribute,
        scope: PermissionScope.PROPERTY,
      });
      if (userPropertyPermission !== null) {
        return true;
      }
    }

    if (userGroups.length > 0) {
      for (const userGroup of userGroups) {
        const userGroupPermission = this.permissionService.getScopePermissionForUser({
          iri: userGroup,
          attribute,
          scope: PermissionScope.USER_GROUP,
        });
        if (userGroupPermission !== null) {
          return true;
        }
      }
    }

    const usersData = this.permissionService.getUsersData();

    const userStatuses = users
      .map(user => usersData.get(user)?.statuses || null)
      .filter((status): status is UserStatusHydraItem[] => status !== null);

    // Return array of unique strings with all userGroupIris
    const userUserGroupStatuses = Array.from(new Set(
      userStatuses
        .flatMap(status => status.filter(s => !!s.userGroup).map(s => s.userGroup))
        .filter((userGroup): userGroup is string => userGroup !== undefined),
    ));

    if (userUserGroupStatuses.length > 0) {
      for (const userGroup of userUserGroupStatuses) {
        const userGroupPermission = this.permissionService.getScopePermissionForUser({
          iri: userGroup,
          attribute,
          scope: PermissionScope.USER_GROUP,
        });
        if (userGroupPermission !== null) {
          return true;
        }
      }
    }

    return false;
  }
}
