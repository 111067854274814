<script setup lang="ts">
import type { CheckboxModelValue, CheckboxStateValue } from "./types";
import { computed, ref } from "vue";
import ACTooltip from "../ACTooltip/ACTooltip.vue";
import { useComponentId } from "../useComponentId";

defineOptions({
  name: "VCheckbox",
  inheritAttrs: false,
});

const {
  value = true,
  falseValue = false,
  labelLeft = null,
  labelRight = null,
  labelVariant = "is-light",
  hovered = false,
  disabled = false,
  hasPerms = true,
  indeterminate = false,
} = defineProps<Props>();

defineSlots<{
  "label-right": { id: string }
}>();

interface Props {
  value?: any
  name: string
  falseValue?: any
  labelLeft?: string | null
  labelRight?: string | null
  labelVariant?: "is-light" | "is-gray"
  hasPerms?: boolean
  disabled?: boolean
  hovered?: boolean
  indeterminate?: boolean
}

const isDisabled = computed(() => {
  return disabled || !hasPerms;
});

const modelValue = defineModel<CheckboxModelValue>({
  default: () => ({ checked: false }),
  type: [Boolean, Array, Object],
});

const internalValue = ref<CheckboxStateValue>(falseValue);
if (!Array.isArray(modelValue.value)) {
  internalValue.value = modelValue.value;
}

const id: any = useComponentId();
</script>

<template>
  <ACTooltip
    type="edit_field"
    :allow-change="hasPerms"
  >
    <div
      class="checkbox-container"
      :class="[
        {
          'is-hovered': hovered,
          'is-disabled': isDisabled,
          'is-indeterminate': indeterminate,
        },
        $attrs.class,
      ]"
      role="checkbox"
      :aria-checked="modelValue === value"
      tabindex="0"
    >
      <label
        v-if="labelLeft"
        :for="id"
        class="v-label is-left"
        :class="[labelVariant]"
      >
        {{ labelLeft }}
      </label>
      <div class="checkbox-wrapper">
        <input
          :id="id"
          v-model="modelValue"
          type="checkbox"
          class="checkbox-input"
          :value="value"
          :true-value="value"
          :false-value="falseValue"
          :disabled="isDisabled"
          :name="name"
          :indeterminate="indeterminate"
          v-bind="$attrs"
        >
        <label :for="id" class="checkbox-custom" />
      </div>
      <label
        v-if="labelRight || $slots['label-right']"
        :for="id"
        class="v-label is-right"
        :class="[labelVariant]"
      >
        <template v-if="labelRight">
          {{ labelRight }}
        </template>
        <template v-else>
          <slot :id="id" name="label-right" />
        </template>
      </label>
    </div>
  </ACTooltip>
</template>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover,
  &.is-hovered {
    //Show check if checkbox is hovered
    .checkbox-wrapper .checkbox-input + .checkbox-custom:after {
      //opacity: 0.25;
    }
  }

  &[disabled],
  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;

    .checkbox-wrapper .checkbox-custom,
    .checkbox-input,
    .checkbox-label {
      cursor: not-allowed;
    }
  }

  .v-label {
    line-height: 16px;
    font-size: 14px;
    color: var(--text);
    cursor: pointer;
    user-select: none;

    &.is-right {
      margin-left: 10px;
    }

    &.is-left {
      margin-right: 10px;
    }
  }
}

.checkbox-wrapper {
  position: relative;

  .checkbox-custom {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--primary);
    border-radius: 2px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    color: gray;
    background-color: var(--white);
    text-align: center;
    cursor: pointer;
    user-select: none;
    transition: background-color 100ms ease-in-out, border-color 100ms ease-in-out;
  }

  .checkbox-input[type="checkbox"] {
    display: none;
  }

  .checkbox-input + .checkbox-custom:after {
    content: "";
    opacity: 0;
    background: var(--primary);
    width: 10px;
    height: 7px;
    border: 2px solid var(--white);
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    margin-top: -3px;
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  }

  .checkbox-input:indeterminate + .checkbox-custom {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .checkbox-input:indeterminate + .checkbox-custom:after {
    transform: rotate(0);
    background-color: var(--white);
    width: 60%;
    height: 2px;
    opacity: 1;
    margin-top: 0;
    border: none;
  }

  .checkbox-input:checked + .checkbox-custom {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .checkbox-input:checked + .checkbox-custom:after {
    opacity: 1;
  }
}
</style>
