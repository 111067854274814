<script setup lang="ts">
import type { SliderContext } from "./types";
import { computed, inject } from "vue";

const slider = inject<SliderContext>("slider");

const percentage = computed(() => {
  if (!slider) {
    return 0;
  }
  return ((slider.value.value - slider.min) / (slider.max - slider.min)) * 100;
});
</script>

<template>
  <div
    class="absolute rounded-full bg-primary-600"
    :class="[
      slider?.orientation === 'horizontal' ? 'h-full' : 'w-full bottom-0',
      slider?.disabled ? 'bg-gray-400' : 'bg-primary-600',
    ]"
    :style="slider?.orientation === 'horizontal'
      ? { width: `${percentage}%` }
      : { height: `${percentage}%` }"
  />
</template>
