import type { HydraItem, MediaObject, ProductHydraItem, PropertyGroupHydraItem, PropertyHydraItem, ReportTopicHydraItem, SortHydraItem, UserGroupHydraItem, UserHydraItem } from "@verbleif/lib";
import { SortFieldEnum } from "@verbleif/lib";
import { api, useAuthStore, UserRole } from "@verbleif/shared";
import { StorageSerializers, useStorage } from "@vueuse/core";
import { useRightsStore } from "@web/core/store/RightStore";
import { computed, ref } from "vue";

export interface TaskSidebar {
  shortcuts: boolean
  departments: boolean
}

function createSystemStore() {
  const systemUsers = ref<UserHydraItem[]>([]);
  const sorts = ref<SortHydraItem[]>([]);
  const propertyGroups = ref<PropertyGroupHydraItem[]>([]);
  const properties = ref<PropertyHydraItem[]>([]);
  const avatars = ref<HydraItem<MediaObject>[]>([]);
  const products = ref<ProductHydraItem[]>([]);
  const reportTopics = ref<ReportTopicHydraItem[]>([]);
  const searchValue = ref<string>("");
  const searchTypes = useStorage<{
    UserGroup: boolean
    User: boolean
  }>("searchTypes", { UserGroup: true, User: true }, undefined, {
    serializer: StorageSerializers.object,
  });
  const taskSidebar = useStorage<TaskSidebar>("taskSidebar", {
    shortcuts: false,
    departments: false,
  }, undefined, {
    serializer: StorageSerializers.object,
  });
  const reportSidebar = useStorage<Record<string, any>>("reportSidebar", {}, undefined, {
    serializer: StorageSerializers.object,
  });
  const { hasRole, hasRoleStrict } = useRightsStore();
  const taskOverviewSettings = useStorage<{
    showStatusDone: boolean
    showStatusCompleted: boolean
    hideSearchAndSort: boolean
    showNoDeadline: boolean
  }>("taskOverviewSettings", {
    showStatusDone: true,
    showStatusCompleted: true,
    hideSearchAndSort: true,
    showNoDeadline: true,
  }, undefined, {
    serializer: StorageSerializers.object,
  });

  const reportOverviewSettings = useStorage<{
    showtransfered: boolean
    hideSearchAndSort: boolean
    showStatusClosed: boolean
  }>("reportOverviewSettings", {
    showtransfered: true,
    hideSearchAndSort: true,
    showStatusClosed: true,
  }, undefined, {
    serializer: StorageSerializers.object,
  });

  // Computed Properties
  const avatarsUrlIndexedByIri = computed<Record<string, string>>(() => {
    return avatars.value.reduce((acc, obj) => {
      const { "@id": key, contentUrl } = obj;
      acc[key] = contentUrl;
      return acc;
    }, {} as Record<string, string>);
  });
  const avatarsMediaObjectIndexedByIri = computed<Record<string, HydraItem<MediaObject>>>(() => {
    return avatars.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<MediaObject>>);
  });

  const systemUsersIndexedByIri = computed<Record<string, UserHydraItem>>(() => {
    return systemUsers.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, UserHydraItem>);
  });

  const sortsIndexedByIri = computed<Record<string, SortHydraItem>>(() => {
    return sorts.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, SortHydraItem>);
  });

  const reportTopicsIndexedByIri = computed<Record<string, ReportTopicHydraItem>>(() => {
    return reportTopics.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, ReportTopicHydraItem>);
  });

  function updateSort(sort: SortHydraItem) {
    sorts.value = sorts.value.map(s => s["@id"] === sort["@id"] ? sort : s);
  }

  function createSort(sort: SortHydraItem) {
    sorts.value.push(sort);
  }

  function deleteSort(sort: SortHydraItem) {
    sorts.value = sorts.value.filter(s => s["@id"] !== sort["@id"]);
  }

  function updatePropertyGroup(propertyGroup: PropertyGroupHydraItem) {
    propertyGroups.value = propertyGroups.value.map(pg => pg["@id"] === propertyGroup["@id"] ? propertyGroup : pg);
  }

  function createPropertyGroup(propertyGroup: PropertyGroupHydraItem) {
    propertyGroups.value.push(propertyGroup);
  }

  function deletePropertyGroup(propertyGroup: PropertyGroupHydraItem) {
    propertyGroups.value = propertyGroups.value.filter(pg => pg["@id"] !== propertyGroup["@id"]);
  }

  const propertyGroupsIndexedByIri = computed<Record<string, PropertyGroupHydraItem>>(() => {
    return propertyGroups.value.reduce((acc: Record<string, PropertyGroupHydraItem>, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {});
  });

  function updateProperty(property: PropertyHydraItem) {
    properties.value = properties.value.map(p => p["@id"] === property["@id"] ? property : p);
  }

  function createProperty(property: PropertyHydraItem) {
    properties.value.push(property);
  }

  function deleteProperty(property: PropertyHydraItem) {
    properties.value = properties.value.filter(p => p["@id"] !== property["@id"]);
  }

  const propertiesIndexedByIri = computed<Record<string, PropertyHydraItem>>(() => {
    return properties.value.reduce((acc: Record<string, PropertyHydraItem>, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {});
  });

  const productsIndexedByIri = computed<Record<string, ProductHydraItem>>(() => {
    return products.value.reduce((acc: Record<string, ProductHydraItem>, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {});
  });

  const usersBasedOnRole = computed(() => {
    const authStore = useAuthStore();

    if (!authStore.user.value) {
      return [];
    }

    const currentUser = authStore.user.value;

    if (
      hasRole(UserRole.ROLE_PARK_MANAGER)
      || hasRoleStrict(UserRole.ROLE_FRONT_DESK)
      || hasRoleStrict(UserRole.ROLE_DEPARTMENT_MANAGER)
    ) {
      return [];
    }

    const currentUserGroups = api.get(`/users/${currentUser.id}/user_groups`).then((response) => {
      return response.data.member;
    });

    const currentUserManagerGroups = api.get(`/users/${currentUser.id}/manager_user_groups`).then((response) => {
      return response.data.member;
    });

    const requiredGroups: string[] = [];
    if (currentUserGroups) {
      currentUserGroups.then((groups) => {
        groups.forEach((group: { "@id": string }) => {
          requiredGroups.push(group["@id"]);
        });
      });
    }

    if (hasRoleStrict(UserRole.ROLE_DEPARTMENT_MANAGER) && currentUserManagerGroups) {
      currentUserManagerGroups.then((groups) => {
        groups.forEach((group: { "@id": string }) => {
          requiredGroups.push(group["@id"]);
        });
      });
    }

    return [];
  });
  const assignableUsersTeamsDepartments = computed(() => {
    const defaultAssignableAssignees = [
      ...usersBasedOnRole.value,
    ];

    return defaultAssignableAssignees.sort((a: UserHydraItem | UserGroupHydraItem, b: UserHydraItem | UserGroupHydraItem) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  });
  const filteredAssignableUserGroupsUsers = computed(() => {
    return assignableUsersTeamsDepartments.value.filter((item) => {
      const name: "UserGroup" | "User" = item["@type"] as "UserGroup" | "User";
      const isTypeEnabled: boolean = searchTypes.value[name];

      if (!isTypeEnabled) {
        return false;
      }

      if (searchValue.value === "") {
        return true;
      }

      // if ("name" in item) {
      //   return item.name.toLowerCase().includes(searchValue.value.toLowerCase());
      // }

      return true;
    });
  });

  // Methods
  function reset() {
    sorts.value = [];
    propertyGroups.value = [];
    properties.value = [];
    avatars.value = [];
    searchValue.value = "";
    taskOverviewSettings.value = {
      showStatusDone: true,
      showNoDeadline: true,
      showStatusCompleted: true,
      hideSearchAndSort: true,
    };
    reportOverviewSettings.value = {
      showtransfered: true,
      hideSearchAndSort: true,
      showStatusClosed: true,
    };
  }

  function setSystemUsers(newUsers: UserHydraItem[]) {
    systemUsers.value = newUsers;
  }
  function setPropertyGroups(newPropertyGroups: PropertyGroupHydraItem[]) {
    propertyGroups.value = newPropertyGroups;
  }
  function setProperties(newProperties: PropertyHydraItem[]) {
    properties.value = newProperties;
  }

  function setSorts(newSorts: SortHydraItem[]) {
    sorts.value = newSorts;
  }

  function setSearchValue(value: string) {
    searchValue.value = value;
  }
  function toggleSearchType(type: "UserGroup" | "User") {
    searchTypes.value[type] = !searchTypes.value[type];
  }
  function setAvatars(newAvatars: HydraItem<MediaObject>[]) {
    avatars.value = newAvatars;
  }

  function setProducts(newProducts: ProductHydraItem[]) {
    products.value = newProducts;
  }

  function setReportTopics(newReportTopics: ReportTopicHydraItem[]) {
    reportTopics.value = newReportTopics;
  }

  const hasOneSort = computed(() => {
    return sorts.value.length === 1;
  });

  const hasInsideOnASort = computed<boolean>(() => {
    return sorts.value.some((sort) => {
      return sort.visibleFields?.includes(SortFieldEnum.INSIDE);
    });
  });

  const hasQuantityOnASort = computed<boolean>(() => {
    return sorts.value.some((sort) => {
      return sort.visibleFields?.includes(SortFieldEnum.QUANTITY);
    });
  });

  const hasPriorityOnASort = computed<boolean>(() => {
    return sorts.value.some((sort) => {
      return sort.visibleFields?.includes(SortFieldEnum.PRIORITY);
    });
  });

  return {
    // State
    sorts,
    properties,
    propertyGroups,
    avatars,
    products,
    searchValue,
    searchTypes,
    updateSort,
    taskSidebar,
    reportSidebar,
    hasOneSort,
    taskOverviewSettings,
    reportTopics,
    // Computed Properties
    createSort,
    deleteSort,
    avatarsUrlIndexedByIri,
    avatarsMediaObjectIndexedByIri,
    systemUsersIndexedByIri,
    sortsIndexedByIri,
    propertyGroupsIndexedByIri,
    propertiesIndexedByIri,
    productsIndexedByIri,
    usersBasedOnRole,
    assignableUsersTeamsDepartments,
    filteredAssignableUserGroupsUsers,
    hasInsideOnASort,
    hasQuantityOnASort,
    hasPriorityOnASort,
    reportTopicsIndexedByIri,
    // Methods
    reset,
    updatePropertyGroup,
    createPropertyGroup,
    deletePropertyGroup,
    updateProperty,
    createProperty,
    deleteProperty,
    setSystemUsers,
    setPropertyGroups,
    setProperties,
    setSorts,
    setSearchValue,
    toggleSearchType,
    setAvatars,
    setReportTopics,
    setProducts,
    reportOverviewSettings,
  };
}

type SystemStoreInterface = ReturnType<typeof createSystemStore>;
let backwardsCompatibleInstance: null | SystemStoreInterface = null;
export function useSystemStore(): SystemStoreInterface {
  if (backwardsCompatibleInstance === null) {
    backwardsCompatibleInstance = createSystemStore();
  }

  return backwardsCompatibleInstance;
}
export default useSystemStore;
