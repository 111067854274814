import { computed, ref, watchEffect } from "vue";

export enum ThemeSetting {
  DARK = "dark",
  LIGHT = "light",
  AUTO = "auto",
}

export enum Theme {
  DARK = "dark",
  LIGHT = "light",
}

const LOCAL_STORAGE_KEY = "themeSetting";
const DEFAULT_THEME: ThemeSetting = ThemeSetting.AUTO;

function createTheme() {
  const themeSetting = ref<ThemeSetting>(loadTheme());
  const systemPreferredTheme = ref<Theme>(getSystemPreferredTheme());

  const theme = computed(() => {
    if (themeSetting.value === ThemeSetting.AUTO) {
      return systemPreferredTheme.value;
    }

    return themeSetting.value;
  });

  watchEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, themeSetting.value);
    document.body.setAttribute("data-theme", theme.value as string);

    if (theme.value === Theme.DARK) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    if (themeSetting.value === ThemeSetting.AUTO) {
      enableWatchSystemTheme();

      return;
    }

    disableWatchSystemTheme();
  });

  function loadTheme() {
    let storedTheme = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!storedTheme || (storedTheme && !["auto", "dark", "light"].includes(storedTheme))) {
      localStorage.setItem(LOCAL_STORAGE_KEY, DEFAULT_THEME);
      storedTheme = DEFAULT_THEME;
    }

    return storedTheme as ThemeSetting;
  }

  const isAuto = computed(() => {
    return themeSetting.value === ThemeSetting.AUTO;
  });

  function getSystemPreferredTheme() {
    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? Theme.DARK
      : Theme.LIGHT;
  }

  function handleMatchMediaChange(e: MediaQueryListEvent) {
    systemPreferredTheme.value = e.matches ? Theme.DARK : Theme.LIGHT;
  }

  function enableWatchSystemTheme() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleMatchMediaChange);
  }

  function disableWatchSystemTheme() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", handleMatchMediaChange);
  }

  return {
    theme,
    isAuto,
    themeSetting,
  };
}

export const theme = createTheme();

export function useTheme() {
  return theme;
}
