import type { TaskHydraItem } from "@verbleif/lib";
import type { PermissionService } from "../PermissionService";
import type { TaskVoter } from "./TaskVoter";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class TaskProductVoter extends ContextVoter {
  public taskVoter: TaskVoter;

  constructor(permissionService: PermissionService, taskVoter: TaskVoter) {
    super(permissionService);
    this.taskVoter = taskVoter;
  }

  public canCreate = (task: TaskHydraItem | null): boolean => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationTaskproductCreateany,
    })) {
      return true;
    }

    return task ? this.taskVoter.hasAccessToAttributeViaTaskRelations(task, PermissionAttributesEnum.ContextOperationTaskproductCreate) : false;
  };

  public canRead = (): boolean => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationTaskproductReadany,
    })) {
      return true;
    }

    return false;
  };

  public canDelete = (task: TaskHydraItem | null): boolean => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationTaskproductDeleteany,
    })) {
      return true;
    }

    return task ? this.taskVoter.hasAccessToAttributeViaTaskRelations(task, PermissionAttributesEnum.ContextOperationTaskproductDelete) : false;
  };

  public canUpdate = (task: TaskHydraItem | null): boolean => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationTaskproductUpdateany,
    })) {
      return true;
    }

    return task ? this.taskVoter.hasAccessToAttributeViaTaskRelations(task, PermissionAttributesEnum.ContextOperationTaskproductUpdate) : false;
  };
}
