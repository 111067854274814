export enum LoopbackFilterOperatorEnum {
  EQ = "eq",
  NEQ = "neq",
  GT = "gt",
  GTE = "gte",
  LT = "lt",
  LTE = "lte",
  ILIKE = "ilike",
  NILIKE = "nilike",
  LIKE = "like",
  NLIKE = "nlike",
  INQ = "inq",
  NIN = "nin",
  BETWEEN = "between",
  NULL = "null",
  NNULL = "nnull",
  EMPTY = "empty",
  NEMPTY = "nempty",
  MEMBER_OF = "mbof",
  NOT_MEMBER_OF = "nmbof",
  INSTANCE_OF = "isof",
  NOT_INSTANCE_OF = "nisof",
}
