<script setup lang="ts">
import type { Package } from "../../composables/Api/Type/PriceSelector";
import { defineProps, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { usePackageStore, useProvidePackageStore } from "./usePackageStore";
import PackageSelectorItem from "./VPackageSelectorItem.vue";

const props = withDefaults(
  defineProps<{
    packages: (Package | null)[] | undefined
    yearly?: boolean
    loading?: boolean
    invalid?: boolean
  }>(),
  {
    packages: () => [null, null, null, null],
    yearly: false,
    loading: false,
    invalid: false,
  },
);

const { t } = useI18n();

// -_-
const packagesData = ref(props.packages);
watch(
  () => props.packages,
  (newPackages: (Package | null)[]) => {
    console.log(newPackages);
    if (!props.loading) {
      packagesData.value = newPackages;
    }
  },
);

// all child components know if they are selected and what price they need to show
useProvidePackageStore(props.yearly);
const { isYearly } = usePackageStore();
const selected = ref<number | null>(null);

// this does not really do anything yet
function onNotPurchasable() {
  console.log("NotPurchasable");
  console.log(selected.value);
}
</script>

<template>
  <div class="flex items-center text-xs !leading-[14px] text-gray-800 dark:text-gray-100 mb-[10px]">
    <div class="">
      {{ t('base.monthly') }}
    </div>
    <VSwitch
      v-model="isYearly"
      class="mx-[10px]"
      :disabled="loading"
    />
    <div class="">
      {{ t('base.yearly') }}
    </div>
    <div class="text-gray-400 dark:text-gray-400 mx-1">
      {{ t('base.discount', { percentage: 10 }) }}
    </div>
  </div>
  <div>
    <div v-if="packages" class="flex items-stretch justify-start grow flex-wrap gap-5">
      <div v-for="packageData in packagesData" :key="packageData?.id">
        <PackageSelectorItem
          v-model="selected"
          :loading="loading"
          :package-data="packageData"
          :invalid="invalid"
          @not-purchasable="onNotPurchasable()"
        />
      </div>
    </div>
  </div>
</template>
