<script setup lang="ts">
import SliderRange from "./SliderRange.vue";
import SliderRoot from "./SliderRoot.vue";
import SliderThumb from "./SliderThumb.vue";
import SliderTrack from "./SliderTrack.vue";

interface Props {
  modelValue?: number
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  orientation?: "horizontal" | "vertical"
  showTooltip?: boolean
  tooltipFormat?: (value: number) => string
  marks?: Array<{ value: number, label?: string }>
  trackClassName?: string
  rangeClassName?: string
  thumbClassName?: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: 0,
  min: 0,
  max: 100,
  step: 1,
  disabled: false,
  orientation: "horizontal",
  showTooltip: true,
  tooltipFormat: (value: number) => `${value}`,
  marks: () => [],
  trackClassName: "bg-gray-200 dark:bg-gray-700",
  rangeClassName: "bg-primary-600",
  thumbClassName: "bg-white border-2 border-primary-600",
});

const emit = defineEmits<{
  (e: "update:modelValue", value: number): void
  (e: "change", value: number): void
}>();
</script>

<template>
  <SliderRoot
    v-bind="props"
    class="relative w-full h-5 flex items-center"
    @update:model-value="(val) => emit('update:modelValue', val)"
    @change="(val) => emit('change', val)"
  >
    <SliderTrack :class="trackClassName">
      <SliderRange :class="rangeClassName" />
    </SliderTrack>
    <SliderThumb :class="thumbClassName" />
  </SliderRoot>
</template>
