import { httpClientIntegration } from "@sentry/browser";
import * as Sentry from "@sentry/vue";
import { useConfigStore } from "@verbleif/shared";
import app from "@web/core/app";
import router from "@web/core/plugin/router";

if (import.meta.env.PROD) {
  const configStore = useConfigStore();

  if (!configStore.config.value) {
    throw new Error("No config is set.");
  }

  Sentry.init({
    app,
    dsn: "https://b63d8cd9408a4feaa5556aa2f3bb4427@sentry.vitrion.dev/10",
    environment: configStore.config.value.appEnv,
    integrations: [
      httpClientIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.browserTracingIntegration({
        router,
      }),
    ],
    sendDefaultPii: true,
    trackComponents: true,
    tracePropagationTargets: [
      "app.verbleif.dev",
      "app.verbleif.com",
      "app.staging.verbleif.com",
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.2,
  });
}
