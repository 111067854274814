import { useSessionStorage, useStorage } from "@vueuse/core";
import { computed, ref } from "vue";

export interface AppConfig {
  apiUrl: string
  mercureUrl: string
  loginUrl: string
  loginRedirect: string
  loginClientId: string
  cdnUrl: string
  appEnv: string
  presetName?: string | null
  webUrl?: string | null
  skipOauth?: boolean
}

function createConfigStore() {
  const initialConfig = ref<AppConfig | null>(null);
  const debugMode = useStorage<boolean>("debugMode", false);

  // A serializer is required since the built-in serializer determines the type on the initial value.
  const overrideConfig = useSessionStorage<AppConfig | null>(
    "overrideConfig",
    null,
    {
      serializer: {
        read: (v: any) => (v ? JSON.parse(v) : null),
        write: (v: any) => JSON.stringify(v),
      },
    },
  );

  function setInitialConfig(newConfig: AppConfig) {
    initialConfig.value = newConfig;
  }

  function setOverrideConfig(newConfig: AppConfig) {
    overrideConfig.value = newConfig;
  }

  function reset() {
    overrideConfig.value = null;
  }

  const config = computed<AppConfig | null>(() => {
    if (overrideConfig.value) {
      return overrideConfig.value;
    }

    return initialConfig.value;
  });

  return {
    config,
    overrideConfig,
    setOverrideConfig,
    debugMode,
    setInitialConfig,
    reset,
  };
}

type ConfigStoreInterface = ReturnType<typeof createConfigStore>;
let backwardsCompatibleInstance: null | ConfigStoreInterface = null;
export function useConfigStore(): ConfigStoreInterface {
  if (backwardsCompatibleInstance === null) {
    backwardsCompatibleInstance = createConfigStore();
  }

  return backwardsCompatibleInstance;
}
export default useConfigStore;
