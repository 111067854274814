export interface StepperProps {
  key: string
  name: string
  icon?: string | ["fas" | "far" | "fad" | "fal", string] | undefined
  image?: string
}

export interface StepperItem {
  key?: string
  name?: string
  icon?: string | ["fas" | "far" | "fad" | "fal", string] | undefined
  type: StepperType
  style: StepperStyle
  inProgress?: boolean
}

export enum StepperStyle {
  SOLID = "solid",
  DASHED = "dashed",
}

export enum StepperType {
  STEP = "step",
  DIVIDER = "divider",
}
