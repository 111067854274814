import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class PermissionTemplateGroupVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPermissiontemplategroupCreateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPermissiontemplategroupCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPermissiontemplategroupReadany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPermissiontemplategroupRead,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPermissiontemplategroupDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPermissiontemplategroupDelete,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPermissiontemplategroupUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPermissiontemplategroupUpdate,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationPermissiontemplategroupCreateany,
        PermissionAttributesEnum.GlobalOperationPermissiontemplategroupUpdateany,
        PermissionAttributesEnum.GlobalOperationPermissiontemplategroupDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationPermissiontemplategroupCreate,
        PermissionAttributesEnum.ContextOperationPermissiontemplategroupUpdate,
        PermissionAttributesEnum.ContextOperationPermissiontemplategroupDelete,
      ],
    });
  };
}
