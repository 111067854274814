import { useSessionStorage } from "@vueuse/core";
import { computed } from "vue";

function createImpersonationStore() {
  const impersonationUserIdentifier = useSessionStorage<string | null>("impersonationUserIdentifier", null);
  const impersonationClientIdentifier = useSessionStorage<string | null>("impersonationClientIdentifier", null);

  const isImpersonating = computed<boolean>(() => {
    return impersonationUserIdentifier.value !== null
      && impersonationClientIdentifier.value !== null;
  });

  const getRequestHeaders = computed<Record<string, string>>(() => {
    const headers = <Record<string, string>>{};

    if (
      impersonationUserIdentifier.value !== null
      && impersonationClientIdentifier.value !== null
    ) {
      headers["X-Switch-Type"] = "verbleif_web";
      headers["X-Switch-Username"] = impersonationUserIdentifier.value;
      headers["X-Switch-Client-Id"] = impersonationClientIdentifier.value;
    }

    return headers;
  });

  function setImpersonationUserIdentifier(id: string): void {
    impersonationUserIdentifier.value = id;
  }

  function setImpersonationClientIdentifier(id: string): void {
    impersonationClientIdentifier.value = id;
  }

  function reset() {
    impersonationUserIdentifier.value = null;
    impersonationClientIdentifier.value = null;
  }

  return {
    isImpersonating,
    impersonationUserIdentifier,
    impersonationClientIdentifier,
    getRequestHeaders,
    setImpersonationUserIdentifier,
    setImpersonationClientIdentifier,
    reset,
  };
}

type ImpersonationStoreInterface = ReturnType<typeof createImpersonationStore>;
let backwardsCompatibleInstance: null | ImpersonationStoreInterface = null;
export function useImpersonationStore(): ImpersonationStoreInterface {
  if (backwardsCompatibleInstance === null) {
    backwardsCompatibleInstance = createImpersonationStore();
  }

  return backwardsCompatibleInstance;
}
export default useImpersonationStore;
