export function iriToId(iri: string): number {
  const matches = iri.match(/(\d+)(?!.*\d)/);
  if (!matches?.[0]) {
    throw new Error("Invalid IRI");
  }

  return Number(matches[0]);
}
export function idToIri(entity: string, id: number | string): string {
  return `/api/${entity}/${id}`;
}
