<script setup lang="ts">
import type { SliderContext } from "./types";
import { inject } from "vue";

const slider = inject<SliderContext>("slider");

function calculateValue(event: MouseEvent) {
  if (!slider || !(event.currentTarget instanceof HTMLElement)) {
    return;
  }

  const rect = event.currentTarget.getBoundingClientRect();
  const isVertical = slider.orientation === "vertical";

  let percentage;
  if (isVertical) {
    const offsetY = event.clientY - rect.top;
    percentage = 100 - (offsetY / rect.height) * 100;
  } else {
    const offsetX = event.clientX - rect.left;
    percentage = (offsetX / rect.width) * 100;
  }

  // Clamp percentage
  percentage = Math.max(0, Math.min(100, percentage));

  // Convert percentage to value
  const range = slider.max - slider.min;
  const rawValue = (percentage * range) / 100 + slider.min;

  // Round to nearest step
  const newValue = Math.round(rawValue / slider.step) * slider.step;

  // Update the value
  slider.updateValue(newValue);
}

function onTrackClick(event: MouseEvent) {
  if (!slider?.disabled) {
    calculateValue(event);
  }
}
</script>

<template>
  <div
    class="relative w-full h-1 bg-gray-200 rounded-full dark:bg-gray-700"
    :class="[
      !slider?.disabled && 'cursor-pointer',
      slider?.disabled && 'opacity-50 cursor-not-allowed',
      slider?.orientation === 'vertical' && 'h-full w-1',
    ]"
    @mousedown="onTrackClick"
  >
    <slot />
  </div>
</template>
