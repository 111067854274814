import { ref } from "vue";

export function useDebounce(timeoutCount = 300) {
  let timeoutRef: null | number = null;
  const debouncedValue = ref("");
  const value = ref("");

  function clearTimeoutRef() {
    if (!timeoutRef) {
      return;
    }
    clearTimeout(timeoutRef);
  }

  function setValue(v: string) {
    value.value = v;
  }
  function setDebouncedValue(v: string) {
    debouncedValue.value = v;
  }

  function debounce(input: string) {
    if (timeoutRef !== null) {
      clearTimeout(timeoutRef);
    }

    value.value = input;
    timeoutRef = window.setTimeout(() => {
      debouncedValue.value = input;
    }, timeoutCount);
  }

  return {
    debouncedValue,
    setDebouncedValue,
    setValue,
    debounce,
    clearTimeoutRef,
  };
}
