import { createInjectionState } from "@vueuse/core";
import { ref } from "vue";

const [useProvidePackageStore, usePackageStoreRaw] = createInjectionState(
  (initialValue: boolean) => {
    const isYearly = ref(initialValue);

    return { isYearly };
  },
);

export { useProvidePackageStore };

export function usePackageStore() {
  const PackageStore = usePackageStoreRaw();
  if (PackageStore == null) {
    throw new Error("Please call `useProvidePackageStore` on the appropriate parent component");
  }
  return PackageStore;
}
