import type { HydraCollection, HydraItem, SkeletonItem, TaskHydraItem, TasksExtraData } from "@verbleif/lib";
import { api } from "@verbleif/shared";
import { createGlobalState } from "@vueuse/core";

export const usePropertyExtraData = createGlobalState(() => {
  const taskExtraData = ref<Record<string, TasksExtraData | boolean>>({});

  async function setPropertiesExtraData(items: TasksExtraData[]) {
    const newData = items.reduce((acc: Record<string, TasksExtraData>, item: TasksExtraData) => {
      acc[item.task] = item;
      return acc;
    }, {} as Record<string, TasksExtraData>);
    Object.assign(taskExtraData.value, newData);
  }

  async function getExtraDataForTasks(tasks: (HydraItem<TaskHydraItem> | SkeletonItem)[]) {
    const searchParams = new URLSearchParams();
    const tasksToFetch = tasks.filter(item => "@id" in item);
    for (const task of tasksToFetch) {
      if (taskExtraData.value[task["@id"]]) {
        console.log("[useTasksExtraData] skipping task", task["@id"]);
        continue;
      }
      searchParams.append("task", task["@id"]);
      taskExtraData.value[task["@id"]] = true;
    }

    const hasTasks = searchParams.size > 0;
    if (!hasTasks) {
      return;
    }

    // fake sleep 1 sec
    await new Promise(resolve => setTimeout(resolve, 1000));
    try {
      const response = await api.get<HydraCollection<TasksExtraData>>("/api/tasks/extra-data", {
        params: searchParams,
      });
      const data = response.data.member.reduce((acc: Record<string, TasksExtraData>, item: TasksExtraData) => {
        acc[item.task] = item;
        return acc;
      }, {} as Record<string, TasksExtraData>);
      Object.assign(taskExtraData.value, data);
    } catch (error) {
      console.error("[useTasksExtraData] error fetching extra data", error);
      for (const task of tasksToFetch) {
        taskExtraData.value[task["@id"]] = false;
      }
      throw error;
    }
  }

  function reset() {
    taskExtraData.value = {};
  }

  return {
    taskExtraData,
    getExtraDataForTasks,
    setPropertiesExtraData,
    reset,
  };
});
