import type { PermissionService } from "../PermissionService";
import { PermissionAttributesEnum } from "@verbleif/lib";

export class AdminVoter {
  public permissionService: PermissionService;

  constructor(permissionService: PermissionService) {
    this.permissionService = permissionService;
  }

  public isAdmin = () => {
    return !!this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperation,
    });
  };
}
