import { useAuthStore, useLocationStore } from "@verbleif/shared";
import AuthRoutes from "@web/features/Auth/AuthRoutes";
import PublicRoutes from "@web/features/Public/PublicRoutes";
import ReportRoutes from "@web/features/Reports/ReportsRoutes";
import SettingsRoutes from "@web/features/Settings/SettingsRoutes";
import TaskRoutes from "@web/features/Tasks/TasksRoutes";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  ...SettingsRoutes,
  ...AuthRoutes,
  ...TaskRoutes,
  ...ReportRoutes,
  ...PublicRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
export default router;

const { returnUrl, hasToken, isRemembered } = useAuthStore();
const locationStore = useLocationStore();

router.beforeEach((to, _, next) => {
  const name = <string>to.name;
  console.log("[router.beforeEach] name", name);
  const isPublicRoute = name === "impersonate"
    || name === "public_report"
    || name.startsWith("oauth-callback");

  if (isPublicRoute || to.meta.skipAuthChecks) {
    return next();
  }

  const isUnauthenticated = !hasToken.value && !isRemembered.value;
  if (isUnauthenticated && !to.meta.public) {
    returnUrl.value = to.fullPath;
    console.log("[router.beforeEach] returning to login", to.fullPath);
    return next({ name: "login" });
  }

  const isAuthenticated = hasToken.value || isRemembered.value;

  if (isAuthenticated) {
    // if (to.name === "spaces") {
    //   return next(fallback);
    // }

    if (to.name !== "spaces") {
      if (!locationStore.selectedLocation.value) {
        return next({ name: "spaces" });
      }
    }
  }

  return next();
});
