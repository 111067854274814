import type { PersonalWrite, UserHydraItem } from "@verbleif/lib";
import type { PersonalObjectSchema } from "./personal.interface";
import { useLocationStore } from "@verbleif/shared";

function splitName(fullName: string) {
  const parts = fullName.split(" ");
  return {
    firstName: parts[0] || "",
    lastName: parts.slice(1).join(" ") || "",
  };
}
export function usePersonalTransformer() {
  function combineName(input: {
    firstName?: string | null
    lastName?: string | null
  }) {
    return [input.firstName, input.lastName].filter(Boolean).join(" ").trim();
  }

  function inputTransformer(input: UserHydraItem | null): PersonalObjectSchema {
    const nameParts = input?.name ? splitName(input.name) : { firstName: "", lastName: "" };
    return {
      firstName: nameParts.firstName,
      lastName: nameParts.lastName,
      username: input?.username ?? "",
      avatar: input?.avatar?.contentUrl ?? null,
      client: useLocationStore().selectedClient.value?.["@id"] ?? "",
      locale: input?.locale ?? "",
      timezone: input?.timezone ?? "",
    };
  }

  function outputTransformer(input: PersonalObjectSchema): PersonalWrite {
    const combinedName = combineName(input);

    return {
      name: combinedName,
      username: input.username,
      timezone: input.timezone,
      locale: input.locale,
      avatar: input.avatar,
    };
  }

  function outputPartialTransformer(input: Partial<PersonalObjectSchema>): Partial<PersonalWrite> {
    return {
      name: input.firstName !== undefined || input.lastName !== undefined ? combineName(input) : undefined,
      username: input.username !== undefined ? input.username : undefined,
      timezone: input.timezone !== undefined ? input.timezone : undefined,
      locale: input.locale !== undefined ? input.locale : undefined,
      avatar: input.avatar !== undefined ? input.avatar : undefined,
    };
  }

  return {
    inputTransformer,
    outputTransformer,
    outputPartialTransformer,
  };
}
