<script setup lang="ts">
import type { ModernSelectProps } from "../AutoComplete";
import { computed, ref, toRefs, watch } from "vue";

defineOptions({
  name: "VTagInput",
  inheritAttrs: false,
});

const props = withDefaults(defineProps<ModernSelectProps & {
  textAlign?: string
  allowDuplicates?: boolean
  tagTextFieldName?: Extract<keyof any, string> | string
}>(), {
  allowDuplicates: false,
  tagTextFieldName: "name",
  modelValue: null,
  iconLeft: undefined,
  iconRight: "chevron-down",
  disabled: false,
  errorMessage: null,
  clearSearchOnSelect: false,
  clearValueOnSelect: false,
  singleItemAutoSelect: false,
  keyFieldName: null,
  uniqueKeyOfObject: "id",
  searchFieldName: "name",
  noResultsText: "No results.",
  refreshText: "Refresh",
  labelLeft: "",
  required: false,
  debug: false,
  textAlign: "is-text-align-right",
  placeholder: null,
  items: () => [],
  excludedItems: () => [],
  onLoad: null,
  loadParams: () => {
  },
  variant: "is-gray",
  watchForChange: null,
});

const emit = defineEmits<{
  "update:modelValue": [v: any]
  "select-all": []
  "paste": [text: string]
}>();

const selectedTagForBackspace = ref(null);
const searchValue = ref("");
const { modelValue } = toRefs(props);
const valueRef = ref(modelValue.value);
const allItems = ref<Record<string, string>[] | null>(null); // Initial null, for loading state detection since empty array can be setAllItems([]) from api

function setAllItems(items: any) {
  allItems.value = items;
}

const selectedItems = computed(() => {
  if (valueRef.value === null) {
    return [];
  }

  return valueRef.value.map((value: any) => {
    if (!allItems.value) {
      return value;
    }

    const match = allItems.value.find((allValue) => {
      if (!props.keyFieldName && typeof props.uniqueKeyOfObject === "string") {
        return Object.prototype.hasOwnProperty.call(value, props.uniqueKeyOfObject)
          ? allValue === value
          : allValue[props.uniqueKeyOfObject] === value[props.uniqueKeyOfObject];
      }

      if (!props.keyFieldName) {
        return allValue === value;
      }

      return Object.prototype.hasOwnProperty.call(allValue, props.keyFieldName)
        && allValue[props.keyFieldName] === value;
    });

    if (match === undefined) {
      return value;
    }

    return match;
  });
});

function addTag(newModelValue: any) {
  if (newModelValue === null) {
    if (valueRef.value) {
      valueRef.value.splice(0, valueRef.value.length);
      emit("update:modelValue", valueRef.value);
    }
    return;
  }

  if (valueRef.value === null) {
    valueRef.value = [];
  }

  const match = valueRef.value.find((i: any) => {
    if (props.keyFieldName === null && typeof props.uniqueKeyOfObject === "string") {
      return newModelValue?.[props.uniqueKeyOfObject] === undefined
        ? i === newModelValue
        : i[props.uniqueKeyOfObject] === newModelValue[props.uniqueKeyOfObject];
    }

    return i === newModelValue;
  });

  if (!props.allowDuplicates && match !== undefined) {
    return;
  }

  valueRef.value.push(newModelValue);
  emit("update:modelValue", valueRef.value);
}

// If the change comes from the outside, we need to update the valueRef
watch(modelValue, (newValue, oldValue) => {
  if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
    return;
  }

  valueRef.value = newValue;
});

function updateSearchValue(val: string) {
  searchValue.value = val;
}

function onEscape() {
  selectedTagForBackspace.value = null;
}

function removeTag(index: number | string) {
  valueRef.value.splice(index, 1);
  emit("update:modelValue", valueRef.value);
}

// Add a ref for the container
const containerRef = ref<HTMLElement | null>(null);

// Update the onModernSelectKeyDown function to use the containerRef
function onModernSelectKeyDown(event: KeyboardEvent) {
  // Handle Ctrl/Cmd + A
  if ((event.ctrlKey || event.metaKey) && event.key === "a") {
    event.preventDefault();
    event.stopPropagation();

    // Select all tags within this specific component
    const tagsContainer = containerRef.value?.querySelector(".tags");
    if (tagsContainer) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(tagsContainer);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  }

  // Handle Ctrl/Cmd + X (Cut)
  if ((event.ctrlKey || event.metaKey) && event.key === "x") {
    event.preventDefault();
    event.stopPropagation();

    // Get all tags and their values
    const tags = containerRef.value?.querySelectorAll(".tag-container");
    if (!tags) {
      return;
    }

    const values = Array.from(tags).map(tag => tag.getAttribute("data-value")).filter(Boolean);

    // Copy the values to clipboard
    if (values.length > 0) {
      navigator.clipboard.writeText(values.join(","));
    }

    // Clear all tags
    valueRef.value = [];
    emit("update:modelValue", valueRef.value);
  }

  // Handle Backspace or Delete
  if (event.key === "Backspace" || event.key === "Delete") {
    const hasSelection = window.getSelection()?.toString();

    if (hasSelection) {
      // Handle selected tags deletion
      event.preventDefault();
      event.stopPropagation();

      const selectedTags = containerRef.value?.querySelectorAll(".tag-container");
      if (!selectedTags) {
        return;
      }

      // Remove all selected tags
      Array.from(selectedTags).forEach((tag) => {
        const tagValue = tag.getAttribute("data-value");
        if (!tagValue) {
          return;
        }

        const index = valueRef.value.findIndex((item: any) => {
          if (props.keyFieldName) {
            const itemValue = typeof item === "object" ? item[props.keyFieldName] : item;
            const tagTypedValue = typeof itemValue === "number" ? Number(tagValue) : tagValue;
            return itemValue === tagTypedValue;
          }
          if (typeof props.uniqueKeyOfObject === "string") {
            const itemValue = typeof item === "object" ? item[props.uniqueKeyOfObject] : item;
            const tagTypedValue = typeof itemValue === "number" ? Number(tagValue) : tagValue;
            return itemValue === tagTypedValue;
          }
          return item === tagValue;
        });

        if (index !== -1) {
          valueRef.value.splice(index, 1);
        }
      });

      // Clear selection
      window.getSelection()?.removeAllRanges();
    } else if (event.key === "Backspace" && !searchValue.value && valueRef.value?.length > 0) {
      // Remove last tag when pressing backspace with no selection and empty search
      event.preventDefault();
      event.stopPropagation();

      // Remove last tag
      valueRef.value.pop();
    }

    // Emit update
    emit("update:modelValue", valueRef.value);
  }
}

// Update the onCopy function to use the containerRef
function onCopy(event: ClipboardEvent) {
  // Only handle copy if tags are selected
  const selection = window.getSelection();
  if (!selection?.toString()) {
    return;
  }

  event.preventDefault();

  // Get all tags within this specific component
  const tags = containerRef.value?.querySelectorAll(".tag-container");
  if (!tags) {
    return;
  }

  const values = Array.from(tags).map(tag => tag.getAttribute("data-value")).filter(Boolean);

  // Copy the data values instead of the visible text
  if (values.length > 0) {
    event.clipboardData?.setData("text/plain", values.join(","));
  }
}

function onPaste(event: ClipboardEvent) {
  event.preventDefault();
  const text = event.clipboardData?.getData("text");
  if (!text) {
    return;
  }

  // Split pasted text by commas and trim whitespace
  const pastedValues = text.split(",").map(value => value.trim()).filter(Boolean);

  // Add each value
  pastedValues.forEach((pastedValue) => {
    // Try to find matching item from allItems based on the pasted value (ID/IRI)
    const matchingItem = allItems.value?.find((item: any) => {
      if (props.keyFieldName) {
        // Convert types to match if needed
        const itemValue = item[props.keyFieldName];
        const pastedTypedValue = typeof itemValue === "number" ? Number(pastedValue) : pastedValue;
        return itemValue === pastedTypedValue;
      }
      if (typeof props.uniqueKeyOfObject === "string") {
        // Convert types to match if needed
        const itemValue = item[props.uniqueKeyOfObject];
        const pastedTypedValue = typeof itemValue === "number" ? Number(pastedValue) : pastedValue;
        return itemValue === pastedTypedValue;
      }
      return item === pastedValue;
    });

    if (!matchingItem) {
      return;
    } // Skip if no matching item found

    // Check for duplicates
    const exists = valueRef.value.some((existingTag: any) => {
      if (props.keyFieldName) {
        // When using keyFieldName, compare with the raw values
        return existingTag === matchingItem[props.keyFieldName];
      }
      if (typeof props.uniqueKeyOfObject === "string") {
        const existingValue = typeof existingTag === "object" ? existingTag[props.uniqueKeyOfObject] : existingTag;
        return existingValue === matchingItem[props.uniqueKeyOfObject];
      }
      return existingTag === matchingItem;
    });

    if (exists) {
      return;
    } // Skip duplicates

    // Add either just the ID or the full object based on keyFieldName
    const newTag = props.keyFieldName
      ? matchingItem[props.keyFieldName] // Just store the ID when keyFieldName is set
      : matchingItem; // Store full object otherwise

    valueRef.value.push(newTag);
  });

  emit("update:modelValue", valueRef.value);
}
</script>

<template>
  <div
    class="tag-input-container"
    :class="[{ 'is-disabled': disabled }]"
    @paste="onPaste"
    @copy="onCopy"
  >
    <label v-if="labelLeft" class="label">
      {{ labelLeft }}
      <span v-if="required" class="required">*</span>
    </label>
    <div
      class="input"
      :class="[
        variant,
        textAlign,
        errorMessage !== null && 'is-danger',
        {
          'has-icon-left': iconLeft,
          'has-icon-right': iconRight,
          'has-validation-error': errorMessage !== null,
        },
      ]"
    >
      <!--      <div v-if="iconLeft" class="icon left"> -->
      <!--        <FontAwesomeIcon :icon="iconLeft" /> -->
      <!--      </div> -->
      <div class="input-tag-wrapper">
        <div v-if="selectedItems?.length" class="tags">
          <VTag
            v-for="(tag, index) in selectedItems"
            :key="tag?.[keyFieldName || ''] || tag?.[uniqueKeyOfObject || ''] || tag"
            class="tag"
            :class="{ 'tag-is-selected': selectedTagForBackspace === index }"
            :disabled="disabled"
            :text="tag?.[tagTextFieldName] || tag"
            :data-value="keyFieldName ? tag[keyFieldName]
              : (typeof uniqueKeyOfObject === 'string' ? tag[uniqueKeyOfObject] : tag)"
            @close="() => removeTag(index)"
          />
        </div>
        <VModernSelect
          :model-value="null"
          :on-load="onLoad"
          :load-params="loadParams"
          :variant="variant"
          :disabled="disabled"
          :items="items"
          :icon-left="iconLeft"
          :excluded-items="selectedItems"
          :error-message="errorMessage"
          :watch-for-change="watchForChange"
          :key-field-name="keyFieldName"
          :unique-key-of-object="uniqueKeyOfObject"
          :search-field-name="searchFieldName as (Extract<keyof any, string>)"
          :no-results-text="noResultsText"
          :refresh-text="refreshText"
          :required="required"
          :debug="debug"
          :text-align="textAlign"
          :single-item-auto-select="singleItemAutoSelect"
          :placeholder="placeholder"
          clear-search-on-select
          clear-value-on-select
          @update:search-value="updateSearchValue"
          @keydown.escape="onEscape"
          @update:model-value="addTag"
          @update:all-items="setAllItems"
          @keydown="onModernSelectKeyDown"
        />
      </div>
      <!--      <div v-if="iconRight" class="icon right"> -->
      <!--        <FontAwesomeIcon :icon="iconRight" /> -->
      <!--      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tag-input-container {
  width: 100%;
  max-width: 100%;

  .tag-is-selected {
    border: 2px solid #4caf50;
  }

  .label {
    display: inline-flex;
    color: var(--text);
    gap: 5px;

    .required {
      color: var(--secondary);
      font-weight: bold;
    }
  }

  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 25px;
    cursor: text;
    border: 1px solid #E0E0E0;
    background-color: #FFFFFF;
    color: var(--input-text);

    &::placeholder {
      color: #E0E0E0;
    }

    &.has-icon-left {
      padding-left: 37px;
    }

    &.is-text-align-left {
      .label {
        min-width: 175px;
      }
    }

    &.is-disabled {
      opacity: 0.25;
      cursor: not-allowed;

      &>* {
        pointer-events: none;
      }
    }

    &.is-danger {
      border: 1px solid var(--danger);

      .icon {
        color: var(--danger);
      }
    }

    &.is-success {
      border: 1px solid var(--success);

      .icon {
        color: var(--success);
      }
    }

    &.is-info {
      border: 1px solid var(--info);

      .icon {
        color: var(--info);
      }
    }

    &.is-warning {
      border: 1px solid var(--warning);

      .icon {
        color: var(--warning);
      }
    }

    &.is-primary {
      border: 1px solid var(--primary);

      .icon {
        color: var(--primary);
      }
    }

    &.is-secondary {
      border: 1px solid var(--secondary);

      .icon {
        color: var(--secondary);
      }
    }

    &.is-gray {
      border: 1px solid var(--input-border);

      .icon {
        color: var(--input-icon);
      }
    }

    .input-tag-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 5px;
      min-height: 40px;

      .tags {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        min-width: 0;
        gap: 5px;
        margin-top: 10px;
      }

      .input-wrapper {
        display: flex;
        margin-left: 0;
        width: 100%;
        flex: 1 1 100%;

        // Reset some ModernSelect CSS
        &::v-deep(.input) {
          padding: 0;
          background-color: transparent;
          border: none;

          input {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }

    .icon {
      position: absolute;
      color: var(--text);
      font-size: 14px;
      display: inline-flex;
      align-items: center;

      &.left {
        left: 0;
        margin-left: 15px;
      }

      &.right {
        margin-right: 15px;
        right: 0;

        .clear-icon {
          margin-right: 5px;
        }

        &.has-action {
          cursor: pointer;
        }
      }
    }
  }
}

.tag-input-container {
  .tags {
    user-select: text !important;
    cursor: text;

    ::v-deep(.tag-container) {
      user-select: contain !important;
      cursor: text;

      .text {
        user-select: text !important;
        cursor: text;
      }
    }
  }

  // Add specific styles for the input
  .input-wrapper {
    ::v-deep(.input) {
      input {
        user-select: text !important;
        cursor: text;
      }
    }
  }
}
</style>
