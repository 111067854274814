<script setup lang="ts">
import { computed } from "vue";
import { useModalStore } from "./modalStore";

const modalStore = useModalStore();
const isActive = computed(() => modalStore.state.modals.length > 0);
</script>

<template>
  <!-- Teleport Target -->
  <div id="modals" />
  <transition name="fade">
    <div v-if="isActive" class="container">
      <div class="overlay" />
    </div>
  </transition>
</template>

<style scoped lang="scss">
.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001; /* Lower z-index to ensure the overlay is beneath the modal */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001; /* Overlay should also have a lower z-index */
}
</style>
