import activities from "./activities.json";
import auth from "./auth.json";
import base from "./base.json";
import clients from "./clients.json";
import csv from "./csv.json";
import dragAndDrop from "./dragAndDrop.json";
import filters from "./filters.json";
import locations from "./locations.json";
import navbar from "./navbar.json";

import notifications from "./notifications.json";
import objects from "./objects.json";
import permissions from "./permissions.json";
import permissions_map from "./permissions_map.json";
import reports from "./reports.json";
import reservations from "./reservations.json";
import roles from "./roles.json";
import server from "./server.json";
import settings from "./settings.json";
import tasks from "./tasks.json";
import thirdParty from "./thirdParty.json";
import users from "./users.json";
import validation from "./validation.json";

export const en = {
  base,
  roles,
  thirdParty,
  validation,
  settings,
  auth,
  clients,
  tasks,
  objects,
  reports,
  notifications,
  permissions_map,
  dragAndDrop,
  csv,
  locations,
  navbar,
  users,
  reservations,
  activities,
  permissions,
  filters,
  server,
};
