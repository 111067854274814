<script setup lang="ts">
</script>

<template>
  <div class="flex-1 min-h-0 flex flex-col overflow-hidden bg-transparent">
    <div class="flex-1 min-h-0 flex flex-col overflow-auto h-full w-full">
      <slot />
    </div>
  </div>
</template>
