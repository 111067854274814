import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class PropertyGroupVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPropertygroupCreateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPropertygroupCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPropertygroupReadany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPropertygroupRead,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPropertygroupDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPropertygroupDelete,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationPropertygroupUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationPropertygroupUpdate,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationPropertygroupCreateany,
        PermissionAttributesEnum.GlobalOperationPropertygroupUpdateany,
        PermissionAttributesEnum.GlobalOperationPropertygroupDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationPropertygroupCreate,
        PermissionAttributesEnum.ContextOperationPropertygroupUpdate,
        PermissionAttributesEnum.ContextOperationPropertygroupDelete,
      ],
    });
  };
}
