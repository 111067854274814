import i18n from "@verbleif/lib/src/translations/i18n";
import { createApp } from "vue";
import App from "../App.vue";
import router from "./plugin/router";

const app = createApp(App);

app.use(router);
app.use(i18n);

export default app;
