<script setup lang="ts">
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { usePaginate, useSortable } from "../../composables";

interface Props {
  placeholderText?: string
}

const {
  placeholderText = undefined,
} = defineProps<Props>();

const { t } = useI18n();

const {
  sort,
  sortableFields,
  sortDirection,
  toggleSortDirection,
} = useSortable();

const { resetAndLoad } = usePaginate();

watch([() => sort.value, () => sortDirection.value], () => resetAndLoad());

function handleIconLeftClick() {
  console.log("onHandleIconLeftClick");
  if (!sort.value) {
    return;
  }
  toggleSortDirection();
}

const sortDirectionIcon = computed(() => {
  return sortDirection.value === "ASC"
    ? ["fas", "arrow-up-wide-short"] as ["fas", string]
    : ["fas", "arrow-down-wide-short"] as ["fas", string];
});

const sortDirectionTranslation = computed(() => {
  return sortDirection.value === "ASC"
    ? t("base.sort.ascending")
    : t("base.sort.descending");
});
</script>

<template>
  <div class="flex items-center">
    <VModernSelect
      v-model="sort"
      :placeholder="placeholderText || t('base.sort.placeholder')"
      variant="is-white"
      key-field-name="id"
      :items="Object.entries(sortableFields).map(([value, label]) => ({
        id: value,
        name: label,
      }))"
      class="[&_.select]:bg-white [&_.icon]:cursor-pointer [&_.icon.is-disabled]:cursor-not-allowed [&_.icon.is-disabled]:opacity-50 [&_.icon.is-disabled]:outline-none"
    >
      <template #iconLeft>
        <VTooltip
          :disabled="!sort"
          placement="top"
        >
          <template #reference>
            <FontAwesomeIcon
              :icon="sortDirectionIcon"
              :class="{ 'cursor-default opacity-50 outline-none': !sort }"
              class="cursor-pointer text-base mr-2 [&>path]:fill-[var(--text)] mt-0.5"
              @click.stop.prevent="handleIconLeftClick"
            />
          </template>
          <template #content>
            {{ sortDirectionTranslation }}
          </template>
        </VTooltip>
      </template>
    </VModernSelect>
  </div>
</template>
