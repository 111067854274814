<script>
import { watch } from "vue";
import { useCursor, usePaginate } from "../../composables";

export default {
  name: "VPerPage",
  props: {
    perPageText: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: () => {
        return {
          10: "10",
          20: "20",
          50: "50",
          100: "100",
        };
      },
    },
  },
  setup() {
    const { perPage } = useCursor();
    const { resetAndLoad } = usePaginate();

    watch(perPage, () => resetAndLoad());

    return {
      perPage,
    };
  },
};
</script>

<template>
  <div class="per-page">
    <VSelect v-model="perPage" :placeholder="perPageText">
      <option v-for="(text, key) in options" :key="key" :value="key">
        {{ text }}
      </option>
    </VSelect>
  </div>
</template>

<style lang="scss" scoped>
.per-page {
  width: 200px;
  margin-right: 10px;
}
</style>
