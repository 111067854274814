import type { InferType } from "yup";
import { object, string } from "yup";

export function timeEntriestopSchema(_t: ReturnType<typeof useI18n>["t"]) {
  return object({
    notes: string().optional().default(undefined),
  });
}

export type TimeEntriestopSchema = InferType<ReturnType<typeof timeEntriestopSchema>>;
