import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class LocationVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationLocationCreateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationLocationCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationLocationReadany,
      contextAttribute: PermissionAttributesEnum.ContextOperationLocationRead,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationLocationDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextOperationLocationDelete,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationLocationUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationLocationUpdate,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationLocationCreateany,
        PermissionAttributesEnum.GlobalOperationLocationUpdateany,
        PermissionAttributesEnum.GlobalOperationLocationDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationLocationCreate,
        PermissionAttributesEnum.ContextOperationLocationUpdate,
        PermissionAttributesEnum.ContextOperationLocationDelete,
      ],
    });
  };
}
