<script setup lang="ts">
import type { CSSProperties } from "vue";
import { defineProps, ref, watch } from "vue";

const {
  text,
  variant = "is-primary",
  closeable = false,
  icon = "",
  disabled = false,
  color = null,
  backgroundColor = null,
  size = "small",
  cursor = false,
  iconLess = false,
  selectable = false,
  dataValue = "",
} = defineProps<{
  text?: string
  variant?: string
  closeable?: boolean
  icon?: string
  disabled?: boolean
  color?: string | null
  backgroundColor?: string | null
  size?: string
  cursor?: boolean
  iconLess?: boolean
  selectable?: boolean
  dataValue?: string
}>();

const emit = defineEmits<{
  close: []
}>();

const model = defineModel<boolean>("modelValue", { default: false });

const isChecked = ref(model.value);

watch(() => model.value, (newValue) => {
  isChecked.value = newValue;
});

function toggleCheck() {
  if (!selectable) {
    return;
  }
  isChecked.value = !isChecked.value;
  model.value = isChecked.value;
}

const tagStyle = ref<CSSProperties>({
  backgroundColor: backgroundColor ?? "",
  color: color ?? "",
});
</script>

<template>
  <div
    class="tag-container"
    :style="tagStyle"
    :class="[variant, { 'is-closeable': closeable && !disabled, 'is-cursor': cursor, 'is-iconless': iconLess, 'is-checked': isChecked, 'is-selectable': selectable }, size]"
    :data-value="dataValue"
    @click="toggleCheck"
  >
    <div v-if="icon && !iconLess" class="icon">
      <slot name="icon">
        <FontAwesomeIcon :key="icon" :icon="icon" />
      </slot>
    </div>

    <div v-if="text" class="text">
      {{ text }}
    </div>

    <div v-if="closeable && !disabled && !iconLess" class="icon">
      <FontAwesomeIcon data-test="close-icon" class="icon" icon="times" @click.stop="emit('close')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "../../scss/CloseIconMixin";

.tag-container {
  display: inline-flex;
  color: var(--text-invert);
  white-space: nowrap;
  font-family: "Roboto", sans-serif;
  width: fit-content;
  gap: 10px;

  &.is-cursor {
    cursor: pointer;
  }

  &.is-iconless {
    .icon {
      display: none;
    }
  }

  &.extra-small {
    border-radius: 9px;
    padding: 0px 8px;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
  }

  &.small {
    border-radius: 9px;
    padding: 2px 8px;
    font-size: 14px;
    line-height: 15px;
    font-weight: 700;
  }

  &.medium {
    font-weight: 700;
    border-radius: 12px;
    padding: 4px 12px;
    font-size: 16px;
    line-height: 17px;
  }

  &.large {
    font-weight: 700;
    border-radius: 15px;
    padding: 6px 16px;
    font-size: 18px;
    line-height: 19px;
  }

  &.is-secondary {
    color: var(--tag-secondary-text);
    background-color: var(--tag-secondary-background);
  }

  &.is-third {
    background-color: var(--gray-1);
    color: var(--text);
  }

  &.is-text-color {
    color: var(--text);
    background-color: var(--tag-background);
  }

  &.is-primary {
    color: var(--tag-primary-text);
    background-color: var(--tag-primary-background);
  }

  &.is-info {
    color: var(--tag-info-text);
    background-color: var(--tag-info-background);
  }

  &.is-warning {
    color: var(--tag-warning-text);
    background-color: var(--tag-warning-background);
  }

  &.is-danger {
    color: var(--tag-danger-text);
    background-color: var(--tag-danger-background);
  }

  &.is-success {
    color: var(--tag-success-text);
    background-color: var(--tag-success-background);
  }

  &.is-gray {
    //  Added new class for gray variant
    color: #4F4F4F; //  Set text color to gray
    background-color: #E0E0E0; //  Set background color to gray
  }

  .icon {
    cursor: pointer;
    transition: ease transform 250ms;

    &:hover {
      transform: rotate(90deg);
    }

    &:active {
      transform: rotate(90deg) scale(1.2);
    }
  }

  .text {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.is-selectable {
    cursor: pointer;
  }

  &.is-checked {
    background-color: var(--tag-primary-hover);
    color: var(--tag-primary-text);
  }
}
</style>
