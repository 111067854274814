<script setup lang="ts">
import { api, useAuthStore } from "@verbleif/shared";
import { useLogout } from "@web/features/Auth/Logout/useLogout";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import Avatar from "../Avatar/Avatar.vue";
import NavbarLocaleChange from "./NavbarLocaleChange/NavbarLocaleChange.vue";
import NavbarNotifications from "./NavbarNotifications/NavbarNotifications.vue";
import NavbarSettingsitem from "./NavbarSettingsItem.vue";

const { onError } = useServerErrorHandler();
const authStore = useAuthStore();
const route = useRoute();
const { logout } = useLogout();
const { t } = useI18n();
const TRANSLATION_KEY = "navbar.dropdown";
const ENDPOINT = `/users/${authStore.user.value?.id}/notifications`;
const notificationCount = ref();

async function checkNotificationAmount() {
  try {
    const response = await api.get(`${ENDPOINT}?perPage=1&filter[readAt][neq]=null`);
    if (response.data.totalItems) {
      console.info(response.data.totalItems);
      notificationCount.value = response.data.totalItems;
    }
  } catch (error) {
    onError(error);
    throw error;
  }
}

onMounted(async () => {
  await checkNotificationAmount();
});
</script>

<template>
  <VDropdown
    theme="verbleif-popper-bounded"
    placement="right"
    auto-size="min"
  >
    <template #popper>
      <div class="flex flex-col">
        <div class="flex items-center mx-4 mt-4 mb-3">
          <Avatar :name="authStore.user.value?.name" class="w-6 h-7 mr-2" size="is-small" />
          <div class="text-xs">
            {{ authStore.user.value?.name }}
          </div>
        </div>
        <div class="flex flex-col">
          <RouterLink v-close-popper.all to="/settings/my-account" class="">
            <NavbarSettingsitem :icon="['fad', 'user']" :text="t(`${TRANSLATION_KEY}.my_account`)" />
          </RouterLink>
          <NavbarLocaleChange />
          <NavbarNotifications />
          <RouterLink v-close-popper.all :to="`/spaces?redirect=${route.path}`">
            <NavbarSettingsitem :icon="['fad', 'location-dot']" :text="t(`${TRANSLATION_KEY}.location_switch`)" />
          </RouterLink>
          <div v-close-popper.all @click="logout()">
            <NavbarSettingsitem variant="danger" :icon="['fad', 'right-from-bracket']" :text="t(`${TRANSLATION_KEY}.logout`)" />
          </div>
        </div>
      </div>
      <div class="flex w-full bg-gray-50 dark:bg-dark-800 text-gray-500 py-2 mt-4 px-4 text-xs">
        <div class="mr-10 font-bold">
          DOWNLOAD APPS:
        </div>
        <div class="flex gap-3">
          <a href="https://verbleif.com/android" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon :icon="['fab', 'android']" />
          </a>
          <a href="https://verbleif.com/ios" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon :icon="['fab', 'apple']" />
          </a>
        </div>
      </div>
    </template>
    <div>
      <Avatar
        size="is-large"
        :name="authStore.user.value?.name"
        :notifications="notificationCount"
        class="cursor-pointer"
      />
    </div>
  </VDropDown>
</template>
