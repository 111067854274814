<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  icon: string[]
  variant?: "default" | "danger" // Add more variants as needed
  text: string
}>();

// Dynamically compute the classes based on the variant
const computedVariantClass = computed(() => {
  switch (props.variant) {
    case "danger":
      return "text-red-500";
    default:
      return ""; // Default styles or no extra classes
  }
});
</script>

<template>
  <div class="flex items-center cursor-pointer text-sm text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-dark-600 py-1 px-4">
    <div
      class="w-5 flex items-center justify-center"
      :class="computedVariantClass"
    >
      <FontAwesomeIcon :icon="icon" />
    </div>
    <div class="mx-1">
      {{ text }}
    </div>
  </div>
</template>
