<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref, shallowRef } from "vue";
import VButton from "../Button/VButton.vue";
import { useDialogStore } from "../Dialog/dialogStore";
import Dialog from "./VDialog.vue";

interface ConfirmOptions {
  title: string
  message: string
  confirmText?: string
  cancelText?: string
  icon?: string
  type?: string
}

const { openDialog, closeDialog } = useDialogStore();

const visible = ref(false);
const resolvePromise = shallowRef<((value: boolean) => void) | null>(null);
const dialogId = computed(() => `confirm-dialog-${Date.now()}`);

const options = ref<ConfirmOptions>({
  title: "",
  message: "",
  confirmText: "Confirm",
  cancelText: "Cancel",
  icon: "",
  type: "primary",
});

function getIconClass() {
  switch (options.value.type) {
    case "info": return "fa-info-circle";
    case "warning": return "fa-exclamation-triangle";
    case "danger": return "fa-exclamation-circle";
    case "success": return "fa-check-circle";
    default: return "fa-question-circle";
  }
}

function openConfirmDialog(confirmOptions: ConfirmOptions): Promise<boolean> {
  return new Promise((resolve) => {
    options.value = {
      ...options.value,
      ...confirmOptions,
    };
    resolvePromise.value = resolve;
    visible.value = true;
    openDialog(dialogId.value);
  });
}

function handleClose() {
  visible.value = false;
  closeDialog(dialogId.value);
  resolvePromise.value?.(false);
}

function handleConfirm() {
  visible.value = false;
  closeDialog(dialogId.value);
  resolvePromise.value?.(true);
}

function handleCancel() {
  visible.value = false;
  closeDialog(dialogId.value);
  resolvePromise.value?.(false);
}

defineExpose({ openDialog: openConfirmDialog });
</script>

<template>
  <Dialog
    :id="dialogId"
    v-model:visible="visible"
    size="confirm"
    mask
    :draggable="false"
    @close="handleClose"
  >
    <template #title>
      <strong class="text-lg">{{ options.title }}</strong>
    </template>
    <template #content>
      <div class="confirm-dialog-content">
        <FontAwesomeIcon
          v-if="options.icon"
          :icon="options.icon || ['fas', getIconClass()]"
          :class="options.type"
          size="2x"
        />
        <div class="description">
          {{ options.message }}
        </div>
      </div>
    </template>
    <template #footer>
      <VButton variant="is-gray" rounded @click="handleCancel">
        {{ options.cancelText }}
      </VButton>
      <VButton :variant="`is-${options.type}`" rounded @click="handleConfirm">
        {{ options.confirmText }}
      </VButton>
    </template>
  </Dialog>
</template>

<style scoped lang="scss">
@mixin type-color($type) {
  .#{$type} {
    color: var(--#{$type});
  }
}

.confirm-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.description {
  margin-top: 1rem;
  text-align: center;
  width: 100%;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #3498db;
  color: white;
}

.btn-primary:hover {
  background-color: #2980b9;
}

.btn-secondary {
  background-color: #ecf0f1;
  color: #34495e;
}

.btn-secondary:hover {
  background-color: #bdc3c7;
}

.btn-info {
  background-color: #3498db;
  color: white;
}

.btn-info:hover {
  background-color: #2980b9;
}

.btn-warning {
  background-color: #f39c12;
  color: white;
}

.btn-warning:hover {
  background-color: #d35400;
}

.btn-danger {
  background-color: #e74c3c;
  color: white;
}

.btn-danger:hover {
  background-color: #c0392b;
}

.btn-success {
  background-color: #4CAF50;
  color: white;
}

.btn-success:hover {
  background-color: #43A047;
}

@include type-color('info');
@include type-color('warning');
@include type-color('danger');
@include type-color('primary');
@include type-color('secondary');
@include type-color('success');
</style>
