<script>
import { computed, ref, toRefs } from "vue";
import VDayItem from "./VDayItem.vue";
import VWeekItem from "./VWeekItem.vue";

export default {
  name: "VDatePicker",
  components: { VDayItem, VWeekItem },
  props: {
    modelValue: {
      type: Date,
      default: null,
    },
    startMonth: {
      type: Date,
      default: () => new Date(),
    },
    firstDayOfWeek: {
      type: Number,
      default: 1,
    },
    allowFuture: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { modelValue, startMonth } = toRefs(props);
    const monthViewing = ref(modelValue.value || startMonth.value);
    const selectedDate = ref(modelValue.value);
    const selectedMonthFormatted = computed(() =>
      monthViewing.value.toLocaleDateString(undefined, {
        month: "short",
        year: "numeric",
      }),
    );
    const weeksInThisMonth = computed(() => {
      const month = monthViewing.value.getMonth();
      const year = monthViewing.value.getFullYear();
      const weeksInThisMonth = [];
      let startingDay = 1;
      while (weeksInThisMonth.length < 6) {
        const newWeek = weekBuilder(month, year, startingDay);
        weeksInThisMonth.push(newWeek);
        startingDay += 7;
      }
      return weeksInThisMonth;
    });

    const baseDate = new Date(Date.UTC(2017, 0, 2)); // Random monday
    const weekDays = [];
    for (let i = 0; i <= 6; i++) {
      const name = baseDate.toLocaleDateString(undefined, { weekday: "short" });
      weekDays.push(name.substr(0, 2));
      baseDate.setDate(baseDate.getDate() + 1);
    }

    function setCurrentDate(date) {
      // Cant use setMonth, not reactive
      monthViewing.value = new Date(
        monthViewing.value.setMonth(date.getMonth()),
      );
      monthViewing.value = new Date(
        monthViewing.value.setFullYear(date.getFullYear()),
      );
      selectedDate.value = date;
    }

    function weekBuilder(month, year, startingDate) {
      const thisMonth = new Date(year, month);
      const thisWeek = [];
      const dayOfWeek = new Date(year, month, startingDate).getDay();

      const end
        = dayOfWeek >= props.firstDayOfWeek
          ? dayOfWeek - props.firstDayOfWeek
          : 7 - props.firstDayOfWeek + dayOfWeek;

      let daysAgo = 1;
      for (let i = 0; i < end; i++) {
        thisWeek.unshift(
          new Date(
            thisMonth.getFullYear(),
            thisMonth.getMonth(),
            startingDate - daysAgo,
          ),
        );
        daysAgo++;
      }

      thisWeek.push(new Date(year, month, startingDate));

      let daysForward = 1;

      while (thisWeek.length < 7) {
        thisWeek.push(new Date(year, month, startingDate + daysForward));
        daysForward++;
      }

      return thisWeek;
    }

    function today() {
      monthViewing.value = new Date();
    }

    function monthBack() {
      monthViewing.value = new Date(
        monthViewing.value.setMonth(monthViewing.value.getMonth() - 1),
      );
    }

    function monthForward() {
      monthViewing.value = new Date(
        monthViewing.value.setMonth(monthViewing.value.getMonth() + 1),
      );
    }

    return {
      monthViewing,
      selectedDate,
      weekDays,
      weeksInThisMonth,
      selectedMonthFormatted,
      setCurrentDate,
      monthBack,
      monthForward,
      today,
    };
  },
};
</script>

<template>
  <div class="date-picker">
    <div class="header">
      <div class="selected-month">
        {{ selectedMonthFormatted }}
      </div>
      <div class="today-button" @click="today">
        Vandaag
      </div>
      <div class="up-button" @click="monthBack">
        <FontAwesomeIcon icon="chevron-up" />
      </div>
      <div class="down-button" @click="monthForward">
        <FontAwesomeIcon icon="chevron-down" />
      </div>
    </div>
    <div class="calendar">
      <div class="weekdays">
        <div v-for="weekDay in weekDays" :key="weekDay" class="day-name">
          {{ weekDay }}
        </div>
      </div>
      <div class="weeks">
        <VWeekItem v-for="week in weeksInThisMonth" :key="week" class="days">
          <VDayItem
            v-for="day in week"
            :key="day.toISOString()"
            :day="day"
            :selected-date="selectedDate"
            :viewing-month="monthViewing.getMonth()"
            @click="(e) => setCurrentDate(e)"
          />
        </VWeekItem>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calendar {
  display: flex;
  flex-direction: column;
  width: 100%;

  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    margin-top: 15px;
    margin-bottom: 10px;

    .day-name {
      font-size: 12px;
      color: var(--text);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .weeks {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);

    .days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
    }
  }
}
.date-picker {
  width: 233px;
  background-color: white;
  padding: 10px;
  user-select: none;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.selected-month {
  color: var(--text);
  font-size: 14px;
}
.today-button {
  color: var(--gray-1);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 1px;
  margin-left: auto;
  margin-right: 5px;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: var(--text-2);
  }
}
.up-button {
}
.down-button {
  margin-left: 5px;
}
.up-button,
.down-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: var(--text);
  background-color: var(--gray-1);
  width: 14px;
  height: 14px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: var(--text-2);
  }
}
</style>
