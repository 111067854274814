<script>
export default {
  name: "VWeekItem",
};
</script>

<template>
  <div class="date-row">
    <slot />
  </div>
</template>

<style scoped></style>
