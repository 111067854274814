import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#FF003C",
      "fill-rule": "nonzero",
      d: "M16 2.5c7.414 0 13.5 6.005 13.5 13.5 0 7.456-6.038 13.5-13.5 13.5-7.453 0-13.5-6.035-13.5-13.5C2.5 8.55 8.538 2.5 16 2.5m0-2C7.44.5.5 7.443.5 16c0 8.562 6.94 15.5 15.5 15.5S31.5 24.562 31.5 16C31.5 7.443 24.56.5 16 .5M15.282 8a.75.75 0 0 0-.75.781l.438 10.5a.75.75 0 0 0 .75.719h.56a.75.75 0 0 0 .75-.719l.437-10.5A.75.75 0 0 0 16.718 8zM16 21.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5"
    }, null, -1)
  ])))
}
export default { render: render }