<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import CloseIcon from "../../assets/CloseIcon.svg?component";

interface Props {
  closeable?: boolean
  variant?: string
  inverted?: boolean
  iconLeft?: object | null
  wrapText?: boolean
  size?: string
  icon?: string | null
  background?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  closeable: false,
  variant: "is-danger",
  inverted: false,
  iconLeft: null,
  wrapText: false,
  size: "medium",
  icon: null,
  background: true,
});

const emit = defineEmits<{
  (e: "close"): void
}>();

const computedIconLeft = computed(() => {
  if (props.icon === "warning") {
    return [props.background ? "fas" : "far", "triangle-exclamation"];
  }
  if (props.iconLeft === null) {
    const iconName = (() => {
      switch (props.variant) {
        case "is-warning":
          return "triangle-exclamation";
        case "is-danger":
          return "circle-exclamation";
        case "is-info":
          return "circle-info";
        case "is-success":
          return "circle-check";
        default:
          return null;
      }
    })();
    return iconName ? [props.background ? "fas" : "far", iconName] : null;
  }
  return props.iconLeft;
});

// TODO: Review colors in dark mode
</script>

<template>
  <div class="alert-container" :class="[variant, { 'is-inverted': inverted, 'no-background': !background }]">
    <div class="left">
      <div class="icon" :class="variant">
        <slot name="icon">
          <FontAwesomeIcon v-if="computedIconLeft" :icon="computedIconLeft" />
        </slot>
      </div>
      <div class="body" :class="[{ 'text-wrap': wrapText }, size]">
        <slot class="text" />
      </div>
    </div>
    <div v-if="closeable" class="right" @click="emit('close')">
      <CloseIcon />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@mixin alert-variant($color, $bg-color, $inverted-color, $variant) {
  color: $color;
  &:not(.no-background) {
    background-color: $bg-color;
  }
  &.no-background {
    color: theme('colors.#{$variant}.600');
  }
  &.is-inverted {
    color: $inverted-color;
    &:not(.no-background) {
      background-color: $color;
    }
  }
  .icon {
    color: theme('colors.#{$variant}.600');
  }
  .right {
    color: theme('colors.#{$variant}.600');
  }
}

.alert-container {
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  font-family: "Roboto", sans-serif;
  gap: 10px;
  padding: 15px;
  justify-content: space-between;

  &.is-primary {
    @include alert-variant(var(--tag-primary-text), var(--tag-primary-background), var(--tag-primary-background), 'primary');
  }

  &.is-transparent-danger {
    @include alert-variant(var(--transparent-red-text), var(--transparent-red-bg), var(--transparent-red-text), 'danger');
  }

  &.is-secondary {
    @include alert-variant(var(--tag-secondary-text), var(--tag-secondary-background), var(--tag-secondary-text-inverted), 'secondary');
  }

  &.is-info {
    @include alert-variant(var(--tag-info-text), var(--tag-info-background), var(--tag-info-text-inverted), 'info');
  }

  &.is-success {
    @include alert-variant(var(--tag-success-text), var(--tag-success-background), var(--tag-inverted-text), 'success');
  }

  &.is-danger {
    @include alert-variant(var(--tag-danger-text), var(--tag-danger-background), var(--tag-danger-background), 'danger');
  }

  &.is-warning {
    @include alert-variant(var(--tag-warning-text), var(--tag-warning-background), var(--tag-inverted-text), 'warning');
  }

  &.is-white,
  &.is-gray {
    @include alert-variant(var(--text), var(--tag-background), var(--text), 'gray');
  }

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .body {
      display: flex;
      align-items: center;
      line-height: 1px;

      &.text-wrap {
        line-height: unset;
      }

      &.medium {
        font-size: 16px;
      }

      &.small {
        font-size: 14px;
      }
    }
    .icon {
      display: flex;
    }
  }

  .right {
    display: flex;
  }

  &.is-text-color {
    color: var(--text);
    &:not(.no-background) {
      background-color: #cccccc;
    }
  }

  .text {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .right svg {
    width: 15px;
    cursor: pointer;
  }
}
</style>
