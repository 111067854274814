import type { Feature as FeatureInterface, UserHydraItem } from "@verbleif/lib";
import type { useAuthStore } from "../AuthStore";
import { ref, watch } from "vue";

export enum Feature {
  BOOKING_EXPERTS = "booking-experts",
  APP = "app",
  WEB_FIREBASE_NOTIFICATIONS = "firebase",
  CUSTOM_REPORT_LISTS = "custom-report-lists",
  CSV_IMPORT = "csv",
  CSV_IMPORT_ADVANCED = "csv-import-advanced",
  SUBSCRIPTION_MODULE = "subscription",
  LOCATION_MODULE = "locations",
  ROLES_MODULE = "roles",
  SORT_TYPES = "sort-types",
  TASKS_DONE_REPORTING = "task-status-done",
  CHANGE_OVER_DAY_MODULE = "change-over-day",
  TASK_LISTS = "task-lists",
  TASK_CREATE_BLOCKING_DEFAULT_TRUE = "task-create-blocking-default-true",
  TASKS_DATE_FILTER = "tasks-date-filter",
  MANUAL_THIRD_PARTY_FIELDS = "manual-third-party-fields",
  HIDE_REPORTS_DEPARTMENTS = "hide-reports-departments",
  NOTIFY_DP_MANAGERS = "notifications-to-department-managers",
  PUBLIC_REPORTS = "public-reports",
  TASK_STATUS_CHANGE_NOTIFICATION = "task-status-change-notification",
  TASK_CREATED_NOTIFICATION_DP_MANAGERS = "task-created-notifications-to-department-managers",
  TASK_COMMENT_NOTIFICATIONS = "task-comments-notification",
  APP_FOCUSED_ON_LOOP_ROUTE = "focused_tasks_route",
  STRATECH_CONNECTION = "stratech",
  HIDE_CREATE_TASK = "hide-task-create",
  DISABLE_REPORT_NOTIFICATIONS = "disable-report-notifications",
  APP_FOCUSED_ON_MY_TASKS_TILL_TODAY = "focused_tasks_until_today",
  ASYNC_BEX_AUTOMATIONS = "use-bex-automations",
  PUBLIC_NOTIFICATIONS_GREAT_STAY_APP = "great-stay-app",
  PUBLIC_NOTIFICATIONS_WEMA = "wema-mobile",
  HIDE_REPORTS = "hide-reports",
  HAS_ADVANCED_TASKS_EXPORT = "has-advanced-tasks-export",
  ANY_CAN_ASSIGN_TO_FRONTDESK = "any-can-assign-to-frontdesk",
  ONLY_DEPARTMENT_MANAGER_CAN_ASSIGN_TO_FRONTDESK = "only-department-manager-can-assign-to-frontdesk",
  TAG_USERS = "tag-users",
  TWO_FACTOR_AUTHENTICATION = "two-factor-authentication-on-account",
  ACTIVE_SESSIONS = "active-sessions",
  HIDE_TIMEZONES = "hide-timezones",
  ALLOW_ANY_TASK_STATUS_COMPLETED = "allow-any-task-status-completed",
}

export enum UserRole {
  ROLE_ADMIN = 0,
  ROLE_MANAGER = 1,
  ROLE_PARK_MANAGER = 2,
  ROLE_FRONT_DESK = 3,
  ROLE_DEPARTMENT_MANAGER = 4,
  ROLE_USER = 5,
}

export function createRightsStore(authStore: ReturnType<typeof useAuthStore>) {
  const effectiveFeatures = ref<Array<any>>([]);

  const roles: UserRole[] = [
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_MANAGER,
    UserRole.ROLE_FRONT_DESK,
    UserRole.ROLE_DEPARTMENT_MANAGER,
    UserRole.ROLE_USER,
  ];

  watch(
    authStore.user,
    (): void => {
      if (hasFeature(Feature.LOCATION_MODULE) && !roles.includes(UserRole.ROLE_PARK_MANAGER)) {
        roles.push(UserRole.ROLE_PARK_MANAGER);
      }
      roles.sort();
    },
    { immediate: true },
  );

  /**
   * User has access based on the role hierarchy.
   */
  // function hasRole(roleName: UserRole, subject: HydraItem<User> | null = authStore.user.value): boolean {
  //   if (!subject) {
  //     return false;
  //   }

  //   const highestRoleString = subject.roles?.[0];
  //   if (typeof highestRoleString !== "string") {
  //     throw new TypeError(`User with id ${subject.id} has no roles.`);
  //   }

  //   // Todo, would be nice if we transform this on receive of the API-call.
  //   if (!(highestRoleString in UserRole)) {
  //     throw new Error(`Role ${highestRoleString} does not exist inside enum.`);
  //   }

  //   const highestUserRole: UserRole = UserRole[highestRoleString as keyof typeof UserRole];

  //   return roleName >= highestUserRole;
  // }

  function hasRole(_roleName: UserRole, _subject: UserHydraItem | null = authStore.user.value): boolean {
    return true;
  }

  /**
   * User must have the role directly assigned.
   */
  function hasRoleStrict(roleName: UserRole, subject = authStore.user.value): boolean {
    if (!subject) {
      return false;
    }

    return subject.roles.some(role => role.roleName === UserRole[roleName]);
  }

  function getRoles(
    excludeOwnRole: boolean = true,
    subject: UserHydraItem | null = authStore.user.value,
  ): UserRole[] {
    if (!subject) {
      return [];
    }

    const highestRoleString = subject.roles?.[0];
    if (typeof highestRoleString !== "string") {
      throw new TypeError(`User with id ${subject.id} has no roles.`);
    }

    // Todo, would be nice if we transform this on receive of the API-call.
    if (!(highestRoleString in UserRole)) {
      throw new Error(`Role ${highestRoleString} does not exist inside enum.`);
    }

    const highestUserRole: UserRole = UserRole[highestRoleString as keyof typeof UserRole];

    if (excludeOwnRole) {
      return roles.filter(i => i !== highestUserRole);
    }

    return roles;
  }

  function reset() {
    effectiveFeatures.value = [];
  }

  function hasFeature(
    inputFeature: Feature,
    inputEffectiveFeatures: FeatureInterface[] | string[] | null = null,
  ): boolean {
    let didMatch = false;
    if (!inputEffectiveFeatures) {
      inputEffectiveFeatures = effectiveFeatures.value;
    }

    for (const feature of inputEffectiveFeatures) {
      // Login v2
      if (typeof feature === "string") {
        if (feature === inputFeature) {
          didMatch = true;
          break;
        }

        continue;
      }

      // Legacy login v1
      if (feature.alias === inputFeature.toString()) {
        didMatch = true;
        break;
      }
    }

    return didMatch;
    // TODO: When old call is removed we can enable this:
    // return subject.effectiveFeatures.includes(inputFeature.toString());
  }

  function setEffectiveFeatures(features: Array<any>) {
    effectiveFeatures.value = features;
  }

  return {
    hasRoleStrict,
    getRoles,
    hasRole,
    hasFeature,
    setEffectiveFeatures,
    reset,
  };
}

export default createRightsStore;
