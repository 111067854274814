import type { Ref } from "vue";
import { onMounted, ref } from "vue";

export function useWidthCalculator(initialWidth = 14): {
  containerRef: Ref<HTMLElement | null>
  width: Ref<number>
} {
  const containerRef = ref<HTMLElement | null>(null);
  const width = ref<number>(initialWidth);

  onMounted(() => {
    if (!containerRef.value) {
      return;
    }
    width.value = containerRef.value.offsetWidth;
  });

  return {
    width,
    containerRef: containerRef as Ref<HTMLElement | null>,
  };
}
