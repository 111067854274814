<script setup lang="ts">
import type { NotificationHydraItem } from "@verbleif/lib";
import { iriToId, LoopbackFilterLogicalOperatorEnum, LoopbackFilterOperatorEnum, providePaginate, ScrollDirection, useLazyLoadScroll } from "@verbleif/lib";
import { api, useAuthStore } from "@verbleif/shared";
import { TaskListType } from "@web/features/Tasks/TasksRoutes";
import moment from "moment";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

import NavbarSettingsitem from "../NavbarSettingsItem.vue";

const { t } = useI18n();
const authStore = useAuthStore();
const { onError } = useServerErrorHandler();
const router = useRouter();
const toast = useToast();
const container: Ref<HTMLElement | null> = ref(null);
const TRANSLATION_KEY = "navbar.dropdown";
const push = ref<boolean>(true);
const isAllRead = ref<boolean>(false);
const ENDPOINT = `/users/${authStore.user.value?.id}/notifications`;

const {
  load,
  loading,
  items,
  searchable,
  cursor: { currentPage, hasNextPage },
} = providePaginate<NotificationHydraItem>({
  cursorParams: { partial: true },
  initialPerPage: 10,
  sortableFields: {},
  searchableFields: [
    {
      name: "title",
      operator: LoopbackFilterOperatorEnum.ILIKE,
      logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
    },
    {
      name: "messageWeb",
      operator: LoopbackFilterOperatorEnum.ILIKE,
      logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
    },
  ],
  onLoad: options => api.get(ENDPOINT, options),
});

useLazyLoadScroll(
  container,
  (options: any) => {
    currentPage.value++;
    load(options);
  },
  hasNextPage,
  loading,
  ScrollDirection.BOTTOM,
);

function fromNow(sentAt: string) {
  const currentTime = moment();
  const sentAtTime = moment(sentAt);
  const diffInSeconds = currentTime.diff(sentAtTime, "seconds");

  if (diffInSeconds < 60) {
    return t(`${TRANSLATION_KEY}.just_now`);
  } else {
    return sentAtTime.from(currentTime);
  }
}

async function checkAllRead() {
  try {
    const response = await api.get(`${ENDPOINT}?perPage=1&filter[readAt][neq]=null`);
    if (response.data.totalItems) {
      isAllRead.value = true;
    }
  } catch (error) {
    onError(error);
    throw error;
  }
}

async function markAsRead() {
  // will send an api call eventually
  console.log("mark as read");

  items.value.forEach((notification) => {
    if ("readAt" in notification && !notification.readAt) {
      notification.readAt = new Date().toISOString();
    }
  });
}

async function notificationLocation(notification: NotificationHydraItem) {
  notification.readAt = new Date().toISOString();
  if (!notification.subject) {
    return;
  }
  const id = iriToId(notification.subject);
  if (notification.subject.includes("task")) {
    router.push({ name: "task-list", params: { type: TaskListType.TYPE_ALL }, query: { taskId: id } });
  } else if (notification.subject.includes("report")) {
    router.push({ name: "report-list", params: { type: "aall" }, query: { reportId: id } });
  } else {
    toast.error(t(`${TRANSLATION_KEY}.no_path`));
  }

  // api call to set it to read
}

watch(searchable.debouncedValue, () => {
  load();
});

onMounted(async () => {
  load();
  checkAllRead();
});
</script>

<template>
  <VDropdown
    theme="verbleif-popper-bounded"
    placement="right"
  >
    <template #popper>
      <div class="mx-5 text-gray-800 dark:text-white">
        <div class="flex mt-5 justify-between items-center">
          <div class="text-xl font-bold ">
            {{ t(`${TRANSLATION_KEY}.notifications`) }}
          </div>
          <div class="text-sm" :class="isAllRead ? 'text-primary-600 cursor-pointer' : 'text-gray-300 pointer-events-none'" @click="markAsRead()">
            {{ t(`${TRANSLATION_KEY}.mark_all_read`) }}
          </div>
        </div>
        <div class="my-3">
          <VDivider />
        </div>
        <div v-if="push" class="flex items-center justify-between rounded-lg bg-red-600 bg-opacity-20 text-red-600 mb-4 font-bold">
          <div class="flex items-center">
            <FontAwesomeIcon class="ml-4 mr-3" :icon="['fa', 'thumbtack']" />
            <div>{{ t(`${TRANSLATION_KEY}.push_notification`) }}</div>
          </div>
          <RouterLink v-close-popper.all class="m-4" to="/settings/notifications">
            <FontAwesomeIcon :icon="['fa', 'arrow-up-right-from-square']" />
          </RouterLink>
        </div>
        <div>
          <VInput
            v-model="searchable.searchValue.value"
            :icon-left="['fa', 'magnifying-glass']"
            :icon-right="['fa', 'xmark']"
            :placeholder="t(`${TRANSLATION_KEY}.search`)"
            @on-right-icon-click="searchable.searchValue.value = ''"
          />
        </div>
      </div>
      <ul ref="container" class="flex flex-col w-96 h-96 overflow-y-scroll mt-3 gap-2 text-gray-800 dark:text-white">
        <li
          v-for="(notification, index) in items"
          :key="'skeleton' in notification ? `skeleton-${index}` : notification.id"
        >
          <template v-if="'skeleton' in notification">
            <div
              class="flex pl-2 pr-3 py-3 rounded-lg group"
            >
              <div class="flex h-6 w-5 items-center justify-center">
                <VSkeleton
                  v-if="Math.round(Math.random())"
                  class="mb-1"
                  :dynamic-width="false"
                  :width="8"
                  :height="8"
                  width-unit="px"
                  height-unit="px"
                />
              </div>

              <div class="flex flex-col w-full">
                <div class="flex justify-between">
                  <VSkeleton class="mb-2" :width="100" :height="16" width-unit="px" height-unit="px" />
                  <VSkeleton class="mb-1" :width="100" :height="12" width-unit="px" height-unit="px" />
                </div>
                <div class="flex justify-between">
                  <div class="flex flex-col">
                    <VSkeleton class="mb-1" :width="200" :height="16" width-unit="px" height-unit="px" />
                    <VSkeleton class="mb-1" :width="150" :height="16" width-unit="px" height-unit="px" />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <div
              v-close-popper.all="!!notification.subject"
              class="flex pl-2 pr-3 py-3 rounded-lg group cursor-pointer"
              @click="notificationLocation(notification) "
            >
              <div
                class="flex h-6 w-5 items-center justify-center cursor-pointer"
              >
                <div v-if="!notification.readAt" class="w-2 h-2 bg-primary-600 rounded-full" />
              </div>
              <div class="flex flex-col w-full">
                <div class="flex justify-between">
                  <div :class="notification.readAt ? '' : 'font-bold'" class="dark:text-white">
                    {{ notification.title }}
                  </div>
                  <div v-if="notification.sentAt" class="text-gray-400 dark:text-grey-200 text-xs">
                    {{ fromNow(notification.sentAt) }}
                  </div>
                </div>
                {{ notification.subject }}
                <div class="flex justify-between">
                  <div class="text-gray-500 font-normal dark:text-grey-300 w-3/4">
                    {{ notification.messageWeb }}
                  </div>
                  <div class="flex items-center">
                    <div v-if="!notification.readAt" class="relative group">
                      <FontAwesomeIcon
                        class="text-primary-600 text-xl hidden group-hover:flex"
                        :icon="['fad', 'circle-check']"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </li>
      </ul>
    </template>
    <NavbarSettingsitem :icon="['fad', 'bell']" :text="t(`${TRANSLATION_KEY}.notifications`)" />
  </VDropdown>
</template>
