import type { Ref } from "vue";

export function useDialogDrag(
  dialogRef: Ref<HTMLElement | null>,
  options: {
    draggable: boolean
    keepInViewport: boolean
    onDragStart?: (event: MouseEvent) => void
    onDragEnd?: (event: MouseEvent) => void
  },
) {
  let dragging = false;
  let lastPageX = 0;
  let lastPageY = 0;

  function initDrag(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!options.draggable || target?.closest("[data-pc-section=\"headeractions\"]")) {
      return;
    }

    event.preventDefault(); // Prevent text selection during drag

    if (dialogRef.value) {
      // Remove centering styles
      dialogRef.value.classList.add("dragging");

      const rect = dialogRef.value.getBoundingClientRect();
      const initialLeft = rect.left;
      const initialTop = rect.top;

      // Set the dialog's position to fixed and set left and top
      dialogRef.value.style.position = "fixed";
      dialogRef.value.style.left = `${initialLeft}px`;
      dialogRef.value.style.top = `${initialTop}px`;
      dialogRef.value.style.transform = "none"; // Remove any transforms

      lastPageX = event.pageX;
      lastPageY = event.pageY;
    }

    document.body.setAttribute("data-p-unselectable-text", "true");
    document.body.style.userSelect = "none";

    dragging = true;
    options.onDragStart?.(event);
    bindDragListeners();
  }

  function bindDragListeners() {
    document.addEventListener("mousemove", onDrag);
    document.addEventListener("mouseup", onDragEnd);
  }

  function unbindDragListeners() {
    document.removeEventListener("mousemove", onDrag);
    document.removeEventListener("mouseup", onDragEnd);
  }

  function onDrag(event: MouseEvent) {
    if (!dragging || !dialogRef.value) {
      return;
    }

    const deltaX = event.pageX - lastPageX;
    const deltaY = event.pageY - lastPageY;

    const currentLeft = Number.parseFloat(dialogRef.value.style.left || "0");
    const currentTop = Number.parseFloat(dialogRef.value.style.top || "0");

    let newLeft = currentLeft + deltaX;
    let newTop = currentTop + deltaY;

    if (options.keepInViewport) {
      const viewport = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      const dialogRect = dialogRef.value.getBoundingClientRect();
      const dialogWidth = dialogRect.width;
      const dialogHeight = dialogRect.height;

      newLeft = Math.max(0, Math.min(newLeft, viewport.width - dialogWidth));
      newTop = Math.max(0, Math.min(newTop, viewport.height - dialogHeight));
    }

    dialogRef.value.style.left = `${newLeft}px`;
    dialogRef.value.style.top = `${newTop}px`;

    lastPageX = event.pageX;
    lastPageY = event.pageY;
  }

  function onDragEnd(event: MouseEvent) {
    if (!dragging) {
      return;
    }

    dragging = false;
    document.body.removeAttribute("data-p-unselectable-text");
    document.body.style.userSelect = "";

    // Optionally remove the 'dragging' class if you want to reset styles
    // dialogRef.value?.classList.remove('dragging');

    options.onDragEnd?.(event);
    unbindDragListeners();
  }

  return {
    initDrag,
  };
}
