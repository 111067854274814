import type { InternalAxiosRequestConfig } from "axios";
import type { InterceptorConfig } from "../../createApi.ts";
import { InterceptorType } from "../../createApi.ts";

export function JwtBearerTokenRequestInterceptor(
  tokenGetter: () => string,
  skip?: (config: InternalAxiosRequestConfig) => boolean,
): InterceptorConfig<InterceptorType.REQUEST> {
  async function resolved(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig> {
    if (!config.url) {
      return config;
    }

    if (skip?.(config)) {
      return config;
    }

    config.headers.Authorization = `Bearer ${tokenGetter()}`;

    return config;
  }

  return {
    name: "JwtBearerTokenRequestInterceptor",
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved,
  };
}
