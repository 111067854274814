import type { ClientHydraItem, ProductHydraItem, PropertyGroupHydraItem, PropertyHydraItem, ReportTopicHydraItem, SortHydraItem, TasksExtraData, UserGroupHydraItem, UserHydraItem } from "@verbleif/lib";
import { getAll, useLocationStore, useSharedStore } from "@verbleif/shared";
import { useSystemStore } from "@web/features/Store/useSystemStore";
import { usePropertyExtraData } from "./features/Tasks/TaskExtraData/usePropertyExtraData";
import { useActiveTimeEntry } from "./features/Tasks/TimeEntry/useActiveTimeEntry";

export function useAppSync() {
  const { setClients, modeSelectedLocation, modeSelectedClient } = useLocationStore();
  const {
    setProperties,
    setSorts,
    setPropertyGroups,
    setReportTopics,
    setProducts,
  } = useSystemStore();

  const { setUsers, setUserGroups } = useSharedStore();

  const { setPropertiesExtraData } = usePropertyExtraData();
  const { getActiveTimeEntry } = useActiveTimeEntry();
  const isSyncRunning = ref(false);

  const params = computed<URLSearchParams>(() => {
    const params = new URLSearchParams();

    if (modeSelectedLocation.value) {
      params.set("location", `${modeSelectedLocation.value}`);
    }

    if (modeSelectedClient.value) {
      params.set("client", `${modeSelectedClient.value}`);
    }

    return params;
  });

  async function syncUserGroups() {
    const userGroups = await getAll<UserGroupHydraItem>({
      url: "/user_groups",
      initialParams: params.value,
    });
    setUserGroups(userGroups);
  };

  async function syncProperties() {
    const properties = await getAll<PropertyHydraItem>({
      url: "/properties",
      initialParams: params.value,
    });
    setProperties(properties);
  }

  async function syncPropertiesExtraData() {
    const propertiesExtraData = await getAll<TasksExtraData>({
      url: "/property-extra-datas",
      initialParams: params.value,
    });
    setPropertiesExtraData(propertiesExtraData);
  }

  async function syncSorts() {
    const sorts = await getAll<SortHydraItem>({
      url: "/sorts",
      initialParams: params.value,
    });
    setSorts(sorts);
  }

  async function syncUsers() {
    const users = await getAll<UserHydraItem>({
      url: "/users",
      initialParams: params.value,
    });
    setUsers(users);
  }

  async function syncPropertyGroups() {
    const propertyGroups = await getAll<PropertyGroupHydraItem>({
      url: "/property_groups",
      initialParams: params.value,
    });
    setPropertyGroups(propertyGroups);
  }

  async function syncClients() {
    const clients = await getAll<ClientHydraItem>({
      url: "/clients",
      initialParams: params.value,
    });
    setClients(clients);
  }

  async function syncReportTopics() {
    const reportTopics = await getAll<ReportTopicHydraItem>({
      url: "/report_topics",
      initialParams: params.value,
    });
    setReportTopics(reportTopics);
  }

  async function syncProducts() {
    const products = await getAll<ProductHydraItem>({
      url: "/products",
      initialParams: params.value,
    });
    setProducts(products);
  }

  async function syncAll() {
    if (isSyncRunning.value) {
      return;
    }
    try {
      isSyncRunning.value = true;
      syncUsers().then();
      syncUserGroups().then();
      syncSorts().then();
      syncProperties().then();
      syncPropertiesExtraData().then();
      syncPropertyGroups().then();
      syncClients().then();
      syncReportTopics().then();
      syncProducts().then();
      getActiveTimeEntry().then();
    } finally {
      isSyncRunning.value = false;
    }
  }

  return {
    syncAll,
  };
}
