import { useDialogStore } from "../../components/Dialog/dialogStore";
import ConfirmDialog from "../../components/Dialog/VTestConfirmDialog.vue";

// Define the unique ID generator
let uniqueIdCounter = 0;

function generateUniqueId(prefix = "id"): string {
  uniqueIdCounter += 1;
  return `${prefix}-${Date.now()}-${uniqueIdCounter}`;
}

export function useTestConfirmDialog() {
  const { showDialog, closeDialog } = useDialogStore();

  function require(options: {
    title: string
    message: string
    confirmText?: string
    cancelText?: string
    accept: () => void
    reject: () => void
    icon?: string
    type?: string
  }): Promise<boolean> {
    return new Promise((resolve) => {
      const dialogId = generateUniqueId("confirm-dialog");

      const componentProps = {
        title: options.title,
        description: options.message,
        confirmText: options.confirmText || "Confirm",
        cancelText: options.cancelText || "Cancel",
        icon: options.icon,
        type: options.type,
        size: "confirm",
        mask: true,
      };

      showDialog({
        id: dialogId,
        title: options.title,
        message: options.message,
        contentComponent: ConfirmDialog,
        componentProps,
        onConfirm: () => {
          options.accept();
          closeDialog(dialogId);
          resolve(true);
        },
        onCancel: () => {
          options.reject();
          closeDialog(dialogId);
          resolve(false);
        },
      });
    });
  }

  return { require };
}
