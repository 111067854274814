<script setup lang="ts">
import { watch } from "vue";
import { useCursor, usePaginate } from "../../composables";
import VPaginator from "../Paginator/VPaginator.vue";

interface Props {
  nextText?: string
  previousText?: string
}

defineProps<Props>();

const { currentPage, totalPages } = useCursor();
const { load, loading } = usePaginate();

watch(currentPage, () => load());
</script>

<template>
  <VPaginator
    v-model:page="currentPage"
    :total-pages="totalPages"
    :loading="loading"
    :next-text="nextText"
    :previous-text="previousText"
  />
</template>

<style scoped></style>
