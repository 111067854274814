import type { ActiveEnum } from "../active.enum";
import type { HydraContext } from "../hydra.interface";

export enum NotificationTypeEnum {
  /**
   * @deprecated
   */
  TYPE_TASK_CREATED = "task_created",
  /**
   * @deprecated
   */
  TYPE_TASK_STATUS_CHANGED = "task_status_changed",
  /**
   * @deprecated
   */
  TYPE_TASK_ASSIGNED = "task_assigned",
  /**
   * @deprecated
   */
  TYPE_TASK_UNASSIGNED = "task_unassigned",
  /**
   * @deprecated
   */
  TYPE_REPORT_CREATED = "report_created",
  /**
   * @deprecated
   */
  TYPE_COMMENT_CREATED = "comment_created",
  /**
   * @deprecated
   */
  TYPE_SECURITY_LOGIN = "security_login",

  TYPE_APP_UPDATE = "app_update",
  TYPE_SYNC_BEX = "sync_booking_experts",
  TYPE_SYNC_CAMPING_CARE = "sync_camping_care",
  TYPE_TASK = "task",
  TYPE_SECURITY = "security",
  TYPE_REPORT = "report",
}

export interface Notification {
  title: string
  type: NotificationTypeEnum
  readAt: string | null
  sentAt: string | null
  alert: boolean
  messageApp: string
  messageWeb: string
  location: string
  createdBy: string
  activity: string
  active: ActiveEnum
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  /**
   * @deprecated Use messageWeb or messageApp instead.
   */
  message?: string
  /**
   * Only when notification is received from Firebase or other third party.
   */
  link?: string
  /**
   * Only when notification is received from Firebase or other third party.
   */
  target?: string
  subject?: string | null
}

export interface NotificationHydraItem extends HydraContext {
  "@id": string
  "@type": string
  "title": string
  "type": NotificationTypeEnum
  "readAt": string | null
  "sentAt": string | null
  "alert": boolean
  "messageApp": string
  "messageWeb": string
  "location": string
  "createdBy": string
  "activity": string
  "active": ActiveEnum
  "id": number
  "createdAt": string
  "updatedAt": string
  "deletedAt": string | null
  "message"?: string
  "link"?: string
  "target"?: string
  "task"?: string | null
  "report"?: string | null
  "subject"?: string | null
}

export type NotificationHydraCollectionItem = Pick<NotificationHydraItem, "@id" | "@type" | "id" | "readAt" | "sentAt" | "deletedAt" | "alert" | "type" | "title" | "location" | "active" | "messageWeb" | "messageApp" | "activity" | "createdAt" | "task" | "report" | "subject">;

export interface NotificationWithSkeleton extends Notification {
  skeleton?: boolean
  id: number
  readAt: string | null
  title: string
  sentAt: string
  messageWeb: string
}
