<script setup lang="ts">
import type { VDropdownItemProps } from "./VDropdownItem.types";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

defineOptions({
  name: "VDropdownItem",
});

const {
  closeOnClick = true,
  icon = null,
  flag = null,
  text,
  disabledNotice = undefined,
  disabled = false,
  hasPerms = true,
  hide,
} = defineProps<VDropdownItemProps>();

const emit = defineEmits<{
  (e: "click", event: MouseEvent): void
}>();

const isDisabled = computed(() => {
  return disabled || !hasPerms;
});

function onClick(e: MouseEvent) {
  if (isDisabled.value) {
    e.preventDefault();
    e.stopPropagation();
    return;
  }

  if (closeOnClick && typeof hide === "function") {
    hide();
  }

  emit("click", e);
}

const { t } = useI18n();

const themeTooltip = computed(() => {
  if (disabled && disabledNotice && hasPerms) {
    return "verbleif-tooltip-gray";
  }
  return "verbleif-tooltip-ac";
});

const contentTooltip = computed(() => {
  if (disabled && disabledNotice && hasPerms) {
    return disabledNotice;
  }
  return t("permissions.ac.dropdown_item");
});

const disableTooltip = computed(() => {
  if (hasPerms && disabledNotice && disabled) {
    return false;
  }
  return hasPerms;
});
</script>

<template>
  <VTooltip
    :content="contentTooltip"
    placement="top"
    :theme="themeTooltip"
    :disabled="disableTooltip"
  >
    <template #reference>
      <div
        class="option"
        :class="[$attrs.class, { 'is-disabled': isDisabled }]"
        :aria-disabled="isDisabled"
        @click="onClick"
      >
        <div v-if="icon" class="icon">
          <slot name="icon">
            <FontAwesomeIcon v-if="icon" :icon="icon" />
          </slot>
        </div>
        <div v-if="flag" class="flag">
          <slot name="flag">
            <img :src="`/flags/${flag.toUpperCase()}.svg`" alt="flag">
          </slot>
        </div>
        <span class="text">{{ text }}</span>
      </div>
    </template>
  </VTooltip>
</template>

<style lang="scss" scoped>
:deep(.option) {
  display: flex;
  align-items: center;
  transition: none;
  color: var(--text);
  user-select: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;

  &:hover:not(.is-disabled) {
    @apply bg-gray-100 dark:bg-dark-800/70;
  }
  &:active:not(.is-disabled) {
    color: var(--primary-dark);
  }

  &.is-disabled {
    opacity: 0.25;
  }

  &.is-danger:not(.is-disabled) {
    color: theme("colors.danger.600");

    &:hover {
      color: theme("colors.danger.700");
    }
    &:active {
      color: theme("colors.danger.900");
    }
  }

  &.is-primary:not(.is-disabled) {
    color: var(--primary);

    &:hover {
      color: var(--primary-dark);
    }
    &:active {
      color: var(--primary-darker);
    }
  }

  &.is-warning:not(.is-disabled) {
    color: theme("colors.warning.600");

    &:hover {
      color: theme("colors.warning.700");
    }
    &:active {
      color: theme("colors.warning.900");
    }
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    transition: inherit;
  }

  .icon {
    width: 16px;
    font-size: 16px;
    margin-right: 10px;
    transition: inherit;
  }

  .flag {
    width: 24px;
    margin-right: 10px;
    transition: inherit;
  }
}
</style>
