<script setup lang="ts">
import type { SliderContext } from "./types";
import { computed, inject, ref } from "vue";

const slider = inject<SliderContext>("slider");
const isDragging = ref(false);
const thumbRef = ref<HTMLDivElement | null>(null);

const position = computed(() => {
  if (!slider) {
    return 0;
  }
  return ((slider.value.value - slider.min) / (slider.max - slider.min)) * 100;
});

function calculateNewValue(clientX: number, clientY: number, element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  const isVertical = slider?.orientation === "vertical";

  let percentage;
  if (isVertical) {
    percentage = ((rect.bottom - clientY) / rect.height) * 100;
  } else {
    percentage = ((clientX - rect.left) / rect.width) * 100;
  }

  percentage = Math.max(0, Math.min(100, percentage));

  if (!slider) {
    return 0;
  }

  const range = slider.max - slider.min;
  const rawValue = (percentage * range) / 100 + slider.min;
  return Math.round(rawValue / slider.step) * slider.step;
}

function onPointerDown(event: MouseEvent | TouchEvent) {
  if (!slider?.disabled && thumbRef.value?.parentElement) {
    event.preventDefault();
    event.stopPropagation();
    isDragging.value = true;

    const trackElement = thumbRef.value.parentElement;

    function moveHandler(e: MouseEvent | TouchEvent) {
      if (!isDragging.value || !slider) {
        return;
      }
      e.preventDefault();

      const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
      const clientY = "touches" in e ? e.touches[0].clientY : e.clientY;

      const newValue = calculateNewValue(clientX, clientY, trackElement as HTMLElement);
      if (newValue !== slider.value.value) {
        slider.updateValue(newValue);
      }
    }

    function upHandler() {
      isDragging.value = false;
      document.removeEventListener("mousemove", moveHandler);
      document.removeEventListener("touchmove", moveHandler);
      document.removeEventListener("mouseup", upHandler);
      document.removeEventListener("touchend", upHandler);
    }

    document.addEventListener("mousemove", moveHandler);
    document.addEventListener("touchmove", moveHandler);
    document.addEventListener("mouseup", upHandler);
    document.addEventListener("touchend", upHandler);

    const clientX = "touches" in event ? event.touches[0].clientX : event.clientX;
    const clientY = "touches" in event ? event.touches[0].clientY : event.clientY;
    const newValue = calculateNewValue(clientX, clientY, trackElement as HTMLElement);
    if (newValue !== slider?.value.value) {
      slider?.updateValue(newValue);
    }
  }
}

function onKeyDown(event: KeyboardEvent) {
  if (!slider || slider.disabled) {
    return;
  }

  const step = event.shiftKey ? slider.step * 10 : slider.step;
  let newValue = slider.value.value;

  switch (event.key) {
    case "ArrowRight":
    case "ArrowUp":
      newValue += step;
      break;
    case "ArrowLeft":
    case "ArrowDown":
      newValue -= step;
      break;
    case "Home":
      newValue = slider.min;
      break;
    case "End":
      newValue = slider.max;
      break;
    case "PageUp":
      newValue += step * 10;
      break;
    case "PageDown":
      newValue -= step * 10;
      break;
    default:
      return;
  }

  newValue = Math.max(slider.min, Math.min(slider.max, newValue));
  slider.updateValue(newValue);
  event.preventDefault();
}
</script>

<template>
  <div
    ref="thumbRef"
    role="slider"
    tabindex="0"
    :aria-valuemin="slider?.min"
    :aria-valuemax="slider?.max"
    :aria-valuenow="slider?.value.value"
    :aria-orientation="slider?.orientation"
    :aria-disabled="slider?.disabled"
    class="absolute w-3 h-3 -translate-x-1/2 -translate-y-1/2 touch-none select-none rounded-full shadow-lg"
    :class="[
      isDragging ? 'ring-2 ring-primary-600/30 scale-110' : 'hover:ring-2 hover:ring-primary-600/30 hover:scale-110',
      slider?.disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-white border-2 border-primary-600 cursor-grab active:cursor-grabbing',
    ]"
    :style="slider?.orientation === 'horizontal'
      ? { left: `${position}%`, top: '50%' }
      : { bottom: `${position}%`, left: '50%', transform: 'translate(-50%, 50%)' }"
    @mousedown="onPointerDown"
    @touchstart="onPointerDown"
    @keydown="onKeyDown"
  />
</template>
