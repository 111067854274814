export function useStringToHsl() {
  function stringToHslColor(str: string, s = 70, l = 60) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return `hsl(${h}, ${s}%, ${l}%)`;
  }

  return {
    stringToHslColor,
  };
}
export default useStringToHsl;
