import type { TaskHydraItem } from "@verbleif/lib";
import type { PermissionService } from "../PermissionService";
import type { TaskVoter } from "./TaskVoter";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class TaskTimeEntryVoter extends ContextVoter {
  public taskVoter: TaskVoter;

  constructor(permissionService: PermissionService, taskVoter: TaskVoter) {
    super(permissionService);
    this.taskVoter = taskVoter;
  }

  public canCreate = (task: TaskHydraItem | null): boolean => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationTasktimeentryCreateany,
    })) {
      return true;
    }

    return task ? this.taskVoter.hasAccessToAttributeViaTaskRelations(task, PermissionAttributesEnum.ContextOperationTasktimeentryCreate) : false;
  };

  public canRead = (): boolean => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationTasktimeentryReadany,
    })) {
      return true;
    }

    return false;
  };

  public canDelete = (task: TaskHydraItem | null): boolean => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationTasktimeentryDeleteany,
    })) {
      return true;
    }

    return task ? this.taskVoter.hasAccessToAttributeViaTaskRelations(task, PermissionAttributesEnum.ContextOperationTasktimeentryDelete) : false;
  };

  public canUpdate = (task: TaskHydraItem | null): boolean => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationTasktimeentryUpdateany,
    })) {
      return true;
    }

    return task ? this.taskVoter.hasAccessToAttributeViaTaskRelations(task, PermissionAttributesEnum.ContextOperationTasktimeentryUpdate) : false;
  };
}
