<script setup lang="ts">
import type { FieldSelectionItem, FieldSelectionItemValue, FieldSelectionProps } from "./VFieldSelection.interface";
import { ref, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = withDefaults(defineProps<FieldSelectionProps>(), {
  disabled: false,
  errorMessage: "",
  labelLeft: "",
  labelCenter: false,
  layout: "is-column",
  required: false,
  hasMargin: false,
  debug: false,
  placeholder: null,
  items: () => [],
  excludedItems: () => [],
  onLoad: null,
  loadParams: () => {
  },
});

const emit = defineEmits<{
  "update:modelValue": [FieldSelectionItemValue[]]
  "blur": [void]
  "onRightIconClick": [void]
}>();

const { t } = useI18n();

const { modelValue, initialValue } = toRefs(props);
const selectedItems = ref<FieldSelectionItemValue[]>
(Array.isArray(initialValue.value) ? initialValue.value : Array.isArray(modelValue.value) ? modelValue.value : []);

watch(() => selectedItems.value, (value, oldValue) => {
  if (value === oldValue) {
    return;
  }
  emit("update:modelValue", value);
}, { deep: true });

function toggleSelection(item: FieldSelectionItem) {
  const exists = selectedItems.value.includes(item.value);
  if (exists) {
    selectedItems.value = selectedItems.value.filter(i => i !== item.value);
  } else {
    selectedItems.value = [...selectedItems.value, item.value];
  }
}

function isSelected(item: FieldSelectionItem) {
  return selectedItems.value.includes(item.value);
}

function getTooltipContent(item: FieldSelectionItem) {
  return isSelected(item) ? t("tasks.hide_field") : t("tasks.show_field");
}
</script>

<template>
  <div class="field-wrapper">
    <div
      class="field-sub-wrapper"
      :class="[
        variant,
        layout,
        {
          'is-danger': errorMessage,
        },
      ]"
    >
      <label
        v-if="labelLeft"
        :class="{ 'w-1/3': layout === 'is-row' }"
        class="label"
      >
        {{ labelLeft }}
        <span v-if="required" class="required">*</span>
      </label>
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="{ selected: isSelected(item) }"
        class="field-items"
        @click="toggleSelection(item)"
      >
        <VTooltip :content="getTooltipContent(item)">
          <template #reference>
            <span>{{ item.label }}</span>
          </template>
        </VTooltip>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.field-wrapper {
  position: relative;

  .field-sub-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;

    &.is-row {
      flex-direction: row;
      align-items: center;

      .label {
        width: 33.33%;
      }

      &.has-label-left {
        padding-left: 15px;
      }
    }

    .label {
      display: inline-flex;
      font-size: 16px;
      color: var(--text);
      gap: 5px;

      .required {
        color: var(--secondary);
        font-weight: bold;
      }
    }

    .field-items {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border: 1px solid #ccc;
      color: var(--primary);
      border-radius: 0.25rem;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: fit-content;
      height: 40px;
      box-sizing: border-box;
    }

    .selected {
      background-color: var(--primary);
      color: var(--white);
    }

    &.is-gray {
      .field-items {
        color: var(--input-text);
      }
      .selected {
        background-color: var(--input-placeholder);
        color: var(--white);
      }
    }
    &.is-red {
      .field-items {
        color: var(--danger);
      }
      .selected {
        background-color: var(--danger);
        color: var(--white);
      }
    }
  }
}
</style>
