<script setup lang="ts">
import type { TabsContext } from "./types";
import { computed, inject, ref } from "vue";

interface Props {
  index: number
}

const {
  index = 0,
} = defineProps<Props>();

const tabs = inject<TabsContext>("tabs", {
  activeIndex: ref(0),
  tabItems: ref([]),
  registerTab: () => {},
  unregisterTab: () => {},
  onTabClick: () => {},
});

const isActive = computed(() => tabs.activeIndex.value === index);
const panelLazy = inject<boolean>("tabPanelLazy");
</script>

<template>
  <div
    v-if="!panelLazy"
    v-show="isActive"
    class="flex-1 min-h-0 flex flex-col h-full w-full"
    role="VTabPanel"
    :aria-hidden="!isActive"
  >
    <slot />
  </div>
  <div
    v-else-if="isActive"
    class="flex-1 min-h-0 flex flex-col h-full w-full"
    role="VTabPanel"
    :aria-hidden="!isActive"
  >
    <slot />
  </div>
</template>
