<script setup lang="ts">
import type { FormContext } from "vee-validate";
import type { InjectionKey } from "vue";
import { getDirtyInput } from "@web/features/Settings/Composables/yup.helpers";
import { FormContextKey, useSetFormValues } from "vee-validate";
import { computed, getCurrentInstance, inject } from "vue";

defineSlots<{
  customTab?: void
  customTabItems?: void
  customTabPanels?: void
}>();

const JsonEditorVue = defineAsyncComponent(() =>
  import("json-editor-vue"),
);

function injectWithSelf<T>(symbol: InjectionKey<T>, def: T | undefined = undefined): T | undefined {
  const vm = getCurrentInstance() as any;
  return vm?.provides[symbol as any] || inject(symbol, def);
}

const setFormValues = useSetFormValues();

const form = injectWithSelf(FormContextKey) as FormContext<any> | undefined;
if (!form) {
  console.warn("No form context found");
}

const other = computed(() => {
  const anyForm = form as any;
  return {
    keepValuesOnUnmount: anyForm?.keepValuesOnUnmount,
    submitCount: anyForm?.submitCount?.value,
    isSubmitting: anyForm?.isSubmitting?.value,
    isValidating: anyForm?.isValidating?.value,
    fieldArray: anyForm?.fieldArray,
    formId: anyForm?.formId,
  };
});

function updateFormValues(value: any) {
  try {
    const objectValue = typeof value === "string" ? JSON.parse(value) : value;
    setFormValues(objectValue);
  } catch {
    // Handle error silently
  }
}
const values = useFormValues<any>();

const dirtyFields = ref<Partial<any>>({});

watch(() => values.value, (v) => {
  if (form?.isFieldDirty) {
    dirtyFields.value = v ? getDirtyInput<any>(form?.isFieldDirty, v as any, false) : {};
  }
}, { deep: true, immediate: true });

enum Mode {
  text = "text",
  tree = "tree",
  table = "table",
}

const meta = computed(() => {
  const { initialValues: _, ...meta } = (form as any)?.meta?.value ?? {};
  return {
    ...meta,
  };
});
</script>

<template>
  <div class="h-[70vh]">
    <VTabs
      :active-index="0"
      lazy
      divider-between-tabs
      orientation="horizontal"
    >
      <VTabList>
        <VTabItem
          title="Values"
          :icon="['fad', 'database']"
          :index="0"
        />
        <VTabItem
          title="Dirty Fields"
          :icon="['fad', 'pen-to-square']"
          :index="1"
        />
        <VTabItem
          title="Errors"
          :icon="['fad', 'triangle-exclamation']"
          :index="2"
        />
        <VTabItem
          title="Meta"
          :icon="['fad', 'circle-info']"
          :index="3"
        />
        <VTabItem
          title="InitialValues"
          :icon="['fad', 'clock-rotate-left']"
          :index="4"
        />
        <VTabItem
          title="Other"
          :icon="['fad', 'ellipsis']"
          :index="5"
        />
        <VTabItem
          v-if="$slots.customTab"
          title="Custom"
          :icon="['fad', 'puzzle-piece']"
          :index="6"
        />
        <template v-if="$slots.customTabItems">
          <slot name="customTabItems" />
        </template>
      </VTabList>
      <VTabPanels>
        <VTabPanel :index="0">
          <div v-if="form" class="py-2">
            <JsonEditorVue
              :model-value="form?.values ?? {}"
              :mode="Mode.text"
              :debounce="0"
              @update:model-value="((value:any) => {
                updateFormValues(value)
              })"
            />
          </div>
          <div v-else>
            No form context found
          </div>
        </VTabPanel>
        <VTabPanel :index="1">
          <div v-if="form" class="py-2">
            <JsonEditorVue
              :model-value="dirtyFields"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
        </VTabPanel>
        <VTabPanel :index="2">
          <div v-if="form" class="py-2">
            <JsonEditorVue
              :model-value="form?.errors?.value ?? {}"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
        </VTabPanel>
        <VTabPanel :index="3">
          <div v-if="form" class="py-2">
            <JsonEditorVue
              :model-value="meta"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
        </VTabPanel>
        <VTabPanel :index="4">
          <div v-if="form" class="py-2">
            <JsonEditorVue
              :model-value="(form as any)?.initialValues?.value ?? {}"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
        </VTabPanel>
        <VTabPanel :index="5">
          <div v-if="form" class="py-2">
            <JsonEditorVue
              :model-value="other"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
        </VTabPanel>
        <VTabPanel v-if="$slots.customTab" :index="6">
          <slot name="customTab" />
        </VTabPanel>
        <template v-if="$slots.customTabPanels">
          <slot name="customTabPanels" />
        </template>
      </VTabPanels>
    </VTabs>
  </div>
</template>
