<script>
export default {
  name: "VLink",
  props: {
    to: {
      type: String,
      default: "",
    },
    text: {
      type: [Boolean, String],
      default: false,
    },
  },
};
</script>

<template>
  <div class="link-container">
    <a :href="to" v-bind="$attrs">
      {{ text || to }}
    </a>
  </div>
</template>

<style lang="scss" scoped>
.link-container {
  a {
    color: var(--primary);
    font-size: 12px;
    text-decoration: none;
    transition: all ease 100ms;
    &:hover {
      color: var(--primary-dark);
    }
    &:active {
      color: var(--primary-darker);
    }
  }
}
</style>
