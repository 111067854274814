import { isAxiosError } from "axios";
import { useI18n } from "vue-i18n";
import { toast } from "vue-sonner";

export function useServerErrorHandler() {
  const { te, t } = useI18n();
  function onError(error: any, violationsPath: string = "violations.", hideToast: boolean = false) {
    let detail = t("base.unknown_error");
    if (
      isAxiosError(error)
      && error.response?.data?.detail
    ) {
      detail = error.response.data.detail;
    }

    if (
      isAxiosError(error)
      && error.response?.data?.violations
    ) {
      let violationsDetails = "";
      for (const violation of error.response.data.violations) {
        const numbers: number[] = [];
        const output = violation.propertyPath.replace(/\[(\d+)\]/g, (_: any, p1: string) => {
          numbers.push(Number(p1));
          return "";
        });
        const firstNumber = numbers ? numbers[0] ?? 0 : 0;
        const propertyPathSplided = output.split(".");
        let propertyPath = "";
        let lastKey = "";
        propertyPathSplided.forEach((path: string, index: number) => {
          const key = t(`${violationsPath}.${lastKey + path}._label`, { count: firstNumber });
          const computedKey = key.includes("_label") ? path : key;
          propertyPath += `${computedKey}${index === propertyPathSplided.length - 1 ? "" : "->"}`;
          lastKey = `${lastKey + path}.`;
        });
        violationsDetails += `[${propertyPath}]: ${violation.message}\n`;
      }
      detail = violationsDetails;
    }

    if (
      detail === t("base.unknown_error")
      && isAxiosError(error)
      && error.response?.status
    ) {
      const translationsExists = te(`base.${error.response.status}`);
      detail = translationsExists ? t(`base.${error.response.status}`) : t("base.unknown_code", { code: error.response.status });
    }

    if (!hideToast) {
      toast.error(detail, {
        duration: 3000,
      });
    }

    return detail;
  }
  return {
    onError,
  };
}
