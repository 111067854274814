import type { Component, defineComponent, Ref } from "vue";
import { inject, provide, reactive, ref, shallowRef, watch } from "vue";

const ModalKey = Symbol("Modal");

export interface ModalOptions {
  title?: string | null
  okIcon?: string | ["fas" | "far" | "fad" | "fal", string] | undefined
  okText: string
  okVariant: string
  cancelIcon?: string | ["fas" | "far" | "fad" | "fal", string] | undefined
  cancelText: string
  cancelVariant: string
  variant?: string
  onOk: (input: any) => (void | Promise<void>)
  onCancel: () => (void | Promise<void>)
  loading: Ref<boolean>
}

export interface ModalOpenFunction {
  componentKey?: string
  component?: Promise<ReturnType<typeof defineComponent>>
  componentProps?: Record<string, any>
  options?: Partial<ModalOptions>
  async?: boolean
}

function createModal() {
  const loading = ref(false);
  const visible = ref(false);
  const componentKey = ref<string | null>(null);
  const componentInst = shallowRef<Component | null>(null);

  const initialOptions: ModalOptions = {
    title: "",
    okIcon: ["fas", "user-slash"],
    cancelIcon: undefined,
    okText: "Ok",
    cancelText: "Cancel",
    okVariant: "is-danger",
    cancelVariant: "is-light",
    onOk: () => { },
    onCancel: () => close(),
    loading,
  };

  const options = reactive<ModalOptions>({ ...initialOptions });
  const componentProps = ref<Record<string, any> | null>(null);

  function setLoading(v: boolean) {
    loading.value = v;
  }

  async function open(inputOptions: ModalOpenFunction): Promise<void> {
    if (inputOptions.options) {
      Object.assign(options, inputOptions.options);
    }
    if (inputOptions.componentProps) {
      componentProps.value = inputOptions.componentProps;
    }

    if (inputOptions.componentKey) {
      componentKey.value = inputOptions.componentKey;
    }

    if (inputOptions.component) {
      componentInst.value = (await inputOptions.component).default;
    }

    visible.value = true;

    if (inputOptions.async) {
      return new Promise((resolve, reject) => {
        options.onOk = () => resolve();
        options.onCancel = () => reject(new Error("User canceled"));
      });
    }
  }

  watch(visible, (v) => {
    if (v) {
      return;
    }
    componentProps.value = null;
    componentInst.value = {};
  });

  function setTitle(title: string) {
    options.title = title;
  }

  function close() {
    visible.value = false;
  }

  return {
    open,
    close,
    options,
    visible,
    setLoading,
    loading,
    componentInst,
    setTitle,
    componentProps,
    componentKey,
  };
}

export function provideModal() {
  const modal = createModal();
  provide(ModalKey, modal);
  return modal;
}

export type ModalManager = ReturnType<typeof createModal>;
export function useModal() {
  const modal = inject<ModalManager>(ModalKey);

  if (!modal) {
    throw new Error("Run provideModal before useModal");
  }

  return modal;
}
