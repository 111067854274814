<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { VModernSelect } from "../AutoComplete";

// Define proper types for the props
interface Props {
  size?: "medium" | "large"
  variant?: "is-gray" | "is-white"
  labelLeft?: string
  required?: boolean
  iconLeft?: string | [("fas" | "far" | "fad" | "fal"), string]
  errorMessage?: string
}

// Remove 'props' variable since it's not used
defineProps<Props>();

const timezone = defineModel<string>({ required: true });
const { t } = useI18n();

const timezones = [
  {
    value: "Africa/Abidjan",
    label: "Abidjan (Africa)",
  },
  {
    value: "Africa/Accra",
    label: "Accra (Africa)",
  },
  {
    value: "America/Adak",
    label: "Adak (America)",
  },
  {
    value: "Africa/Addis_Ababa",
    label: "Addis Ababa (Africa)",
  },
  {
    value: "Australia/Adelaide",
    label: "Adelaide (Australia)",
  },
  {
    value: "Asia/Aden",
    label: "Aden (Asia)",
  },
  {
    value: "Africa/Algiers",
    label: "Algiers (Africa)",
  },
  {
    value: "Asia/Almaty",
    label: "Almaty (Asia)",
  },
  {
    value: "Asia/Amman",
    label: "Amman (Asia)",
  },
  {
    value: "Europe/Amsterdam",
    label: "Amsterdam (Europe)",
  },
  {
    value: "Asia/Anadyr",
    label: "Anadyr (Asia)",
  },
  {
    value: "America/Anchorage",
    label: "Anchorage (America)",
  },
  {
    value: "Europe/Andorra",
    label: "Andorra (Europe)",
  },
  {
    value: "America/Anguilla",
    label: "Anguilla (America)",
  },
  {
    value: "Indian/Antananarivo",
    label: "Antananarivo (Indian)",
  },
  {
    value: "America/Antigua",
    label: "Antigua (America)",
  },
  {
    value: "Pacific/Apia",
    label: "Apia (Pacific)",
  },
  {
    value: "Asia/Aqtau",
    label: "Aqtau (Asia)",
  },
  {
    value: "Asia/Aqtobe",
    label: "Aqtobe (Asia)",
  },
  {
    value: "America/Araguaina",
    label: "Araguaina (America)",
  },
  {
    value: "America/Aruba",
    label: "Aruba (America)",
  },
  {
    value: "Asia/Ashgabat",
    label: "Ashgabat (Asia)",
  },
  {
    value: "Africa/Asmara",
    label: "Asmara (Africa)",
  },
  {
    value: "Europe/Astrakhan",
    label: "Astrakhan (Europe)",
  },
  {
    value: "America/Asuncion",
    label: "Asuncion (America)",
  },
  {
    value: "Europe/Athens",
    label: "Athens (Europe)",
  },
  {
    value: "America/Atikokan",
    label: "Atikokan (America)",
  },
  {
    value: "Asia/Atyrau",
    label: "Atyrau (Asia)",
  },
  {
    value: "Pacific/Auckland",
    label: "Auckland (Pacific)",
  },
  {
    value: "Atlantic/Azores",
    label: "Azores (Atlantic)",
  },
  {
    value: "Asia/Baghdad",
    label: "Baghdad (Asia)",
  },
  {
    value: "America/Bahia",
    label: "Bahia (America)",
  },
  {
    value: "America/Bahia_Banderas",
    label: "Bahia Banderas (America)",
  },
  {
    value: "Asia/Bahrain",
    label: "Bahrain (Asia)",
  },
  {
    value: "Asia/Baku",
    label: "Baku (Asia)",
  },
  {
    value: "Africa/Bamako",
    label: "Bamako (Africa)",
  },
  {
    value: "Asia/Bangkok",
    label: "Bangkok (Asia)",
  },
  {
    value: "Africa/Bangui",
    label: "Bangui (Africa)",
  },
  {
    value: "Africa/Banjul",
    label: "Banjul (Africa)",
  },
  {
    value: "America/Barbados",
    label: "Barbados (America)",
  },
  {
    value: "Asia/Barnaul",
    label: "Barnaul (Asia)",
  },
  {
    value: "Asia/Beirut",
    label: "Beirut (Asia)",
  },
  {
    value: "America/Belem",
    label: "Belem (America)",
  },
  {
    value: "Europe/Belgrade",
    label: "Belgrade (Europe)",
  },
  {
    value: "America/Belize",
    label: "Belize (America)",
  },
  {
    value: "Europe/Berlin",
    label: "Berlin (Europe)",
  },
  {
    value: "Atlantic/Bermuda",
    label: "Bermuda (Atlantic)",
  },
  {
    value: "America/North_Dakota/Beulah",
    label: "Beulah (America)",
  },
  {
    value: "Asia/Bishkek",
    label: "Bishkek (Asia)",
  },
  {
    value: "Africa/Bissau",
    label: "Bissau (Africa)",
  },
  {
    value: "America/Blanc-Sablon",
    label: "Blanc-Sablon (America)",
  },
  {
    value: "Africa/Blantyre",
    label: "Blantyre (Africa)",
  },
  {
    value: "America/Boa_Vista",
    label: "Boa Vista (America)",
  },
  {
    value: "America/Bogota",
    label: "Bogota (America)",
  },
  {
    value: "America/Boise",
    label: "Boise (America)",
  },
  {
    value: "Pacific/Bougainville",
    label: "Bougainville (Pacific)",
  },
  {
    value: "Europe/Bratislava",
    label: "Bratislava (Europe)",
  },
  {
    value: "Africa/Brazzaville",
    label: "Brazzaville (Africa)",
  },
  {
    value: "Australia/Brisbane",
    label: "Brisbane (Australia)",
  },
  {
    value: "Australia/Broken_Hill",
    label: "Broken Hill (Australia)",
  },
  {
    value: "Asia/Brunei",
    label: "Brunei (Asia)",
  },
  {
    value: "Europe/Brussels",
    label: "Brussels (Europe)",
  },
  {
    value: "Europe/Bucharest",
    label: "Bucharest (Europe)",
  },
  {
    value: "Europe/Budapest",
    label: "Budapest (Europe)",
  },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "Buenos Aires (America)",
  },
  {
    value: "Africa/Bujumbura",
    label: "Bujumbura (Africa)",
  },
  {
    value: "Europe/Busingen",
    label: "Busingen (Europe)",
  },
  {
    value: "Africa/Cairo",
    label: "Cairo (Africa)",
  },
  {
    value: "America/Cambridge_Bay",
    label: "Cambridge Bay (America)",
  },
  {
    value: "America/Campo_Grande",
    label: "Campo Grande (America)",
  },
  {
    value: "Atlantic/Canary",
    label: "Canary (Atlantic)",
  },
  {
    value: "America/Cancun",
    label: "Cancun (America)",
  },
  {
    value: "Atlantic/Cape_Verde",
    label: "Cape Verde (Atlantic)",
  },
  {
    value: "America/Caracas",
    label: "Caracas (America)",
  },
  {
    value: "Africa/Casablanca",
    label: "Casablanca (Africa)",
  },
  {
    value: "Antarctica/Casey",
    label: "Casey (Antarctica)",
  },
  {
    value: "America/Argentina/Catamarca",
    label: "Catamarca (America)",
  },
  {
    value: "America/Cayenne",
    label: "Cayenne (America)",
  },
  {
    value: "America/Cayman",
    label: "Cayman (America)",
  },
  {
    value: "America/North_Dakota/Center",
    label: "Center (America)",
  },
  {
    value: "Africa/Ceuta",
    label: "Ceuta (Africa)",
  },
  {
    value: "Indian/Chagos",
    label: "Chagos (Indian)",
  },
  {
    value: "Pacific/Chatham",
    label: "Chatham (Pacific)",
  },
  {
    value: "America/Chicago",
    label: "Chicago (America)",
  },
  {
    value: "America/Chihuahua",
    label: "Chihuahua (America)",
  },
  {
    value: "Europe/Chisinau",
    label: "Chisinau (Europe)",
  },
  {
    value: "Asia/Chita",
    label: "Chita (Asia)",
  },
  {
    value: "Asia/Choibalsan",
    label: "Choibalsan (Asia)",
  },
  {
    value: "Indian/Christmas",
    label: "Christmas (Indian)",
  },
  {
    value: "Pacific/Chuuk",
    label: "Chuuk (Pacific)",
  },
  {
    value: "America/Ciudad_Juarez",
    label: "Ciudad Juarez (America)",
  },
  {
    value: "Indian/Cocos",
    label: "Cocos (Indian)",
  },
  {
    value: "Asia/Colombo",
    label: "Colombo (Asia)",
  },
  {
    value: "Indian/Comoro",
    label: "Comoro (Indian)",
  },
  {
    value: "Africa/Conakry",
    label: "Conakry (Africa)",
  },
  {
    value: "Europe/Copenhagen",
    label: "Copenhagen (Europe)",
  },
  {
    value: "America/Argentina/Cordoba",
    label: "Cordoba (America)",
  },
  {
    value: "America/Costa_Rica",
    label: "Costa Rica (America)",
  },
  {
    value: "America/Creston",
    label: "Creston (America)",
  },
  {
    value: "America/Cuiaba",
    label: "Cuiaba (America)",
  },
  {
    value: "America/Curacao",
    label: "Curacao (America)",
  },
  {
    value: "Africa/Dakar",
    label: "Dakar (Africa)",
  },
  {
    value: "Asia/Damascus",
    label: "Damascus (Asia)",
  },
  {
    value: "America/Danmarkshavn",
    label: "Danmarkshavn (America)",
  },
  {
    value: "Africa/Dar_es_Salaam",
    label: "Dar es Salaam (Africa)",
  },
  {
    value: "Australia/Darwin",
    label: "Darwin (Australia)",
  },
  {
    value: "Antarctica/Davis",
    label: "Davis (Antarctica)",
  },
  {
    value: "America/Dawson",
    label: "Dawson (America)",
  },
  {
    value: "America/Dawson_Creek",
    label: "Dawson Creek (America)",
  },
  {
    value: "America/Denver",
    label: "Denver (America)",
  },
  {
    value: "America/Detroit",
    label: "Detroit (America)",
  },
  {
    value: "Asia/Dhaka",
    label: "Dhaka (Asia)",
  },
  {
    value: "Asia/Dili",
    label: "Dili (Asia)",
  },
  {
    value: "Africa/Djibouti",
    label: "Djibouti (Africa)",
  },
  {
    value: "America/Dominica",
    label: "Dominica (America)",
  },
  {
    value: "Africa/Douala",
    label: "Douala (Africa)",
  },
  {
    value: "Asia/Dubai",
    label: "Dubai (Asia)",
  },
  {
    value: "Europe/Dublin",
    label: "Dublin (Europe)",
  },
  {
    value: "Antarctica/DumontDUrville",
    label: "DumontDUrville (Antarctica)",
  },
  {
    value: "Asia/Dushanbe",
    label: "Dushanbe (Asia)",
  },
  {
    value: "Pacific/Easter",
    label: "Easter (Pacific)",
  },
  {
    value: "America/Edmonton",
    label: "Edmonton (America)",
  },
  {
    value: "Pacific/Efate",
    label: "Efate (Pacific)",
  },
  {
    value: "America/Eirunepe",
    label: "Eirunepe (America)",
  },
  {
    value: "Africa/El_Aaiun",
    label: "El Aaiun (Africa)",
  },
  {
    value: "America/El_Salvador",
    label: "El Salvador (America)",
  },
  {
    value: "Australia/Eucla",
    label: "Eucla (Australia)",
  },
  {
    value: "Pacific/Fakaofo",
    label: "Fakaofo (Pacific)",
  },
  {
    value: "Asia/Famagusta",
    label: "Famagusta (Asia)",
  },
  {
    value: "Atlantic/Faroe",
    label: "Faroe (Atlantic)",
  },
  {
    value: "Pacific/Fiji",
    label: "Fiji (Pacific)",
  },
  {
    value: "America/Fort_Nelson",
    label: "Fort Nelson (America)",
  },
  {
    value: "America/Fortaleza",
    label: "Fortaleza (America)",
  },
  {
    value: "Africa/Freetown",
    label: "Freetown (Africa)",
  },
  {
    value: "Pacific/Funafuti",
    label: "Funafuti (Pacific)",
  },
  {
    value: "Africa/Gaborone",
    label: "Gaborone (Africa)",
  },
  {
    value: "Pacific/Galapagos",
    label: "Galapagos (Pacific)",
  },
  {
    value: "Pacific/Gambier",
    label: "Gambier (Pacific)",
  },
  {
    value: "Asia/Gaza",
    label: "Gaza (Asia)",
  },
  {
    value: "Europe/Gibraltar",
    label: "Gibraltar (Europe)",
  },
  {
    value: "America/Glace_Bay",
    label: "Glace Bay (America)",
  },
  {
    value: "America/Goose_Bay",
    label: "Goose Bay (America)",
  },
  {
    value: "America/Grand_Turk",
    label: "Grand Turk (America)",
  },
  {
    value: "America/Grenada",
    label: "Grenada (America)",
  },
  {
    value: "Pacific/Guadalcanal",
    label: "Guadalcanal (Pacific)",
  },
  {
    value: "America/Guadeloupe",
    label: "Guadeloupe (America)",
  },
  {
    value: "Pacific/Guam",
    label: "Guam (Pacific)",
  },
  {
    value: "America/Guatemala",
    label: "Guatemala (America)",
  },
  {
    value: "America/Guayaquil",
    label: "Guayaquil (America)",
  },
  {
    value: "Europe/Guernsey",
    label: "Guernsey (Europe)",
  },
  {
    value: "America/Guyana",
    label: "Guyana (America)",
  },
  {
    value: "America/Halifax",
    label: "Halifax (America)",
  },
  {
    value: "Africa/Harare",
    label: "Harare (Africa)",
  },
  {
    value: "America/Havana",
    label: "Havana (America)",
  },
  {
    value: "Asia/Hebron",
    label: "Hebron (Asia)",
  },
  {
    value: "Europe/Helsinki",
    label: "Helsinki (Europe)",
  },
  {
    value: "America/Hermosillo",
    label: "Hermosillo (America)",
  },
  {
    value: "Asia/Ho_Chi_Minh",
    label: "Ho Chi Minh (Asia)",
  },
  {
    value: "Australia/Hobart",
    label: "Hobart (Australia)",
  },
  {
    value: "Asia/Hong_Kong",
    label: "Hong Kong (Asia)",
  },
  {
    value: "Pacific/Honolulu",
    label: "Honolulu (Pacific)",
  },
  {
    value: "Asia/Hovd",
    label: "Hovd (Asia)",
  },
  {
    value: "America/Indiana/Indianapolis",
    label: "Indianapolis (America)",
  },
  {
    value: "America/Inuvik",
    label: "Inuvik (America)",
  },
  {
    value: "America/Iqaluit",
    label: "Iqaluit (America)",
  },
  {
    value: "Asia/Irkutsk",
    label: "Irkutsk (Asia)",
  },
  {
    value: "Europe/Isle_of_Man",
    label: "Isle of Man (Europe)",
  },
  {
    value: "Europe/Istanbul",
    label: "Istanbul (Europe)",
  },
  {
    value: "Asia/Jakarta",
    label: "Jakarta (Asia)",
  },
  {
    value: "America/Jamaica",
    label: "Jamaica (America)",
  },
  {
    value: "Asia/Jayapura",
    label: "Jayapura (Asia)",
  },
  {
    value: "Europe/Jersey",
    label: "Jersey (Europe)",
  },
  {
    value: "Asia/Jerusalem",
    label: "Jerusalem (Asia)",
  },
  {
    value: "Africa/Johannesburg",
    label: "Johannesburg (Africa)",
  },
  {
    value: "Africa/Juba",
    label: "Juba (Africa)",
  },
  {
    value: "America/Argentina/Jujuy",
    label: "Jujuy (America)",
  },
  {
    value: "America/Juneau",
    label: "Juneau (America)",
  },
  {
    value: "Asia/Kabul",
    label: "Kabul (Asia)",
  },
  {
    value: "Europe/Kaliningrad",
    label: "Kaliningrad (Europe)",
  },
  {
    value: "Asia/Kamchatka",
    label: "Kamchatka (Asia)",
  },
  {
    value: "Africa/Kampala",
    label: "Kampala (Africa)",
  },
  {
    value: "Pacific/Kanton",
    label: "Kanton (Pacific)",
  },
  {
    value: "Asia/Karachi",
    label: "Karachi (Asia)",
  },
  {
    value: "Asia/Kathmandu",
    label: "Kathmandu (Asia)",
  },
  {
    value: "Indian/Kerguelen",
    label: "Kerguelen (Indian)",
  },
  {
    value: "Asia/Khandyga",
    label: "Khandyga (Asia)",
  },
  {
    value: "Africa/Khartoum",
    label: "Khartoum (Africa)",
  },
  {
    value: "Africa/Kigali",
    label: "Kigali (Africa)",
  },
  {
    value: "Africa/Kinshasa",
    label: "Kinshasa (Africa)",
  },
  {
    value: "Pacific/Kiritimati",
    label: "Kiritimati (Pacific)",
  },
  {
    value: "Europe/Kirov",
    label: "Kirov (Europe)",
  },
  {
    value: "America/Indiana/Knox",
    label: "Knox (America)",
  },
  {
    value: "Asia/Kolkata",
    label: "Kolkata (Asia)",
  },
  {
    value: "Pacific/Kosrae",
    label: "Kosrae (Pacific)",
  },
  {
    value: "America/Kralendijk",
    label: "Kralendijk (America)",
  },
  {
    value: "Asia/Krasnoyarsk",
    label: "Krasnoyarsk (Asia)",
  },
  {
    value: "Asia/Kuala_Lumpur",
    label: "Kuala Lumpur (Asia)",
  },
  {
    value: "Asia/Kuching",
    label: "Kuching (Asia)",
  },
  {
    value: "Asia/Kuwait",
    label: "Kuwait (Asia)",
  },
  {
    value: "Pacific/Kwajalein",
    label: "Kwajalein (Pacific)",
  },
  {
    value: "Europe/Kyiv",
    label: "Kyiv (Europe)",
  },
  {
    value: "America/La_Paz",
    label: "La Paz (America)",
  },
  {
    value: "America/Argentina/La_Rioja",
    label: "La Rioja (America)",
  },
  {
    value: "Africa/Lagos",
    label: "Lagos (Africa)",
  },
  {
    value: "Africa/Libreville",
    label: "Libreville (Africa)",
  },
  {
    value: "America/Lima",
    label: "Lima (America)",
  },
  {
    value: "Australia/Lindeman",
    label: "Lindeman (Australia)",
  },
  {
    value: "Europe/Lisbon",
    label: "Lisbon (Europe)",
  },
  {
    value: "Europe/Ljubljana",
    label: "Ljubljana (Europe)",
  },
  {
    value: "Africa/Lome",
    label: "Lome (Africa)",
  },
  {
    value: "Europe/London",
    label: "London (Europe)",
  },
  {
    value: "Arctic/Longyearbyen",
    label: "Longyearbyen (Arctic)",
  },
  {
    value: "Australia/Lord_Howe",
    label: "Lord Howe (Australia)",
  },
  {
    value: "America/Los_Angeles",
    label: "Los Angeles (America)",
  },
  {
    value: "America/Kentucky/Louisville",
    label: "Louisville (America)",
  },
  {
    value: "America/Lower_Princes",
    label: "Lower Princes (America)",
  },
  {
    value: "Africa/Luanda",
    label: "Luanda (Africa)",
  },
  {
    value: "Africa/Lubumbashi",
    label: "Lubumbashi (Africa)",
  },
  {
    value: "Africa/Lusaka",
    label: "Lusaka (Africa)",
  },
  {
    value: "Europe/Luxembourg",
    label: "Luxembourg (Europe)",
  },
  {
    value: "Asia/Macau",
    label: "Macau (Asia)",
  },
  {
    value: "America/Maceio",
    label: "Maceio (America)",
  },
  {
    value: "Antarctica/Macquarie",
    label: "Macquarie (Antarctica)",
  },
  {
    value: "Atlantic/Madeira",
    label: "Madeira (Atlantic)",
  },
  {
    value: "Europe/Madrid",
    label: "Madrid (Europe)",
  },
  {
    value: "Asia/Magadan",
    label: "Magadan (Asia)",
  },
  {
    value: "Indian/Mahe",
    label: "Mahe (Indian)",
  },
  {
    value: "Pacific/Majuro",
    label: "Majuro (Pacific)",
  },
  {
    value: "Asia/Makassar",
    label: "Makassar (Asia)",
  },
  {
    value: "Africa/Malabo",
    label: "Malabo (Africa)",
  },
  {
    value: "Indian/Maldives",
    label: "Maldives (Indian)",
  },
  {
    value: "Europe/Malta",
    label: "Malta (Europe)",
  },
  {
    value: "America/Managua",
    label: "Managua (America)",
  },
  {
    value: "America/Manaus",
    label: "Manaus (America)",
  },
  {
    value: "Asia/Manila",
    label: "Manila (Asia)",
  },
  {
    value: "Africa/Maputo",
    label: "Maputo (Africa)",
  },
  {
    value: "America/Indiana/Marengo",
    label: "Marengo (America)",
  },
  {
    value: "Europe/Mariehamn",
    label: "Mariehamn (Europe)",
  },
  {
    value: "America/Marigot",
    label: "Marigot (America)",
  },
  {
    value: "Pacific/Marquesas",
    label: "Marquesas (Pacific)",
  },
  {
    value: "America/Martinique",
    label: "Martinique (America)",
  },
  {
    value: "Africa/Maseru",
    label: "Maseru (Africa)",
  },
  {
    value: "America/Matamoros",
    label: "Matamoros (America)",
  },
  {
    value: "Indian/Mauritius",
    label: "Mauritius (Indian)",
  },
  {
    value: "Antarctica/Mawson",
    label: "Mawson (Antarctica)",
  },
  {
    value: "Indian/Mayotte",
    label: "Mayotte (Indian)",
  },
  {
    value: "America/Mazatlan",
    label: "Mazatlan (America)",
  },
  {
    value: "Africa/Mbabane",
    label: "Mbabane (Africa)",
  },
  {
    value: "Antarctica/McMurdo",
    label: "McMurdo (Antarctica)",
  },
  {
    value: "Australia/Melbourne",
    label: "Melbourne (Australia)",
  },
  {
    value: "America/Argentina/Mendoza",
    label: "Mendoza (America)",
  },
  {
    value: "America/Menominee",
    label: "Menominee (America)",
  },
  {
    value: "America/Merida",
    label: "Merida (America)",
  },
  {
    value: "America/Metlakatla",
    label: "Metlakatla (America)",
  },
  {
    value: "America/Mexico_City",
    label: "Mexico City (America)",
  },
  {
    value: "Pacific/Midway",
    label: "Midway (Pacific)",
  },
  {
    value: "Europe/Minsk",
    label: "Minsk (Europe)",
  },
  {
    value: "America/Miquelon",
    label: "Miquelon (America)",
  },
  {
    value: "Africa/Mogadishu",
    label: "Mogadishu (Africa)",
  },
  {
    value: "Europe/Monaco",
    label: "Monaco (Europe)",
  },
  {
    value: "America/Moncton",
    label: "Moncton (America)",
  },
  {
    value: "Africa/Monrovia",
    label: "Monrovia (Africa)",
  },
  {
    value: "America/Monterrey",
    label: "Monterrey (America)",
  },
  {
    value: "America/Montevideo",
    label: "Montevideo (America)",
  },
  {
    value: "America/Kentucky/Monticello",
    label: "Monticello (America)",
  },
  {
    value: "America/Montserrat",
    label: "Montserrat (America)",
  },
  {
    value: "Europe/Moscow",
    label: "Moscow (Europe)",
  },
  {
    value: "Asia/Muscat",
    label: "Muscat (Asia)",
  },
  {
    value: "Africa/Nairobi",
    label: "Nairobi (Africa)",
  },
  {
    value: "America/Nassau",
    label: "Nassau (America)",
  },
  {
    value: "Pacific/Nauru",
    label: "Nauru (Pacific)",
  },
  {
    value: "Africa/Ndjamena",
    label: "Ndjamena (Africa)",
  },
  {
    value: "America/North_Dakota/New_Salem",
    label: "New Salem (America)",
  },
  {
    value: "America/New_York",
    label: "New York (America)",
  },
  {
    value: "Africa/Niamey",
    label: "Niamey (Africa)",
  },
  {
    value: "Asia/Nicosia",
    label: "Nicosia (Asia)",
  },
  {
    value: "Pacific/Niue",
    label: "Niue (Pacific)",
  },
  {
    value: "America/Nome",
    label: "Nome (America)",
  },
  {
    value: "Pacific/Norfolk",
    label: "Norfolk (Pacific)",
  },
  {
    value: "America/Noronha",
    label: "Noronha (America)",
  },
  {
    value: "Africa/Nouakchott",
    label: "Nouakchott (Africa)",
  },
  {
    value: "Pacific/Noumea",
    label: "Noumea (Pacific)",
  },
  {
    value: "Asia/Novokuznetsk",
    label: "Novokuznetsk (Asia)",
  },
  {
    value: "Asia/Novosibirsk",
    label: "Novosibirsk (Asia)",
  },
  {
    value: "America/Nuuk",
    label: "Nuuk (America)",
  },
  {
    value: "America/Ojinaga",
    label: "Ojinaga (America)",
  },
  {
    value: "Asia/Omsk",
    label: "Omsk (Asia)",
  },
  {
    value: "Asia/Oral",
    label: "Oral (Asia)",
  },
  {
    value: "Europe/Oslo",
    label: "Oslo (Europe)",
  },
  {
    value: "Africa/Ouagadougou",
    label: "Ouagadougou (Africa)",
  },
  {
    value: "Pacific/Pago_Pago",
    label: "Pago Pago (Pacific)",
  },
  {
    value: "Pacific/Palau",
    label: "Palau (Pacific)",
  },
  {
    value: "Antarctica/Palmer",
    label: "Palmer (Antarctica)",
  },
  {
    value: "America/Panama",
    label: "Panama (America)",
  },
  {
    value: "America/Paramaribo",
    label: "Paramaribo (America)",
  },
  {
    value: "Europe/Paris",
    label: "Paris (Europe)",
  },
  {
    value: "Australia/Perth",
    label: "Perth (Australia)",
  },
  {
    value: "America/Indiana/Petersburg",
    label: "Petersburg (America)",
  },
  {
    value: "Asia/Phnom_Penh",
    label: "Phnom Penh (Asia)",
  },
  {
    value: "America/Phoenix",
    label: "Phoenix (America)",
  },
  {
    value: "Pacific/Pitcairn",
    label: "Pitcairn (Pacific)",
  },
  {
    value: "Europe/Podgorica",
    label: "Podgorica (Europe)",
  },
  {
    value: "Pacific/Pohnpei",
    label: "Pohnpei (Pacific)",
  },
  {
    value: "Asia/Pontianak",
    label: "Pontianak (Asia)",
  },
  {
    value: "Pacific/Port_Moresby",
    label: "Port Moresby (Pacific)",
  },
  {
    value: "America/Port_of_Spain",
    label: "Port of Spain (America)",
  },
  {
    value: "America/Port-au-Prince",
    label: "Port-au-Prince (America)",
  },
  {
    value: "America/Porto_Velho",
    label: "Porto Velho (America)",
  },
  {
    value: "Africa/Porto-Novo",
    label: "Porto-Novo (Africa)",
  },
  {
    value: "Europe/Prague",
    label: "Prague (Europe)",
  },
  {
    value: "America/Puerto_Rico",
    label: "Puerto Rico (America)",
  },
  {
    value: "America/Punta_Arenas",
    label: "Punta Arenas (America)",
  },
  {
    value: "Asia/Pyongyang",
    label: "Pyongyang (Asia)",
  },
  {
    value: "Asia/Qatar",
    label: "Qatar (Asia)",
  },
  {
    value: "Asia/Qostanay",
    label: "Qostanay (Asia)",
  },
  {
    value: "Asia/Qyzylorda",
    label: "Qyzylorda (Asia)",
  },
  {
    value: "America/Rankin_Inlet",
    label: "Rankin Inlet (America)",
  },
  {
    value: "Pacific/Rarotonga",
    label: "Rarotonga (Pacific)",
  },
  {
    value: "America/Recife",
    label: "Recife (America)",
  },
  {
    value: "America/Regina",
    label: "Regina (America)",
  },
  {
    value: "America/Resolute",
    label: "Resolute (America)",
  },
  {
    value: "Indian/Reunion",
    label: "Reunion (Indian)",
  },
  {
    value: "Atlantic/Reykjavik",
    label: "Reykjavik (Atlantic)",
  },
  {
    value: "Europe/Riga",
    label: "Riga (Europe)",
  },
  {
    value: "America/Rio_Branco",
    label: "Rio Branco (America)",
  },
  {
    value: "America/Argentina/Rio_Gallegos",
    label: "Rio Gallegos (America)",
  },
  {
    value: "Asia/Riyadh",
    label: "Riyadh (Asia)",
  },
  {
    value: "Europe/Rome",
    label: "Rome (Europe)",
  },
  {
    value: "Antarctica/Rothera",
    label: "Rothera (Antarctica)",
  },
  {
    value: "Pacific/Saipan",
    label: "Saipan (Pacific)",
  },
  {
    value: "Asia/Sakhalin",
    label: "Sakhalin (Asia)",
  },
  {
    value: "America/Argentina/Salta",
    label: "Salta (America)",
  },
  {
    value: "Europe/Samara",
    label: "Samara (Europe)",
  },
  {
    value: "Asia/Samarkand",
    label: "Samarkand (Asia)",
  },
  {
    value: "America/Argentina/San_Juan",
    label: "San Juan (America)",
  },
  {
    value: "America/Argentina/San_Luis",
    label: "San Luis (America)",
  },
  {
    value: "Europe/San_Marino",
    label: "San Marino (Europe)",
  },
  {
    value: "America/Santarem",
    label: "Santarem (America)",
  },
  {
    value: "America/Santiago",
    label: "Santiago (America)",
  },
  {
    value: "America/Santo_Domingo",
    label: "Santo Domingo (America)",
  },
  {
    value: "America/Sao_Paulo",
    label: "Sao Paulo (America)",
  },
  {
    value: "Africa/Sao_Tome",
    label: "Sao Tome (Africa)",
  },
  {
    value: "Europe/Sarajevo",
    label: "Sarajevo (Europe)",
  },
  {
    value: "Europe/Saratov",
    label: "Saratov (Europe)",
  },
  {
    value: "America/Scoresbysund",
    label: "Scoresbysund (America)",
  },
  {
    value: "Asia/Seoul",
    label: "Seoul (Asia)",
  },
  {
    value: "Asia/Shanghai",
    label: "Shanghai (Asia)",
  },
  {
    value: "Europe/Simferopol",
    label: "Simferopol (Europe)",
  },
  {
    value: "Asia/Singapore",
    label: "Singapore (Asia)",
  },
  {
    value: "America/Sitka",
    label: "Sitka (America)",
  },
  {
    value: "Europe/Skopje",
    label: "Skopje (Europe)",
  },
  {
    value: "Europe/Sofia",
    label: "Sofia (Europe)",
  },
  {
    value: "Atlantic/South_Georgia",
    label: "South Georgia (Atlantic)",
  },
  {
    value: "Asia/Srednekolymsk",
    label: "Srednekolymsk (Asia)",
  },
  {
    value: "America/St_Barthelemy",
    label: "St Barthelemy (America)",
  },
  {
    value: "Atlantic/St_Helena",
    label: "St Helena (Atlantic)",
  },
  {
    value: "America/St_Johns",
    label: "St Johns (America)",
  },
  {
    value: "America/St_Kitts",
    label: "St Kitts (America)",
  },
  {
    value: "America/St_Lucia",
    label: "St Lucia (America)",
  },
  {
    value: "America/St_Thomas",
    label: "St Thomas (America)",
  },
  {
    value: "America/St_Vincent",
    label: "St Vincent (America)",
  },
  {
    value: "Atlantic/Stanley",
    label: "Stanley (Atlantic)",
  },
  {
    value: "Europe/Stockholm",
    label: "Stockholm (Europe)",
  },
  {
    value: "America/Swift_Current",
    label: "Swift Current (America)",
  },
  {
    value: "Australia/Sydney",
    label: "Sydney (Australia)",
  },
  {
    value: "Antarctica/Syowa",
    label: "Syowa (Antarctica)",
  },
  {
    value: "Pacific/Tahiti",
    label: "Tahiti (Pacific)",
  },
  {
    value: "Asia/Taipei",
    label: "Taipei (Asia)",
  },
  {
    value: "Europe/Tallinn",
    label: "Tallinn (Europe)",
  },
  {
    value: "Pacific/Tarawa",
    label: "Tarawa (Pacific)",
  },
  {
    value: "Asia/Tashkent",
    label: "Tashkent (Asia)",
  },
  {
    value: "Asia/Tbilisi",
    label: "Tbilisi (Asia)",
  },
  {
    value: "America/Tegucigalpa",
    label: "Tegucigalpa (America)",
  },
  {
    value: "Asia/Tehran",
    label: "Tehran (Asia)",
  },
  {
    value: "America/Indiana/Tell_City",
    label: "Tell City (America)",
  },
  {
    value: "Asia/Thimphu",
    label: "Thimphu (Asia)",
  },
  {
    value: "America/Thule",
    label: "Thule (America)",
  },
  {
    value: "America/Tijuana",
    label: "Tijuana (America)",
  },
  {
    value: "Europe/Tirane",
    label: "Tirane (Europe)",
  },
  {
    value: "Asia/Tokyo",
    label: "Tokyo (Asia)",
  },
  {
    value: "Asia/Tomsk",
    label: "Tomsk (Asia)",
  },
  {
    value: "Pacific/Tongatapu",
    label: "Tongatapu (Pacific)",
  },
  {
    value: "America/Toronto",
    label: "Toronto (America)",
  },
  {
    value: "America/Tortola",
    label: "Tortola (America)",
  },
  {
    value: "Africa/Tripoli",
    label: "Tripoli (Africa)",
  },
  {
    value: "Antarctica/Troll",
    label: "Troll (Antarctica)",
  },
  {
    value: "America/Argentina/Tucuman",
    label: "Tucuman (America)",
  },
  {
    value: "Africa/Tunis",
    label: "Tunis (Africa)",
  },
  {
    value: "UTC",
    label: "UTC",
  },
  {
    value: "Asia/Ulaanbaatar",
    label: "Ulaanbaatar (Asia)",
  },
  {
    value: "Europe/Ulyanovsk",
    label: "Ulyanovsk (Europe)",
  },
  {
    value: "Asia/Urumqi",
    label: "Urumqi (Asia)",
  },
  {
    value: "America/Argentina/Ushuaia",
    label: "Ushuaia (America)",
  },
  {
    value: "Asia/Ust-Nera",
    label: "Ust-Nera (Asia)",
  },
  {
    value: "Europe/Vaduz",
    label: "Vaduz (Europe)",
  },
  {
    value: "America/Vancouver",
    label: "Vancouver (America)",
  },
  {
    value: "Europe/Vatican",
    label: "Vatican (Europe)",
  },
  {
    value: "America/Indiana/Vevay",
    label: "Vevay (America)",
  },
  {
    value: "Europe/Vienna",
    label: "Vienna (Europe)",
  },
  {
    value: "Asia/Vientiane",
    label: "Vientiane (Asia)",
  },
  {
    value: "Europe/Vilnius",
    label: "Vilnius (Europe)",
  },
  {
    value: "America/Indiana/Vincennes",
    label: "Vincennes (America)",
  },
  {
    value: "Asia/Vladivostok",
    label: "Vladivostok (Asia)",
  },
  {
    value: "Europe/Volgograd",
    label: "Volgograd (Europe)",
  },
  {
    value: "Antarctica/Vostok",
    label: "Vostok (Antarctica)",
  },
  {
    value: "Pacific/Wake",
    label: "Wake (Pacific)",
  },
  {
    value: "Pacific/Wallis",
    label: "Wallis (Pacific)",
  },
  {
    value: "Europe/Warsaw",
    label: "Warsaw (Europe)",
  },
  {
    value: "America/Whitehorse",
    label: "Whitehorse (America)",
  },
  {
    value: "America/Indiana/Winamac",
    label: "Winamac (America)",
  },
  {
    value: "Africa/Windhoek",
    label: "Windhoek (Africa)",
  },
  {
    value: "America/Winnipeg",
    label: "Winnipeg (America)",
  },
  {
    value: "America/Yakutat",
    label: "Yakutat (America)",
  },
  {
    value: "Asia/Yakutsk",
    label: "Yakutsk (Asia)",
  },
  {
    value: "Asia/Yangon",
    label: "Yangon (Asia)",
  },
  {
    value: "Asia/Yekaterinburg",
    label: "Yekaterinburg (Asia)",
  },
  {
    value: "Asia/Yerevan",
    label: "Yerevan (Asia)",
  },
  {
    value: "Europe/Zagreb",
    label: "Zagreb (Europe)",
  },
  {
    value: "Europe/Zurich",
    label: "Zurich (Europe)",
  },
];

const timezoneItems = computed(() => timezones.map(tz => ({
  id: tz.value,
  value: tz.value,
  name: tz.label,
  region: tz.label.split(" ").pop()?.replace(/[()]/g, ""),
})));
</script>

<template>
  <VModernSelect
    v-model="timezone"
    :items="timezoneItems"
    :label-left="labelLeft"
    :required="required"
    :error-message="errorMessage"
    :variant="variant"
    :size="size"
    :icon-left="['fas', 'clock']"
    key-field-name="value"
    search-field-name="name"
    :placeholder="t('settings.myAccount.timezonePlaceholder')"
  >
    <template #item="{ item }">
      <div class="flex items-center gap-2 text-gray-800 dark:text-gray-200">
        <div>
          <span>{{ item.name.split(" ")[0] }}</span>
          <span class="text-gray-500 dark:text-gray-400 ml-1">({{ item.region }})</span>
        </div>
      </div>
    </template>
  </VModernSelect>
</template>

<style lang="scss" scoped>
// We can remove most of the CSS since we're using VModernSelect's styling
</style>
