import type { TimeEntryHydraItem, TimeEntryWrite } from "@verbleif/lib";

import type { TimeEntryObjectSchema } from "./TimeentrySchema";
import dayjs from "dayjs";

export function useTimeEntryTransformer() {
  function inputTransformer(timeEntry: TimeEntryHydraItem): TimeEntryObjectSchema {
    return {
      task: timeEntry.task ?? "",
      startedAt: typeof timeEntry.startedAt === "string" ? dayjs(timeEntry.startedAt).toDate() : null,
      endedAt: typeof timeEntry.endedAt === "string" ? dayjs(timeEntry.endedAt).toDate() : null,
      billable: timeEntry.billable,
      manual: !!timeEntry.manual,
      notes: timeEntry.notes,
    };
  };

  function outputTransformer(TimeEntry: TimeEntryObjectSchema): TimeEntryWrite {
    return {
      task: TimeEntry.task,
      startedAt: dayjs(TimeEntry.startedAt).format("YYYY-MM-DDTHH:mm:ssZ"),
      endedAt: TimeEntry.endedAt ? dayjs(TimeEntry.endedAt).format("YYYY-MM-DDTHH:mm:ssZ") : null,
      billable: TimeEntry.billable,
      manual: TimeEntry.manual,
      notes: TimeEntry.notes,
    };
  }

  function outputPartialTransformer(input: Partial<TimeEntryObjectSchema>): Partial<TimeEntryWrite> {
    return {
      task: input.task !== undefined ? input.task : undefined,
      startedAt: input.startedAt !== undefined ? dayjs(input.startedAt).format("YYYY-MM-DDTHH:mm:ssZ") : undefined,
      endedAt: input.endedAt !== undefined ? dayjs(input.endedAt).format("YYYY-MM-DDTHH:mm:ssZ") : undefined,
      billable: input.billable !== undefined ? input.billable : undefined,
      manual: input.manual !== undefined ? input.manual : undefined,
      notes: input.notes !== undefined ? input.notes : undefined,
    };
  }

  return {
    inputTransformer,
    outputTransformer,
    outputPartialTransformer,
  };
}
