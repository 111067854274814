<script>
export default {
  name: "VSection",
  props: {
    title: {
      type: [Boolean, String],
      default: "Title",
    },
  },
};
</script>

<template>
  <div class="v-section-container">
    <div class="text">
      {{ title }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-section-container {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(213, 213, 213, 0.27);
  .text {
    display: inline-flex;
    margin-left: 15px;
    color: rgb(79, 79, 79);
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
