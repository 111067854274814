<script setup lang="ts">
import type { Package } from "../../composables/Api/Type/PriceSelector";
import { computed, defineEmits, defineModel, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import PackageSelectorPrice from "./VPackageSelectorPrice.vue";

const props = defineProps<{
  packageData: Package | null
  invalid: boolean
  loading: boolean
}>();

const emit = defineEmits<{
  (e: "notPurchasable"): void
}>();

const { t } = useI18n();

const selected = defineModel<number | null>();

// is the package is selected change it to the respective id
function setSelected() {
  if (props.packageData) {
    selected.value = props.packageData.id;
  }
}
const borderClass = computed(() => {
  if (props.invalid) {
    return "border-danger-500";
  }

  if (props.packageData && props.packageData.purchasable) {
    if (selected.value === props.packageData.id) {
      return "border-primary-600 dark:border-primary-400 hover:border-primary-600/50 dark:hover:border-primary-400/50 cursor-pointer";
    } else {
      return "border-gray-300 dark:border-gray-600 hover:border-gray-300/50 dark:hover:border-gray-600/50 cursor-pointer";
    }
  }

  return "border-gray-300 dark:border-gray-600";
});
</script>

<template>
  <div
    class="rounded-lg relative border-2 border-solid bg-white dark:bg-dark-800 flex text-center items-center flex-col h-full w-[189px]"
    :class="borderClass"
    @click="setSelected"
  >
    <div class="pt-[30px] flex flex-col items-center h-[44]">
      <div
        v-if="packageData && packageData.mostPicked"
        class="absolute left-[-5px] right-[-5px] top-[7px] rounded-sm bg-primary-600 dark:bg-primary-500 text-white text-center text-xs !leading-[18px]"
      >
        {{ t('base.mostPicked') }}
      </div>
      <VSkeleton
        v-if="!packageData"
        :width="50"
        width-unit="%"
        :height="18"
        height-unit="px"
      />
      <div v-else class="text-lg !leading-[21px] font-bold" :class="selected === packageData.id && packageData.purchasable ? 'text-primary-600 dark:text-primary-400' : 'text-gray-700 dark:text-gray-200'">
        {{ packageData.title }}
      </div>
      <div v-if="!packageData" class="mt-[10px] mx-[15px] h-14 flex flex-col w-full items-center">
        <VSkeleton
          v-for="item in 4"
          :key="item"
          :width="140"
          width-unit="px"
          :height="12"
          height-unit="px"
          class="mb-[5px]"
        />
      </div>
      <div v-else class="mt-[10px] mx-[15px] text-xs !leading-[14px] h-14 overflow-hidden dark:text-gray-300">
        {{ packageData.description }}
      </div>
    </div>
    <div v-if="!packageData" class="flex flex-col m-[15px]">
      <VSkeleton
        :width="54"
        width-unit="px"
        :height="30"
        height-unit="px"
        :dynamic-width="false"
        class="mb-[4px]"
      />
      <VSkeleton :width="43" width-unit="px" :height="12" height-unit="px" :dynamic-width="false" />
    </div>

    <PackageSelectorPrice
      v-else
      class="m-[15px]"
      :price="packageData.price"
      :purchasable="packageData.purchasable"
    />
    <div class="w-full h-full flex flex-col items-center mb-[15px]">
      <div class="mb-[7px]">
        <div v-if="!packageData" class="flex flex-col w-full items-center">
          <VSkeleton
            v-for="item in 3"
            :key="item"
            :width="150"
            width-unit="px"
            :height="12"
            height-unit="px"
            class="mb-[5px]"
          />
        </div>
        <div v-for="feature in packageData.features" v-else :key="feature" class="text-gray-600 dark:text-gray-400 text-xs !leading-[14px] mb-[5px] overflow-hidden text-nowrap">
          {{ feature }}
        </div>
      </div>

      <VButton
        v-if="packageData && !packageData.purchasable"
        rounded
        @click="emit('notPurchasable')"
      >
        {{ t('base.request') }}
      </VButton>
    </div>
  </div>
</template>
