import type { App } from "vue";

import ACTooltip from "./components/ACTooltip/ACTooltip.vue";

import VAlert from "./components/Alert/VAlert.vue";
import VModernSelect from "./components/AutoComplete/VModernSelect.vue";
import VButton from "./components/Button/VButton.vue";
import VCheckbox from "./components/Checkbox/VCheckbox.vue";
import VColorPicker from "./components/ColorPicker/VColorPicker.vue";
import VConfirmDialog from "./components/ConfirmDialog/VConfirmDialog.vue";
import VNewConfirmDialog from "./components/ConfirmDialog/VNewConfirmDialog.vue";
import VDate from "./components/Date/VDate.vue";
import VDateInput from "./components/DateTime/VDateInput.vue";
import VDatePicker from "./components/DateTime/VDatePicker.vue";
import VTimePicker from "./components/DateTime/VTimePicker.vue";
import DefaultConfirmDialog from "./components/Dialog/DefaultConfirmDialog.vue";
import VDeleteDialog from "./components/Dialog/VDeleteDialog.vue";
import VDialog from "./components/Dialog/VDialog.vue";
import VTestConfirmDialog from "./components/Dialog/VTestConfirmDialog.vue";
import VToggleDialog from "./components/Dialog/VToggleDialog.vue";
import VDivider from "./components/Divider/VDivider.vue";
import VDropdownItem from "./components/DropdownItem/VDropdownItem.vue";
import VField from "./components/Field/VField.vue";
import VFieldSelection from "./components/FieldSelection/VFieldSelection.vue";
import VFilter from "./components/Filter/VFilter.vue";
import VNewFilter from "./components/Filter/VNewFilter.vue";
import IconTitleText from "./components/IconTitleText/IconTitleText.vue";
import VInput from "./components/Input/VInput.vue";
import VInputNumber from "./components/InputNumber/VInputNumber.vue";
import VLink from "./components/Link/VLink.vue";
import VLocaleSelect from "./components/LocaleSelect/VLocaleSelect.vue";
import VModal from "./components/Modal/VModal.vue";
import VModalContainer from "./components/Modal/VModalContainer.vue";
import VPaginator from "./components/Paginator/VPaginator.vue";
import VPriceSelector from "./components/PriceSelector";
import VRadio from "./components/Radio/VRadio.vue";
import VSection from "./components/Section/VSection.vue";
import VSelect from "./components/Select/VSelect.vue";
import VSelectButton from "./components/SelectButton/VSelectButton.vue";
import VSkeleton from "./components/Skeleton/VSkeleton.vue";
import VSlider from "./components/Slider/VSlider.vue";
import VSpacer from "./components/Spacer/VSpacer.vue";
import VStepper from "./components/Stepper/VStepper.vue";
import VSwitch from "./components/Switch/VSwitch.vue";
import VPaginate from "./components/Table/VPaginate.vue";
import VPerPage from "./components/Table/VPerPage.vue";
import VSearch from "./components/Table/VSearch.vue";
import VSort from "./components/Table/VSort.vue";
import VTable from "./components/Table/VTable.vue";
import VTabItem from "./components/Tabs/VTabItem.vue";
import VTabList from "./components/Tabs/VTabList.vue";
import VTabPanel from "./components/Tabs/VTabPanel.vue";
import VTabPanels from "./components/Tabs/VTabPanels.vue";
import VTabs from "./components/Tabs/VTabs.vue";
import VTag from "./components/Tag/VTag.vue";
import VTagFilter from "./components/TagFilter/VTagFilter.vue";
import VTagGroup from "./components/TagGroup/VTagGroup.vue";
import VTagInput from "./components/TagInput/VTagInput.vue";
import VTextArea from "./components/TextArea/VTextArea.vue";
import VTimezoneSelect from "./components/TimezoneSelect/VTimezoneSelect.vue";
import VToastClose from "./components/Toast/VToastClose.vue";
import VTooltip from "./components/Tooltip/VTooltip.vue";
import VFieldGroup from "./components/VFieldGroup/VFieldGroup.vue";
import "./global.css";

export * from "./components";
export * from "./composables";
export { provideDefaultConfirmDialog } from "./composables/Dialog/useDefaultConfirmDialog";

export type { ConfirmDialogInstance } from "./composables/Dialog/useDefaultConfirmDialog";
export * from "./mixins";

declare module "vue" {
  export interface GlobalComponents {
    VTabs: typeof import("./components/Tabs/VTabs.vue")["default"]
    VTabItem: typeof import("./components/Tabs/VTabItem.vue")["default"]
    VTabList: typeof import("./components/Tabs/VTabList.vue")["default"]
    VTabPanel: typeof import("./components/Tabs/VTabPanel.vue")["default"]
    VTabPanels: typeof import("./components/Tabs/VTabPanels.vue")["default"]
    VModalContainer: typeof import("./components/Modal/VModalContainer.vue")["default"]
    VNewConfirmDialog: typeof import("./components/ConfirmDialog/VNewConfirmDialog.vue")["default"]
    VConfirmDialog: typeof import("./components/ConfirmDialog/VConfirmDialog.vue")["default"]
    DefaultConfirmDialog: typeof import("./components/Dialog/DefaultConfirmDialog.vue")["default"]
    VModernSelect: typeof import("./components/AutoComplete/VModernSelect.vue")["default"]
    VDatePicker: typeof import("./components/DateTime/VDatePicker.vue")["default"]
    VTimePicker: typeof import("./components/DateTime/VTimePicker.vue")["default"]
    VDateInput: typeof import("./components/DateTime/VDateInput.vue")["default"]
    VSort: typeof import("./components/Table/VSort.vue")["default"]
    VSearch: typeof import("./components/Table/VSearch.vue")["default"]
    VPerPage: typeof import("./components/Table/VPerPage.vue")["default"]
    VPaginate: typeof import("./components/Table/VPaginate.vue")["default"]
    VTable: typeof import("./components/Table/VTable.vue")["default"]
    VButton: typeof import("./components/Button/VButton.vue")["default"]
    VSelectButton: typeof import("./components/SelectButton/VSelectButton.vue")["default"]
    VCheckbox: typeof import("./components/Checkbox/VCheckbox.vue")["default"]
    VDropdownItem: typeof import("./components/DropdownItem/VDropdownItem.vue")["default"]
    VInput: typeof import("./components/Input/VInput.vue")["default"]
    VInputNumber: typeof import("./components/InputNumber/VInputNumber.vue")["default"]
    VLink: typeof import("./components/Link/VLink.vue")["default"]
    VModal: typeof import("./components/Modal/VModal.vue")["default"]
    VPaginator: typeof import("./components/Paginator/VPaginator.vue")["default"]
    VPriceSelector: typeof import("./components/PriceSelector")["default"]
    VRadio: typeof import("./components/Radio/VRadio.vue")["default"]
    VSection: typeof import("./components/Section/VSection.vue")["default"]
    VSelect: typeof import("./components/Select/VSelect.vue")["default"]
    VSpacer: typeof import("./components/Spacer/VSpacer.vue")["default"]
    VSwitch: typeof import("./components/Switch/VSwitch.vue")["default"]
    VTagInput: typeof import("./components/TagInput/VTagInput.vue")["default"]
    VToastClose: typeof import("./components/Toast/VToastClose.vue")["default"]
    VTag: typeof import("./components/Tag/VTag.vue")["default"]
    VField: typeof import("./components/Field/VField.vue")["default"]
    VFieldGroup: typeof import("./components/VFieldGroup/VFieldGroup.vue")["default"]
    VDivider: typeof import("./components/Divider/VDivider.vue")["default"]
    VDate: typeof import("./components/Date/VDate.vue")["default"]
    VSkeleton: typeof import("./components/Skeleton/VSkeleton.vue")["default"]
    IconTitleText: typeof import("./components/IconTitleText/IconTitleText.vue")["default"]
    VTooltip: typeof import("./components/Tooltip/VTooltip.vue")["default"]
    ACTooltip: typeof import("./components/ACTooltip/ACTooltip.vue")["default"]
    VTagGroup: typeof import("./components/TagGroup/VTagGroup.vue")["default"]
    VStepper: typeof import("./components/Stepper/VStepper.vue")["default"]
    VAlert: typeof import("./components/Alert/VAlert.vue")["default"]
    VTextArea: typeof import("./components/TextArea/VTextArea.vue")["default"]
    VLocaleSelect: typeof import("./components/LocaleSelect/VLocaleSelect.vue")["default"]
    VFieldSelection: typeof import("./components/FieldSelection/VFieldSelection.vue")["default"]
    VColorPicker: typeof import("./components/ColorPicker/VColorPicker.vue")["default"]
    VTagFilter: typeof import("./components/TagFilter/VTagFilter.vue")["default"]
    VTimezoneSelect: typeof import("./components/TimezoneSelect/VTimezoneSelect.vue")["default"]
    VFilter: typeof import("./components/Filter/VFilter.vue")["default"]
    VNewFilter: typeof import("./components/Filter/VNewFilter.vue")["default"]
    VDialog: typeof import("./components/Dialog/VDialog.vue")["default"]
    VTestConfirmDialog: typeof import("./components/Dialog/VTestConfirmDialog.vue")["default"]
    VDeleteDialog: typeof import("./components/Dialog/VDeleteDialog.vue")["default"]
    VToggleDialog: typeof import("./components/Dialog/VToggleDialog.vue")["default"]
    VSlider: typeof import("./components/Slider/VSlider.vue")["default"]
  }
}

export default {
  install(app: App) {
    app.component("VTabs", VTabs);
    app.component("VTabItem", VTabItem);
    app.component("VTabList", VTabList);
    app.component("VTabPanel", VTabPanel);
    app.component("VTabPanels", VTabPanels);
    app.component("VSlider", VSlider);
    app.component("VDialog", VDialog);
    app.component("VTestConfirmDialog", VTestConfirmDialog);
    app.component("VDeleteDialog", VDeleteDialog);
    app.component("VToggleDialog", VToggleDialog);
    app.component("DefaultConfirmDialog", DefaultConfirmDialog);
    app.component("VNewConfirmDialog", VNewConfirmDialog);
    app.component("VConfirmDialog", VConfirmDialog);
    app.component("VModernSelect", VModernSelect);
    app.component("VDatePicker", VDatePicker);
    app.component("VTimePicker", VTimePicker);
    app.component("VDateInput", VDateInput);
    app.component("VSort", VSort);
    app.component("VSearch", VSearch);
    app.component("VPerPage", VPerPage);
    app.component("VPaginate", VPaginate);
    app.component("VTable", VTable);
    app.component("VButton", VButton);
    app.component("VSelectButton", VSelectButton);
    app.component("VCheckbox", VCheckbox);
    app.component("VDropdownItem", VDropdownItem);
    app.component("VInput", VInput);
    app.component("VInputNumber", VInputNumber);
    app.component("VLink", VLink);
    app.component("VModal", VModal);
    app.component("VPaginator", VPaginator);
    app.component("VPriceSelector", VPriceSelector);
    app.component("VRadio", VRadio);
    app.component("VSection", VSection);
    app.component("VSelect", VSelect);
    app.component("VSpacer", VSpacer);
    app.component("VSwitch", VSwitch);
    app.component("VTagInput", VTagInput);
    app.component("VToastClose", VToastClose);
    app.component("VTag", VTag);
    app.component("VField", VField);
    app.component("VFieldGroup", VFieldGroup);
    app.component("VDivider", VDivider);
    app.component("VDate", VDate);
    app.component("VSkeleton", VSkeleton);
    app.component("IconTitleText", IconTitleText);
    app.component("ACTooltip", ACTooltip);
    app.component("VTooltip", VTooltip);
    app.component("VTagGroup", VTagGroup);
    app.component("VAlert", VAlert);
    app.component("VTextArea", VTextArea);
    app.component("VLocaleSelect", VLocaleSelect);
    app.component("VColorPicker", VColorPicker);
    app.component("VFieldSelection", VFieldSelection);
    app.component("VStepper", VStepper);
    app.component("VTagFilter", VTagFilter);
    app.component("VTimezoneSelect", VTimezoneSelect);
    app.component("VFilter", VFilter);
    app.component("VNewFilter", VNewFilter);
    app.component("VModalContainer", VModalContainer);
  },
};
