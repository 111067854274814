import type { ComputedRef, Ref } from "vue";
import { computed, onBeforeUnmount, watch } from "vue";

export enum ScrollDirection {
  TOP = "top",
  BOTTOM = "bottom",
}

export function useLazyLoadScroll(
  scrollableTarget: Ref<HTMLElement | null>,
  loadMoreHook: (o: object) => void,
  hasNextPage: Ref<boolean> | ComputedRef<boolean>,
  isLoading: Ref<boolean>,
  direction: ScrollDirection = ScrollDirection.BOTTOM,
) {
  const shouldLoadMore = computed(() => {
    if (isLoading.value) {
      return false;
    }

    return hasNextPage.value;
  });

  function handleScroll(e: Event) {
    if (!e.target) {
      console.error("Cannot handleScroll since the passed event has no target.");
      return;
    }

    const target = e.target as HTMLElement;
    let almostAtEdge = false;

    if (direction === ScrollDirection.BOTTOM) {
      const scrollState = target.scrollTop + target.getBoundingClientRect().height;
      const partialScrollHeight = target.scrollHeight / 3;
      const loadOffset = partialScrollHeight < 200 ? 200 : partialScrollHeight;
      const loadItemsAt = target.scrollHeight - loadOffset;
      almostAtEdge = scrollState >= loadItemsAt;
    } else if (direction === ScrollDirection.TOP) {
      const partialScrollHeight = target.scrollHeight / 3;
      const loadOffset = partialScrollHeight < 200 ? 200 : partialScrollHeight;
      almostAtEdge = target.scrollTop <= loadOffset;
    }

    if (almostAtEdge && shouldLoadMore.value) {
      loadMoreHook({ scroll: true });
    }
  }

  watch(scrollableTarget, (newScrollableTarget, oldScrollableTarget) => {
    if (!newScrollableTarget) {
      if (oldScrollableTarget) {
        console.error("Removed old scrollable target.");
        oldScrollableTarget.removeEventListener("scroll", handleScroll);
      }

      return;
    }

    newScrollableTarget.addEventListener("scroll", handleScroll);
  });

  onBeforeUnmount(() => {
    if (!scrollableTarget.value) {
      console.error("No scrollable target exists onBeforeUnmount(). Is the ref correctly set?");
      return;
    }
    scrollableTarget.value.removeEventListener("scroll", handleScroll);
  });

  return {
    shouldLoadMore,
  };
}
