export enum SortTypeEnum {
  TASKS = 1,
  REPORTS = 2,
  BOTH = 3,
}

export enum SortFieldEnum {
  PROPERTY = "property",
  INSIDE = "inside",
  PRIORITY = "priority",
  DEADLINE_AT = "deadline_at",
  TIME_SENSITIVE = "time_sensitive",
  QUANTITY = "quantity",
  BLOCKING = "blocking",
}

export enum SortCategoryEnum {
  CLEANING = 1,
  LEGIONELLA_RINSE = 2,
}
