<script setup lang="ts">
import type { ModalManager } from "./Modal";
import CloseIcon from "../../assets/CloseIcon.svg?component";
import { useModal } from "./Modal";

interface Props {
  size?: "is-medium" | "is-large"
  headerless?: boolean
}

withDefaults(defineProps<Props>(), {
  size: "is-medium",
  headerless: false,
});

defineSlots<{
  default: (
    componentKey: string,
    componentProps: Record<string, any>,
    modalManager: ModalManager,
    loading: boolean
  ) => any
}>();

const modalManager = useModal();
const {
  componentKey,
  componentInst,
  loading,
  componentProps,
  options,
} = modalManager;
</script>

<template>
  <div class="modal-container" @mousedown.self.stop="options.onCancel">
    <div class="modal old" :class="[size, { 'is-headerless': headerless }]">
      <template v-if="headerless">
        <CloseIcon class="close icon absolute" @click="options.onCancel" />
      </template>
      <template v-else>
        <div class="header my-[25px] h-[24px]">
          <span v-if="options?.title" class="title leading-[24px]">
            {{ options.title }}
          </span>
          <CloseIcon class="close icon" @click="options.onCancel" />
        </div>
      </template>

      <div class="body">
        <slot
          v-if="componentKey"
          :component-key="componentKey"
          :component-props="componentProps"
          :modal-manager="modalManager"
          :loading="loading"
        />
        <component
          v-bind="componentProps"
          :is="componentInst"
          v-if="componentInst"
          :loading="loading"
          :manager="modalManager"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "../../scss/CloseIconMixin";
@use "./Modal";
</style>
