<script setup lang="ts">
import { useI18n } from "vue-i18n";

const {
  allowChange = false,
  type = "button",
  popperClass,
} = defineProps<{
  allowChange: boolean
  type: "dropdown_item" | "edit_field" | "button" | "tab" | "list" | "item"
  popperClass?: string
}>();

const { t } = useI18n();
</script>

<template>
  <VTooltip
    :content="t(`permissions.ac.${type}`)"
    placement="top"
    theme="verbleif-tooltip-ac"
    :popper-class="popperClass"
    :disabled="allowChange"
  >
    <template #reference>
      <slot />
    </template>
  </VTooltip>
</template>

<style scoped>

</style>
