import type { NetworkError, ServerError } from "@verbleif/shared";

import { HttpStatusCode } from "@axios-extensions/plugins-api";

import { api, AuthPath, ClientError, createAuthenticatePlugin, installInterceptor, RefreshTokenResponseInterceptor, useAuthStore, useConfigStore } from "@verbleif/shared";

import { onAuthenticateSuccess } from "@web/core/onAuthenticateSuccess";

const { authenticate } = createAuthenticatePlugin({
  axios: api,
  path: AuthPath.OAUTH_REFRESH_V2,
  onAuthenticateSuccess,
  onAuthenticateFailed,
});

async function onAuthenticateFailed(
  e: ClientError | ServerError | NetworkError,
) {
  const { logout } = useLogout();

  if (e instanceof ClientError && e.status === HttpStatusCode.BAD_REQUEST && e.response?.data?.error === "invalid_grant") {
    return logout();
  }
}

export async function refreshToken() {
  console.log("Refreshing token");
  const authStore = useAuthStore();
  const { config } = useConfigStore();

  if (!authStore.getRefreshToken.value) {
    console.error("No refresh token set.");
    throw new Error("No refresh token set.");
  }
  if (!config.value) {
    console.error("Config not set");
    throw new Error("Config not set");
  }
  await authenticate({
    grant_type: "refresh_token",
    refresh_token: authStore.getRefreshToken.value,
    client_id: `${config.value.loginClientId}`, // Replace with your client ID.
    redirect_uri: `${config.value.loginRedirect}/oauth/v2/callback`, // Replace with your callback URL.
  });
}

const refreshTokenInterceptorPlugin = RefreshTokenResponseInterceptor(
  api,
  refreshToken,
  ["/oauth/v2/token"],
);
installInterceptor(refreshTokenInterceptorPlugin);
