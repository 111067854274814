import type { Component, defineComponent } from "vue";
import { computed, inject, provide, reactive, ref, shallowRef, watch } from "vue";

const NewConfirmDialogSymbol = Symbol("NewConfirmDialog");

export interface NewConfirmDialogOptions {
  title?: string
  messageIcon?: string | ["fas" | "far" | "fad" | "fal", string] | undefined
  messageTitle?: string
  message?: string
  continueIcon?: string | ["fas" | "far" | "fad" | "fal", string] | undefined
  cancelIcon?: string | ["fas" | "far" | "fad" | "fal", string] | undefined
  continueText?: string
  cancelText?: string
  continueVariant?: string
  cancelVariant?: string
  shouldConfirm?: boolean
  confirmMatch?: string
  confirmIcon?: string | ["fas" | "far" | "fad" | "fal", string] | undefined
  confirmPlaceholder?: string
  data?: any
  onContinue?: (...args: any) => any
  onCancel?: () => any
}

export interface NewConfirmDialogProps {
  options: NewConfirmDialogOptions
  loading: boolean
  isMatching: boolean
  close: () => void
}

export interface NewTwoFactorDialogProps extends NewConfirmDialogProps {
  options: NewConfirmDialogOptions & {
    messageTitle: string
  }
}

export interface OpenNewConfirmDialogArguments {
  component?: Promise<ReturnType<typeof defineComponent>>
  componentProps?: Record<string, any>
  options?: NewConfirmDialogOptions
  async?: boolean
}

export const NewConfirmDialogInitialOptions: NewConfirmDialogOptions = {
  title: "ConfirmTitle",
  messageIcon: ["far", "user-slash"],
  message: "ConfirmMessage",
  continueIcon: ["fas", "user-slash"],
  cancelIcon: undefined,
  continueText: "Continue",
  cancelText: "Cancel",
  continueVariant: "is-danger",
  cancelVariant: "is-light",
  shouldConfirm: true,
  confirmMatch: " ",
  confirmIcon: ["fas", "keyboard"],
  confirmPlaceholder: "Type \" \" to confirm",
  onContinue: () => {},
  onCancel: () => {},
};

function defaultConfirmModalComponent(): Promise<ReturnType<typeof defineComponent>> {
  return import("./NewDefaultConfirmModal.vue");
}

function createNewConfirmDialog() {
  const visible = ref(false);
  const loading = ref(false);
  const confirmInput = ref("");
  const componentInst = shallowRef<Component>({});
  const options = reactive<NewConfirmDialogOptions>({ ...NewConfirmDialogInitialOptions, onCancel: close });
  const componentProps = ref<Record<string, any>>({});

  const isMatching = computed(
    () => confirmInput.value === options.confirmMatch,
  );

  async function open(inputOptions: OpenNewConfirmDialogArguments): Promise<void> {
    if (inputOptions.options) {
      Object.assign(options, inputOptions.options);
    }
    if (inputOptions.componentProps) {
      componentProps.value = inputOptions.componentProps;
    }

    if (inputOptions?.component) {
      componentInst.value = (await inputOptions?.component).default;
    } else {
      componentInst.value = (await defaultConfirmModalComponent()).default;
    }

    visible.value = true;

    if (inputOptions.async) {
      await new Promise((resolve, reject) => {
        options.onContinue = () => resolve(undefined);
        options.onCancel = () => reject(new Error("User canceled"));
      });
    }
  }

  watch(visible, (v): void => {
    if (v) {
      return;
    }
    Object.assign(options, { ...NewConfirmDialogInitialOptions, onCancel: close });
    confirmInput.value = "";
    componentProps.value = {};
    componentInst.value = {};
  });

  function close() {
    visible.value = false;
  }

  function setLoading(bool: boolean): void {
    loading.value = bool;
  }

  function onConfirmInput(value: string) {
    confirmInput.value = value;
  }

  return {
    onConfirmInput,
    componentInst,
    open,
    close,
    options,
    visible,
    confirmInput,
    isMatching,
    loading,
    setLoading,
    componentProps,
  };
}

export function provideNewConfirmDialog() {
  const instance = createNewConfirmDialog();
  provide(NewConfirmDialogSymbol, instance);
  return instance;
}

export type UseNewConfirmDialog = ReturnType<typeof createNewConfirmDialog>;
export function useNewConfirmDialog() {
  const instance = inject<UseNewConfirmDialog>(NewConfirmDialogSymbol);

  if (!instance) {
    throw new Error("Call createNewConfirmDialog before useNewConfirmDialog");
  }

  return instance;
}
