import type { ComputedRef, Ref } from "vue";
import { onKeyStroke } from "@vueuse/core";
import { useDialogStore } from "../../components/Dialog/dialogStore";

interface UseEscapeOptions {
  onEscape: () => void
  enabled?: boolean
  instanceId: ComputedRef<string>
  visible: Ref<boolean>
}

export function useEscape({ onEscape, enabled = true, instanceId, visible }: UseEscapeOptions) {
  const { state } = useDialogStore();

  function isTopMostDialog(): boolean {
    if (!visible.value || !instanceId.value) {
      return false;
    }

    const visibleDialogs = state.openOrder.filter((id) => {
      const dialog = state.dialogs.find(d => d.instanceId === id);
      return Boolean(dialog && !dialog.isConfirm);
    });

    const lastOpenDialogId = visibleDialogs[visibleDialogs.length - 1];
    return instanceId.value === lastOpenDialogId;
  }

  onKeyStroke("Escape", () => {
    if (!enabled || !visible.value || !instanceId.value) {
      return;
    }

    if (isTopMostDialog()) {
      onEscape();
    }
  });

  return {
    isTopMostDialog,
  };
}
