import type { UserHydraItem } from "@verbleif/lib";
import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum, PermissionScope } from "@verbleif/lib";
import { useLocationStore } from "../../LocationStore";
import { ContextVoter } from "./composables/ContextVoter";
import { LocationVoter } from "./LocationVoter";

export class UserVoter extends ContextVoter {
  private locationVoter = new LocationVoter(this.permissionService);

  public canReadCollectionViaClient = ({
    client,
  }: {
    client: string | null
  }) => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.ContextClientOperationUserReadcollectionviaclient,
    })) {
      return true;
    }

    if (!client) {
      return false;
    }

    const clientPermission = this.permissionService.getClientPermissionForUser({ clientIri: client });

    // When there is a client permission, you have access since the UserExtensions handles which users you can see.
    return clientPermission !== null;
  };

  public canReadCollectionViaLocation = ({
    location,
  }: {
    location: string | null
  }) => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.ContextClientOperationUserReadcollectionviaclient,
    })) {
      return true;
    }

    if (!location) {
      return false;
    }

    const locationItem = useLocationStore().locations.value[location];

    if (!locationItem) {
      return false;
    }

    return this.locationVoter.canRead(locationItem);
  };

  public canInvite = ({
    location = this.permissionService.defaultLocation,
    client = this.permissionService.defaultClient,
    scope,
  }: {
    location?: string | null
    client?: string | null
    scope: Extract<PermissionScope, PermissionScope.CLIENT | PermissionScope.LOCATION>
  }) => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationUserInviteany,
    })) {
      return true;
    }

    if (this.hasAccessToScope({
      location,
      client,
      scope,
    }) === false) {
      return false;
    }

    return true;
  };

  public canRead = ({
    location = this.permissionService.defaultLocation,
    client = this.permissionService.defaultClient,
    item,
  }: {
    location?: string | null
    client?: string | null
    item: UserHydraItem
  }) => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationUserReadany,
    })) {
      return true;
    }

    // If the user is the same as the item, they can always true
    if (this.permissionService.userIri === item["@id"]) {
      return true;
    }

    return this.hasAccess({
      "@id": item["@id"],
      "location": location,
      "client": client,
      "attribute": PermissionAttributesEnum.ContextOperationUserRead,
      "scope": PermissionScope.LOCATION,
    });
  };

  public canUpdate = (
    {
      location = this.permissionService.defaultLocation,
      client = this.permissionService.defaultClient,
      item,
      scope,
    }: {
      location?: string | null
      client?: string | null
      item: UserHydraItem
      scope: Extract<PermissionScope, PermissionScope.CLIENT | PermissionScope.LOCATION>
    },
  ): boolean => {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationUserInviteany,
    })) {
      return true;
    }

    if (this.hasAccessToScope({
      location,
      client,
      scope,
    }) === false) {
      return false;
    }

    // If the user is the same as the item, they can always true
    if (this.permissionService.userIri === item["@id"]) {
      return true;
    }

    return this.hasAccess({
      "@id": item["@id"],
      "attribute": PermissionAttributesEnum.ContextOperationUserUpdate,
      scope,
      location,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationUserInviteany,
        PermissionAttributesEnum.GlobalOperationUserUpdateany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationUserInvite,
        PermissionAttributesEnum.ContextOperationUserUpdate,
      ],
    });
  };

  protected hasAccess(
    {
      "@id": iri,
      location,
      client,
      attribute,
      scope,
    }: {
      "@id"?: string
      "location"?: string | null
      "client"?: string | null
      "attribute": PermissionAttributesEnum
      "scope"?: Exclude<PermissionScope, PermissionScope.GLOBAL>
    },
  ): boolean {
    if (iri && scope) {
      const userPermission = this.permissionService.getScopePermissionForUser({
        iri,
        attribute,
        scope,
      });
      if (userPermission !== null) {
        return true;
      }
    }

    if (location) {
      const userLocationPermission = this.permissionService.getLocationPermissionForUser({ locationIri: location, attribute });
      if (userLocationPermission !== null) {
        return true;
      }
    }

    const inputClient = client ?? (location ? this.permissionService.getClientViaLocation(location) : null);

    if (inputClient === null) {
      return false;
    }

    const clientPermission = this.permissionService.getClientPermissionForUser({ clientIri: inputClient, attribute });

    return clientPermission !== null;
  }

  protected hasAccessToScope({
    location,
    client,
    scope,
    attribute,
  }: {
    location?: string | null
    client?: string | null
    scope?: Extract<PermissionScope, PermissionScope.CLIENT | PermissionScope.LOCATION>
    attribute?: PermissionAttributesEnum
  }): boolean {
    if (!scope) {
      return false;
    }

    // Check client permissions first
    const inputClient = client ?? (location ? this.permissionService.getClientViaLocation(location) : null);
    if (inputClient) {
      const clientPermission = this.permissionService.getClientPermissionForUser({
        clientIri: inputClient,
        attribute,
      });

      if (clientPermission !== null) {
        return true;
      }
    }

    // If scope is location and we have a location, check location permissions
    if (scope === PermissionScope.LOCATION && location) {
      const locationPermission = this.permissionService.getLocationPermissionForUser({
        locationIri: location,
        attribute,
      });

      if (locationPermission !== null) {
        return true;
      }
    }

    return false;
  }
}
