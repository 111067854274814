import type { UserGroupHydraItem, UserHydraItem } from "@verbleif/lib";
import type { VSidebarItemProps } from "../Sidebar/VSidebarItem.type";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { useSharedStore } from "@verbleif/shared";
import { createInjectionState } from "@vueuse/core";
import { shortcutVoter } from "@web/features/Permissions";
import { useSystemStore } from "@web/features/Store/useSystemStore";
import { TaskListType } from "@web/features/Tasks/TasksRoutes";
import { computed } from "vue";
import { useRoute } from "vue-router";

const [useProvideTaskList, useTaskListRaw] = createInjectionState(() => {
  const route = useRoute();
  const {
    properties,
  } = useSystemStore();
  const {
    usersPerUserGroup,
    usersList,
    userGroupLessUsers,
    userGroupsList,
  } = useSharedStore();

  const hasTaskListsShortcutUserGroups = computed<boolean>(() => {
    return shortcutVoter.hasShortcut({
      attribute: PermissionAttributesEnum.ContextTaskshortcutUsergroups,
    });
  });

  const hasTaskListsUsers = computed<boolean>(() => {
    return shortcutVoter.hasShortcut({
      attribute: PermissionAttributesEnum.ContextTaskshortcutUser,
    });
  });

  const hasTaskListsShortcutUserGroupsLessUser = computed<boolean>(() => {
    return shortcutVoter.hasShortcut({
      attribute: PermissionAttributesEnum.ContextTaskshortcutUsergrouplessuser,
    });
  });

  const showUserGroupsListsTab = computed<boolean>(() => {
    return hasTaskListsShortcutUserGroups.value || hasTaskListsShortcutUserGroupsLessUser.value;
  });

  const lists = computed<VSidebarItemProps[]>(() => {
    const rawLists: VSidebarItemProps[] = [];

    userGroupsList.value.sort((a, b) => a.name.localeCompare(b.name)).forEach((userGroup) => {
      const routeId = `${TaskListType.TYPE_USER_GROUP}_${userGroup.id}`;

      const listItem: VSidebarItemProps = {
        routeId,
        text: userGroup.name,
        icon: ["fad", "users-class"],
        to: {
          name: "task-list",
          params: { type: TaskListType.TYPE_USER_GROUP, id: userGroup.id },
        },
        children: [],
      };

      const userGroupUsers = usersPerUserGroup.value[userGroup["@id"]];
      if (userGroupUsers?.length && hasTaskListsUsers.value) {
        listItem.children = [];

        userGroupUsers.sort((a, b) => a.name.localeCompare(b.name)).forEach((user) => {
          listItem.children!.push({
            routeId: `${TaskListType.TYPE_USER}_${user.id}`,
            text: user.name,
            icon: ["fad", "user"],
            to: { name: "task-list", params: { type: TaskListType.TYPE_USER, id: user.id } },
            children: [],
          });
        });
      }
      rawLists.push(listItem);
    });

    if (hasTaskListsShortcutUserGroupsLessUser.value) {
      userGroupLessUsers.value.sort((a, b) => a.name.localeCompare(b.name)).forEach((user) => {
        rawLists.push({
          routeId: `${TaskListType.TYPE_USER}_${user.id}`,
          text: user.name,
          icon: ["fad", "user"],
          to: { name: "task-list", params: { type: TaskListType.TYPE_USER, id: user.id } },
          children: [],
        });
      });
    }
    return rawLists;
  });

  const currentListType = computed<TaskListType | null>(() => {
    return route.params.type as TaskListType || null;
  });

  const currentListId = computed(() => {
    return route.params.id as string || null;
  });

  const currentListRouteId = computed(() => {
    if (!currentListType.value) {
      return null;
    }
    return `${currentListType.value}_${currentListId.value}`;
  });

  const currentList = computed<UserHydraItem | UserGroupHydraItem | null>(() => {
    switch (currentListType.value) {
      case TaskListType.TYPE_ALL:
      case TaskListType.TYPE_PER_PROPERTY:
        return properties.value.find((property) => {
          return `${TaskListType.TYPE_PER_PROPERTY}_${property.id}` === currentListRouteId.value;
        }) || null;
      case TaskListType.TYPE_TODAY:
      case TaskListType.TYPE_OPEN_UNTIL_TODAY:
      case TaskListType.TYPE_ALL_UNTIL_TODAY:
      case TaskListType.TYPE_ALL_TODAY:
      case TaskListType.TYPE_ASSIGNED_TO_ME:
      case TaskListType.TYPE_UNASSIGNED:
      case TaskListType.TYPE_READY:
      case TaskListType.TYPE_FINISHED:
      case TaskListType.TYPE_CHANGEOVER_DAY:
      case TaskListType.TYPE_ROUTE:
      case TaskListType.TYPE_TEMPLATE:
        return null;
      case TaskListType.TYPE_USER_GROUP:
        return userGroupsList.value.find((userGroup) => {
          return `${TaskListType.TYPE_USER_GROUP}_${userGroup.id}` === currentListRouteId.value;
        }) || null;
      case TaskListType.TYPE_USER:
        return usersList.value.find((user) => {
          return `${TaskListType.TYPE_USER}_${user.id}` === currentListRouteId.value;
        }) || null;
      case TaskListType.TYPE_CUSTOM:
        return null;
    }
    return null;
  });

  const loading = computed(() => {
    return !!currentList.value;
  });

  const currentListName = computed<string>(() => {
    if (currentList.value) {
      return currentList.value?.name || "";
    }

    return "";
  });

  const currentListTranslation = computed(() => {
    if (!currentListType.value) {
      return "unknown";
    }
    const map = {
      [TaskListType.TYPE_ALL]: "tasks.type.all",
      [TaskListType.TYPE_PER_PROPERTY]: "tasks.type.per_property",
      [TaskListType.TYPE_TODAY]: "tasks.type.today",
      [TaskListType.TYPE_OPEN_UNTIL_TODAY]: "tasks.type.open_until_today",
      [TaskListType.TYPE_ALL_UNTIL_TODAY]: "tasks.type.all_until_today",
      [TaskListType.TYPE_ALL_TODAY]: "tasks.type.all_today",
      [TaskListType.TYPE_ASSIGNED_TO_ME]: "tasks.type.assigned_to_me",
      [TaskListType.TYPE_UNASSIGNED]: "tasks.type.unassigned",
      [TaskListType.TYPE_READY]: "tasks.type.ready",
      [TaskListType.TYPE_FINISHED]: "tasks.type.finished",
      [TaskListType.TYPE_USER_GROUP]: "tasks.type.user_group",
      [TaskListType.TYPE_USER]: "tasks.type.user",
      [TaskListType.TYPE_CUSTOM]: "tasks.type.custom",
      [TaskListType.TYPE_CHANGEOVER_DAY]: "tasks.type.change_over_day",
      [TaskListType.TYPE_ROUTE]: "tasks.type.route",
      [TaskListType.TYPE_TEMPLATE]: "tasks.type.template",
    };
    return map[currentListType.value];
  });

  const propertyTabs = computed<VSidebarItemProps[]>(() => {
    // if (currentListType.value !== TaskListType.TYPE_PER_PROPERTY) {
    //   return [];
    // }

    // if (!properties.value.length) {
    //   return [];
    // }

    const items: VSidebarItemProps[] = [];

    properties.value
      .filter(property => property.active)
      .forEach((property, index) => {
        items.push({
          routeId: `${TaskListType.TYPE_PER_PROPERTY}_${property.id}`,
          text: property.name,
          icon: ["fas", "house"],
          to: {
            name: "task-list",
            params: {
              type: TaskListType.TYPE_PER_PROPERTY,
              id: property.id,
            },
          },
          hideSpacer: true,
          children: [],
          count: properties.value.length,
          iconColor: [
            "text-red-500 dark:text-red-400",
            "text-orange-500 dark:text-orange-400",
            "text-green-500 dark:text-green-400",
            "text-blue-500 dark:text-blue-400",
            "text-black dark:text-white",
          ][index % 5],
          rightIcons: index === 0
            ? [
                {
                  icon: ["fad", "arrow-right-arrow-left"],
                  tooltip: "Wisselt",
                  color: "text-gray-800 dark:text-gray-200",
                  tooltipColor: "text-gray-200",
                  showInTooltip: true,
                },
                {
                  icon: ["fad", "lock"],
                  tooltip: "Geblokkeerd",
                  color: "text-red-500",
                  showInTooltip: true,
                },
              ]
            : undefined,
        });
      });

    return items;
  });

  return {
    lists,
    currentListName,
    currentList,
    currentListRouteId,
    currentListId,
    propertyTabs,
    currentListType,
    currentListTranslation,
    loading,
    hasTaskListsShortcutUserGroups,
    hasTaskListsUsers,
    hasTaskListsShortcutUserGroupsLessUser,
    showUserGroupsListsTab,
  };
});

export { useProvideTaskList };

export function useTaskList() {
  const taskList = useTaskListRaw();
  if (taskList == null) {
    throw new Error("Please call `useProvideTaskList` on the appropriate parent component");
  }
  return taskList;
}

export const isFocusedOnShortcut = computed(() => {
  return shortcutVoter.isFocusedOnShortcut();
});
