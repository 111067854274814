<script setup lang="ts">
import { computed, defineModel } from "vue";
import { useI18n } from "vue-i18n";
import VTag from "../Tag/VTag.vue";
import VTooltip from "../Tooltip/VTooltip.vue";

interface TagItem {
  name?: string
  fullName?: string
  [key: string]: any
}

interface Props {
  textKeyName?: string
  keyName?: string
  variant?: string
  closeable?: boolean
  color?: string | null
  backgroundColor?: string | null
  truncateAt?: number
  isTag?: boolean
}

const {
  textKeyName = "name",
  keyName = "id",
  variant = "is-primary",
  closeable = false,
  color = null,
  backgroundColor = null,
  truncateAt = 1,
  isTag = false,
} = defineProps<Props>();

const model = defineModel<TagItem[]>({ default: [] });

const { t } = useI18n();

const truncatedItems = computed(() => {
  const items = model.value.slice(truncateAt, model.value.length);
  return items;
});

const truncatedNames = computed(() => {
  const names = truncatedItems.value.map((i: TagItem) => {
    return typeof i === "string" ? i : i.name || i.fullName;
  }).filter(Boolean).join(", ");
  return names;
});

const visibleItems = computed(() => {
  return model.value.slice(0, truncateAt);
});

const visibleNames = computed(() => {
  const names = visibleItems.value.map((i: TagItem) => {
    return typeof i === "string" ? i : i[textKeyName] || i;
  }).filter(Boolean).join(", ");
  return model.value.length <= truncateAt ? names : `${names},`;
});
</script>

<template>
  <div class="tag-group-container" :class="{ 'is-tag': isTag }">
    <slot v-if="model.length === 0" name="empty" />
    <template v-if="!isTag">
      <span>{{ visibleNames }}</span>
      <VTooltip :content="truncatedNames">
        <template #reference>
          <span v-if="truncatedItems.length !== 0">
            +{{ truncatedItems.length }} {{ t('base.others') }}
          </span>
        </template>
      </VTooltip>
    </template>
    <template v-else>
      <VTag
        v-for="item in visibleItems"
        :key="item[keyName]"
        :text="item[textKeyName] || item"
        :closeable="closeable"
        :variant="variant"
        :color="color ?? ''"
        :background-color="backgroundColor ?? ''"
      />
      <VTooltip :content="truncatedNames">
        <template #reference>
          <VTag
            v-if="truncatedItems.length !== 0"
            class="end_tag"
            :closeable="false"
            :variant="variant ?? ''"
            :text="`+${truncatedItems.length} ${t('base.others')}`"
          />
        </template>
      </VTooltip>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "../../scss/CloseIconMixin";
.tag-group-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  &.is-tag {
    gap: 10px;
  }
}
</style>
