export enum MercureProgressSubjectEnum {
  SYNC_BOOKING_EXPERTS = "sync_booking_experts",
  SYNC_CAMPING_CARE = "sync_camping_care",
  SYNC_RECRANET = "sync_recranet",
  SYNC_TOMMY = "sync_tommy",
  BULK_ENTITY_UPDATE = "bulk_entity_update",
}

export enum MercureProgressTypeEnum {
  UPDATE = "update",
  FINISHED = "finished",
}

export interface MercureProgress {
  data: {
    startTime: number
    maxSteps: number
    step: number
    percentage: number
    elapsed: number
    estimated: number
    remaining: number
  }
  type: MercureProgressTypeEnum
  subject: MercureProgressSubjectEnum
}
